// ctype SCSS
.ce-ticketoverview {
    $border-radius: 12px;
    .ticket{
        position: relative;
        //@include gradient_ci-turquoise;
        background: $blue-ci;
        color:#fff;
        border-radius: $border-radius;
        padding: 0;
        margin: 0 0 6rem 0;
        box-shadow: 0 40px 30px -35px rgba(0, 0, 0, 0.7);
        overflow: hidden;
        &:hover{
            .cta{
                .moreInfo{
                    &:before{
                        background: $yellow;
                    }
                }
            }
        }
        .teams{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            font-size: 3.2rem;
            font-weight: 100;
            padding: 0 4rem;
            margin: 0 0 3.5rem;

            .team{
                display: flex;
                flex-direction: column;
                flex: 1;
                align-items: center;
                max-width: 25rem;
                img{
                    margin: 0 1rem 1rem;
                }
                .teamname{
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }
            .versus{
                font-family: American_Captain_01;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 5rem;
            }
        }
        .info{
            cursor: pointer;
            border-bottom: 2px dashed rgba(255, 255, 255, 0.35);
            padding: 0;
            margin: 0;
            position: relative;
            border-radius: $border-radius $border-radius 0 0;
            &:before, &:after{
                content: "";
                background: #fff;
                box-shadow: inset 0 4px 5px -2px rgba(0, 0, 0, 0.45);
                width: 14px;
                height: 14px;
                border-radius: 50%;
                position: absolute;
                z-index: 1;
                top: 100%;
            }
            &:before{
                left: 0;
                transform: translate(-50%, -50%);
            }
            &:after{
                right: 0;
                transform: translate(50%, -50%);
            }
            .topInfo{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2rem 2rem;
                border-radius: $border-radius $border-radius 0 0;
                text-align: center;
                font-size: 1.6rem;
                font-weight: 600;
                time{
                    margin-bottom: .75rem;
                }
            }
        }
        .description{
            display: none;
            will-change: height, display;
            cursor: pointer;
            background: $blue-ci-bright;
            p{
                font-size: 15px;
                font-weight: 400;
                padding: 4rem;
            }
        }
        .cta{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.3rem 2rem 1.5rem;
            .moreInfo{
                $infoSize: 1.75em;
                cursor: pointer;
                padding: 0;
                font-size: 1.6rem;
                font-weight: 400;
                color: #fff;
                display: flex;
                align-items: center;
                transition: color .3s;
                &:before{
                    content:"i";
                    font-weight: 800;
                    margin-right: .5em;
                    text-align: center;
                    line-height:$infoSize;
                    width: $infoSize;
                    height: $infoSize;
                    border-radius: $infoSize;
                    background: #fff;
                    color: $blue-ci;
                    transition: background .3s;
                }
                &:hover, &:active{
                    color: $yellow;
                    &:before{
                        background: $yellow;
                    }
                }
            }
            .btn-ticket{
                cursor: default;
                pointer-events: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                //text-align: center;
                position: relative;
                background: #c41200;
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 0;
                padding: 1.2rem 1.1rem 1.2rem 1.4rem;
                border-radius: 5px;
                color: #fff;
                text-align: center;
                min-width: 23.3rem;
                span{
                    line-height: 1.9rem;
                }
                &:after{
                    font-family: 'ksc-icons';
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    content: $ksc-icon-ticket-small;
                    margin: 0 0 0 .5em;
                    font-size: 1.25em;
                    opacity: .5;
                }
                &.available{
                    background: #019a38;
                    cursor: pointer;
                    pointer-events: all;
                    font-size: 1.8rem;
                    span{
                        line-height: 1;
                    }
                    &:after{
                        transition: transform .1s;
                        opacity: 1;
                    }
                    &:hover{
                        background: #01ad40;
                    }
                }
            }
            &.open{
                .moreInfo {
                    &:before{
                        content: "×";
                    }
                }
            }
        }
        @media all and (max-width: 649px){
            .teams{
                font-size: 2.2rem;
                padding: 0 1.5rem;
                margin: 1.5rem 0 4.2rem;
                .team{
                    img{
                        max-width: 75px;
                    }
                }
            }
            .cta .moreInfo{
                font-size: 1.4rem;
            }
        }
        @media all and (max-width: 499px){
            .info{
                .topInfo{
                    display: block;
                    text-align: center;
                    .spieltag{
                        margin-top: 1rem;
                    }
                }
            }
            .teams{
                display: block;
                text-align: center;
                margin-bottom: 5.5rem;
                .team{
                    max-width: 100%;

                    img{
                        margin: 1rem;
                        position: relative;
                        z-index: 2;
                    }
                    .teamname{
                    }
                    &.home{
                        .teamname{
                            order:-1;
                            margin-bottom: .5rem;
                        }
                    }
                }
                .versus{
                    font-size: 3rem;
                    margin: 3rem 0;
                    line-height: 0;
                    position: relative;
                    &:before{
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 170px;
                        height: 170px;
                        border: 2px solid #fff;
                        border-radius: 50%;
                    }
                }
            }
            .description{
                p{
                    column-count: 1;
                }
            }
            .cta {
                display: block;
                text-align: center;
                .moreInfo{
                    font-size: 1.6rem;
                    text-align: center;
                    display: block;
                    font-weight: 600;
                    margin-bottom: 2rem;
                    margin-top: 0.6rem;
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }
}