//==========KSC APP==========//

section.kscApp{
  .items{
    text-align: center;
    display: block;
    width: 60%;
    margin: 0 auto;
    .item{
      display: inline-block;
      vertical-align: middle;
      width: 25%;
      margin: 0 1.5em;
      .ksc-icon{
        font-size: 4em;
        color: $grey-ci-bright;
      }
      .qr-code{
        margin: 1.25em 2.5em;
      }
      &.phone{
        position: relative;
        transition: $t-medium;
        transform-origin: center 85%;
        &:after{
          content: "";
          display: block;
          position: absolute;
          bottom: -4em;
          width: 100%;
          left: 0;
          height: 3%;
          background: rgba(0, 0, 0, 0.85);
          border-radius: 1em;
          box-shadow: 0 0 15px #000, 0 0 45px #000, 0 0 35px #000;
          opacity: .07;
        }
      }
      &.getApp{

      }
      .btn.small{
        margin: 0 2.25em;
        display: block;
        font-size: 120%;
      }
      &:hover{
        .ksc-icon{
          color: $yellow;
          transform: scale(1.1);
        }
      }
    }
    &:hover{
      .phone{
        transform:scale(1.1);
      }
    }
  }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){
  section.kscApp{
    .items{
      width: 80%;
    }
  }
}
@media (max-width: 1279px){

}
@media (max-width: 991px){
  section.kscApp{
    .items{
      width: 100%;
    }
  }
}
@media (max-width: 767px){
  section.kscApp{
    .items{
      .item{
        margin: 0 5px;
        width: calc(33.33333% - 10px);
        .btn.small{
          margin-left:1em;
          margin-right: 1em;
        }
      }
    }
  }
}
@media (max-width: 580px){
  section.kscApp{
    .items .item{
      .qr-code, .btn.small{
        display: none;
      }
      .ksc-icon{
        font-size: 6.5em;
      }
    }
  }
  section.socialQuicklinks{
    hr{
    }
  }
}
@media (max-width: 375px){

}