//==========VORLAGE==========//

table.table {
  width:100%;
  tr {
    transition: $t-fast;
    border-bottom:1px solid $grey-superlight;
    th {background-color:$grey-ultralight; padding: 1em;
      font-size: 1.7em; font-weight:400; text-align:left;
      border-right:1px solid $grey-superlight;
      &:last-child {border-right:none;} }
    td {
      transition: $t-fast;
      padding: 1em;
      font-size: 1.7em;
      border-right:1px solid $grey-superlight;
      font-weight:300;
      &:last-child {border-right:none;}
    }
  }
  p {font-size:1em;
  padding:0;}
  + p{padding-top:1em;}
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 375px){

}