//==========Match - Timeline==========//

.matchTimeline {
  padding: 0;
  font-size: 1em;
  height: 20em;
  background: $blue-ci;
  margin-top: -1px;
  position: relative;
  z-index: 10;
  &:after {
    position: absolute;
    content: '';
    display: block;
    z-index: 1;
    width: 100%;
    height: 50%;
    left: 0;
    bottom: 0;
    background: transparentize(#fff, .9);
  }
  .logo {
    $logosize: 7em;
    margin: 0;
    display: inline-block;
    text-align: center;
    position: absolute;
    z-index: 5;
    left: 2em;
    width: 10em;
    height: 7em;
    vertical-align: middle;
    overflow: hidden;
    img {
      height: auto;
      max-width: 100%;
      width: auto;
      max-height: $logosize;
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    + .logo {
      left: auto;
      right: 2em;
    }
    &.home {
      top: calc(#{$logosize / 4});
    }
    &.away {
      bottom: calc(#{$logosize / 4});
    }
  }
  .timeline {
    $timelinePos: 14em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: calc(100% - (#{$timelinePos} * 2));
    left: $timelinePos;
    height: 10px;
    background: url("../Img/timelineMarker.svg") no-repeat 0 0 $green;
    background-size: 100%;
    cursor: pointer;
  }
  .event {
    $eventSize: 3em;
    background: #fff;
    border-radius: 50%;
    width: $eventSize;
    height: $eventSize;
    position: absolute;
    box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-position: center center;
    transform: translateX(-50%);
    z-index: 2;
    &:hover{
      z-index: 3;
    }
    img{
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      display: block;
      width: 2px;
      left: calc(50% - 2px);
      height: calc(#{$eventSize} / 2);
      background: #fff;
    }
    &.home {
      top: calc((#{$eventSize} / 2) * -3);
      &:before {
        bottom: calc(#{$eventSize} / -2);
      }
    }
    &.away {
      bottom: calc((#{$eventSize} / 2) * -3);
      &:before {
        top: calc(#{$eventSize} / -2);
      }
    }
    &.wizzle {
      z-index: 3;
      top: 50%;
      transform: translateY(-50%) rotate(25deg);
      left: calc(#{-$eventSize} / 2);

      + .wizzle {
        left: auto;
        right: calc(#{-$eventSize} / 2);
      }
      &:before, &:after {
        display: none;
      }
    }
    &.halftime {
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate3D(-50%, -50%, 0);
      box-shadow: none;
      background-color: $blue-ci;
      border: 2px solid $green;
      &:after, &:before {
        display: none;
      }
    }
    &.halftimeDivider {
      position: absolute;
      display: block;
      border-radius: 0;
      z-index: -1;
      background-color: $green;
      height: calc(#{$eventSize} * 4);
      width: 2px;
      top: 50%;
      left: 50%;
      transform: translate3D(-50%, -50%, 0);
      box-shadow: none;
      &:after, &:before {
        background: none;
        color: #fff;
        font-size: 1.3em;
        line-height: 1;
        font-weight: 900;
        text-transform: uppercase;
        position: relative;
        width: auto;
        left: 50%;
        display: inline-block;
      }
      &:before {
        content: "Halbzeit";
        transform: translate3D(-50%, -130%, 0);
      }
      &:after {
        content: "45'";
        top: 100%;
        transform: translate3D(-50%, -60%, 0);
      }
    }

    &.wizzle {
      background-image: url("../Img/me_Wizzle.svg");
    }
    &.halftime {
      background-image: url("../Img/me_Halftime.svg");
    }
    &.yellow-card {
      background-image: url("../Img/me_Yellowcard.svg");
    }
    &.red-card {
      background-image: url("../Img/me_Redcard.svg");
    }
    &.yellowred-card {
      background-image: url("../Img/me_YellowRedcard.svg");
    }
    &.goal {
      background-image: url("../Img/me_Goal.svg");
    }
    .eventInfo {
      position: absolute;
      padding: 10px;
      width: 22em;
      white-space: nowrap;
      font-size: 1.2em;
      background: $grey-superlight;
      display: block;
      margin-top: 5em;
      left: 50%;
      box-shadow: 4px -4px 28px rgba(0, 0, 0, 0.2);
      transition: $t-medium;
      visibility: hidden;
      opacity: 0;
      .open & {
        display: block;
      }
      > div {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 0;
        height: 0;
        left: 50%;
        top: 100%;
        transform: translate3D(-50%, -2px, 0);
        border-style: solid;
        border-width: 20px 12px 0 12px;
        border-color: $grey-superlight transparent transparent transparent;
      }
      img, .infos{
        display: block;
        width: 66.66666%;
        float: left;
      }
      img{
        margin-left: -6px;
        width: 33.33333%;
        display: none;
      }
      .eventType {
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 1.35em;
      }
      .eventTime, .eventPlayer{
        margin-bottom: 4px;
        font-size: 1.35em;
      }
    }
    &:hover .eventInfo {
      opacity: 1;
      visibility: visible;
    }
    &.goal {
      .eventInfo {
        .eventType, .eventScore {
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 5px;
          font-size: 1.5em;
          display: inline-block;
          margin-right: 4px;
        }
      }
    }
    &.home {
      .eventInfo {
        top: 0;
        transform: translate3D(-50%, calc(-100% + -7em), 0);
      }
    }
    &.away {
      .eventInfo {
        bottom: auto;
        top: 0;
        transform: translate3D(-50%, -0.5em, 0);
        &:after {
          top: auto;
          bottom: 100%;
          transform: translate3D(-50%, 2px, 0) rotate(180deg);
          border-style: solid;
          border-width: 20px 12px 0 12px;
          border-color: $grey-superlight transparent transparent transparent;
        }
        img {
          display: none !important;

        }
      }
    }
  }
  .labelHalftime {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {
  .matchTimeline {
    font-size: .9em;
  }
}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  .matchTimeline {
    font-size: .75em;
  }
}

@media (max-width: 479px) {
  .matchTimeline {
    height: auto;
    margin: 30px 15px 25px;
    background: #fff;
    max-height: 50vH;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .timeline {
      background: none;
      margin-top: 10em;
    }
    &:after {
      display: none;
    }
    .logo {
      position: absolute !important;
      bottom: auto !important;
      top: 0 !important;
      left: 0 !important;
      width: 50% !important;
      &.away {
        left: auto !important;
        right: 0 !important;
      }
    }
    .logo + .logo {
      display: none;
    }
    * {
      position: static !important;
      transform: none !important;
      height: auto !important;
      width: auto !important;
      left: auto !important;
      top: auto !important;
    }
    .homeEvents, .awayEvents {
      display: inline-block;
      width: calc(50% - 4px) !important;
      vertical-align: top;
      overflow: hidden;
    }
    .event {
      width: 6em !important;
      height: 6em !important;
      margin-bottom: 4em;
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid rgb(220, 220, 220);
      &:before {
        display: none;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        background: $grey-superlight;
        width: 100vW;
        bottom: -21px;
        left: 53px;
      }
      &.wizzle, &.halftime {
        display: none;
      }
      &.halftimeDivider {
        display: none;
        border-radius: 0;
        z-index: 1;
        box-shadow: none;
        background: white;
        width: 100% !important;
        height: 1px !important;
        &:before {
          color: #fff;
          font-size: 2.3em;
          background: #60ae69;
          line-height: 1;
          font-weight: 900;
          text-transform: uppercase;
          position: relative;
          padding: 10px;
          top: 13px;
          width: auto;
          left: 50%;
          display: inline-block;
        }
        &:after {
          display: none;
        }
      }
      .eventInfo {
        padding: 0 20px 0 0;
        display: block;
        visibility: visible;
        opacity: 1;
        min-width: 100% !important;
        margin-top: 0;
        position: absolute !important;
        left: 53px !important;
        color: $font_darkgrey;
        background: transparent;
        font-size: 14px;
        box-shadow: none;
        &:after {
          display: none;
        }
        .eventType {
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 5px;
          font-size: 13px !important;
        }
        .eventTime, .eventPlayer{
          font-size: 14px;
        }
        .eventPlayer{margin-bottom: 0;}
        img {
          display: none;
        }
      }
      &.goal {
        .eventInfo {
          .eventType, .eventScore {
            margin-right: 4px;
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 5px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .matchTimeline {
    .event {
      width: 4em !important;
      height: 4em !important;
      margin-bottom: 5em;
      &:after{
        left: 39px !important;
        bottom: -28px;
      }
      .eventInfo{
        left: 39px !important;
        .eventType, .eventScore{
          font-size: 11px !important;
        }
        .eventPlayer, .eventTime{
          font-size: 11px;
        }
      }
    }
  }
}