// ctype SCSS
//==========MatchcenterNews==========//

.container.accordeon {
  padding-top: 0;
  article {
    animation: all 0.2s;
    padding: 0 0 1em 0;
    margin: 0 0 1em 0;
    border-bottom: 1px solid $grey-superlight;
    .toggler h1 {
      padding-right: 30px;
      color: $font_darkgrey;
      font-size: 3.2em;
      font-weight: normal;
      line-height: 1.25;
      text-transform: none;
      font-style: normal;
      margin: 0;
      text-align: left;
    }
    .toggleContent {
      padding-bottom: 2em;
    }
    p:first-child {
      padding-top: 2em;
    }
    p:last-child {
      padding-bottom: 2em;
    }
  }

  .toggler {
    cursor: pointer;
    &:before {
      position: absolute;
      right: 30px;
      top: 4px;
      content: "+";
      border-radius: 50%;
      display: block;
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      background-color: $grey-ci-bright;
      transition: $t-fast;
    }
    &:hover, &:focus {
      &:before {
        background-color: $yellow;
        color: #fff;
      }
    }
  }

  &.open {

    article {

    }

    .toggler {
      &:before {
        content: "-";
      }
    }
  }
  &.open {
    article .toggler h1 {
      color: $blue-ci;
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}