//==========VORLAGE==========//
.sponsorenSammlung{
  .sponsor-logobox {
    padding: 30px;
    border: 3px solid #fff;
    background: #fff;
    display: table;
    width: 100%;
    margin-bottom: 60px;
    transition: $t-fast;
    a {
      display: table-cell;
      vertical-align: middle;
    }
    &:hover, &:focus {
      border-color: $yellow;
    }
  }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  .sponsorenSammlung {
    .sponsor-logobox {
      padding: 30px;
      border: 3px solid #fff;
      background: #fff;
      display: table;
      width: 100%;
      margin-bottom: 60px;
      transition: $t-fast;
      a {
        display: table-cell;
        vertical-align: middle;
      }
      &:hover, &:focus {
        border-color: $yellow;
      }
    }
    .sp {
      [class*="col"] [class*="col"] {
        width: 50%;
      }
    }
  }
}

@media (max-width: 480px) {
  .sp {
    [class*="col"] [class*="col"] {
      display: block !important;
      width: 100% !important;
      margin: 0;
    }
  }
}

@media (max-width: 375px) {

}