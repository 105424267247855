html {
  font-size: 10px;
}

.container, .container-fluid {
  position: relative;
}

.row {
  &.row-centered, &.row-inlineBlock {
    /*text-align: center;
    [class*="col-"] {
      float: none;
      text-align: left;
      display: inline-block;
      vertical-align: top;
      margin-right: -4px;
    }*/
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  &.row-inlineBlock {
    text-align: left;
  }
}

header + main > .container {
  padding-top: 6em;
}

main > .container.headlines + section {
  padding-top: 0;
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {
  aside {
    float: right !important;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 0.714285714285714vW;
  }
}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {
  .container {
    width: 1460px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 13.33333px;
  }
}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1280px){
    .container {
        width: 100%;
    }
}


@media (max-width: 649px) {
  .col-xs-12 {
    width: 100%;
  }
}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}

