$navBorder: 5px;
.navcontainer {
    position: relative;
    //border-bottom: $navBorder solid $blue-ci;
    z-index: 100;
}

$mainNavHeight: 6em;
.mainnav, .subnav {
    position: relative;
    z-index: 8;
    display: table;
    width: 100%;
    background: #fff;
    text-align: center;
    height: $mainNavHeight;
    ul {
        display: table-cell;
        width: 50%;
        margin-right: 0;
        vertical-align: middle;

        li {
            display: inline-block;
            position: relative;
            margin: 0 1.2em;
            height: $mainNavHeight;
            line-height: $mainNavHeight;
            cursor: pointer;

            a {
                position: relative;
                display: block;
                text-transform: uppercase;
                font-weight: 300;
                font-size: 2em;
                line-height: inherit;
                color: $grey-ci;

                &:after {
                    display: block;
                    content: "";
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: translateY(100%);
                    height: $navBorder;
                    background: $yellow;
                    opacity: 0;
                    transition: $t-fast;
                }
            }

            &:after {
                display: block;
                content: "";
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translateY(100%);
                height: $navBorder;
                background: $yellow;
                opacity: 0;
                transition: $t-fast;
            }

            &:hover, &:focus {
                a {
                    color: $grey-ci-dark;
                }

                &:after {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            &.active {
                a {
                    font-weight: 700;
                    color: $grey-ci-dark;
                }

                &:after {
                    display: none;
                }
            }
        }

        &.leftnav {
            text-align: right;
            padding-right: $navMargin;
        }

        &.rightnav {
            text-align: left;
            padding-left: $navMargin;
        }
    }
}

.burger {
    display: none;
    cursor: pointer;
    width: 38px;
    height: 20px;
    position: absolute;
    z-index: 100;
    right: 3em;
    top: 1.15em;
    transition: $t-medium;

    &:before {
        transition: $t-fast;
        //content: "";
        //display: block;
        //position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 50%;
        background: $blue-dark;
        opacity: 0;
    }

    span {
        position: absolute;
        display: block;
        background: $blue-ci;
        bottom: auto;
        width: 100%;
        height: .5em;
        border-radius: .25em;
        transition: $t-fast;

        &:nth-child(1) {
            top: 0;
            transform: none;
        }

        &:nth-child(2) {
            top: 50%;
        }

        &:nth-child(3) {
            top: 100%;
            transform: none;
        }
    }

    &:hover, &:focus {
        span {
            //background: $yellow;
        }
    }

    &.open {
        transform: rotate(-45deg) scale(.75);

        span {
            background: $grey-ci-dark;

            &:nth-child(1) {
                top: 50%;
                transform: rotate(-90deg) translateX(0%);
            }

            &:nth-child(2) {
                top: 50%;
                opacity: 0;
            }

            &:nth-child(3) {
                top: 50%;
            }
        }

        &:hover, &:focus {
            span {
                background: $blue-ci;
            }
        }
    }
}



@media (max-width: 1200px) {
    .burger {
        display: block;
    }
    .navcontainer {
        height: 5.35em;
        background: #fff;
        border-bottom: $navBorder solid $blue-ci;
    }
    .mainnav {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        left: 0;
        top: 5.35em;
        padding-top: 6em;
        padding-bottom: 0;
        width: 100%;
        position: absolute;
        z-index: 8;
        min-height: 0;
        height: calc(100vh - 164px);
        transform: translateY(125%);
        transition: $t-slow;
        ul {
            &.leftnav, &.rightnav {
                display: flex;
                flex-wrap: wrap;
                flex: 1 0 100%;
                text-align: left;
                width: 100%;
                float: none;
                padding: 0;
                li {
                    flex: 1 0 100%;
                    display: block;
                    height: auto;
                    text-align: center;
                    margin: 0;
                    a {
                        font-size: 4em;
                        padding: .25em 0;
                        font-weight: 300;
                    }
                    &:hover, &:focus {
                        &:after {
                            display: none;
                        }
                        a {
                            color: $blue-ci;
                        }
                    }
                }
            }
        }
    }
    .mobilenav-open {
        .mainnav {
            transform: translateY(0);
            ul {
                &.leftnav, &.rightnav {
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .mainnav {
        ul {
            &.leftnav, &.rightnav {
                li {
                    a {
                        font-size: 2.5em;
                        padding: 0;
                        line-height: 170%;
                    }
                }
            }
        }
    }
}

@media (max-width: 479px) {
    .mobilenav-open {
        .mainnav {
            top: -6.8em;
        }
    }
    .mainnav {
        z-index: 101;
        height: calc(100vh - 40px);
        padding-top: 6em;
    }
    .navcontainer {
        height: 5.5em;
    }
}

@media (max-width: 400px) {
    .mainnav {
        ul {
            &.leftnav, &.rightnav {
                li {
                    a {
                        font-size: 3em;
                        padding: 0.05em 0;
                    }
                }
            }
        }
    }
}