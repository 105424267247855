.ce-interferer {
    display: flex;
    justify-content: flex-end;
    margin: 4em 0;
    .inner {

        transform: translateX(105%);
        transition: transform .4s;
        transition-delay: .5s;
        /*
        background-repeat: no-repeat;
        background-image:
        url("../Img/Interferer/if_left_white.gif"),
        url("../Img/Interferer/if_right_white.gif"),
        url("../Img/Interferer/if_mid_white.gif");
        background-position:
        left -1px center,
        right -1px center,
        left 1px center;
        background-size:
        auto 100%,
        auto 100%,
        100% 100%;
        */

        @include gradient_ci2019;
        transform: translateX(105%) skewY(-7deg);
        a {
            position: relative;
            text-align: center;
            font-size: 3.7em;
            color: $yellow;
            padding: 0.65em .75em 0.75em .75em;
            text-transform: uppercase;
            font-weight: normal;
            font-family:American_Captain_01, Arial, Helvetica, sans-serif;
            display: block;
            text-shadow: 2px 3px 4px #183354;
            &:after{
                color: #fff;
                content: $ksc-icon-arrow-round;
                font-family: 'ksc-icons';
                font-weight: normal;
                font-style: normal;
                display: inline-block;
                margin-left: 0.3em;
                transform: scale(-1) translateY(-0.06em) scale(0.9);
                text-shadow: none;
            }
            &:before{
                content: "";
                position: absolute;
                width: 100%;
                height: 14%;
                left: 0;
                bottom: 0;
                background: rgba(66, 168, 210, 0.25);
                transition: all .25s;
            }
        }
        &:hover, &:focus {
            a {
                &:after{
                    transform: scale(-1) translateY(-0.06em) scale(1.1) translateX(-0.3em);
                }
                &:before{
                    height: 50%;
                    bottom:50%;
                    background: #fff;
                    opacity: 0;
                }
            }
        }
    }
    &.appeared{
        .inner{
            transform: translateX(0) skewY(-7deg);
        }
    }
}
@media all and (min-width: 992px){
    .ce-interferer {
        .inner {
            max-width: 75vw;
        }
    }
}



