//==========MatchcenterNews==========//

section.article.matchcenter {
  padding: 0;
  main {
    margin: 0;
    article{
      padding: 0 0 1em 0;
      margin: 0 0 1em 0;
      border-bottom: 1px solid $grey-superlight;
      .toggler h1{
        padding-right: 30px;
        color: $font_darkgrey;
      }
    }
  }

  .toggler {
    cursor: pointer;
    &:before {
      position: absolute;
      right: 30px;
      top: 4px;
      content: "+";
      border-radius: 50%;
      display: block;
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      background-color: $grey-ci-bright;
      transition: $t-fast;
    }
    &:hover, &:focus {
      &:before {
        background-color: $yellow;
        color: #fff;
      }
    }
  }

  &.open {
    main {
      article{
        padding: 0 0 6em 0;
      }
    }
    .toggler {
      &:before {
        content: "-";
      }
    }
  }
  &.open{
    main article .toggler h1{
      color: $blue-ci;
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
  section.article.matchcenter{
    .toggler {

      &:before {
      }
    }
  }
}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}