//==========Social Media Teaser Startseite==========//

.socialMedia {
  .item {
    position: relative;
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: $gridGutter;
    color: #fff;
    &:after {
      font-family: ksc-icons;
      border-radius: 50%;
      border: 0.13em solid #fff;
      font-size: 6em;
      color: $blue-ci;
      position: absolute;
      z-index: 10;
      content: "";
      display: block;
      left: 50%;
      top: 0;
      transform: translate3D(-50%, -50%, 0);
    }
    &:before {
      border-radius: 50%;
      width: 4em;
      height: 4em;
      background: #fff;
      position: absolute;
      z-index: 9;
      content: "";
      display: block;
      left: 50%;
      top: 0;
      transform: translate3D(-50%, -50%, 0);
      transition: $t-slow;
    }
    .info {
      z-index: 2;
      position: absolute;
      display: table;
      width: 100%;
      left: 0;
      bottom: 0;
      background: $blue-ci;
      min-height: 9.5em;
      padding: 1.2em 2em 1.5em;
      span{
        display: table-cell;
        vertical-align: top;
        font-weight: 300;
        font-size: 1.8em;
        line-height: 1.25;
        a{
          font-weight: 400;
          color: #fff;
        }
      }
    }
    figure{
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top:0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      transition: $t-slow;
    }
    &.small {
      background: $blue-ci-bright;
      padding: 0;
      height: auto;
      .info {
        position: static;
        background: $blue-ci-bright;
        height: 100%;
        min-height: 11em;
        padding: 5em 2em 2em;
        span{vertical-align: top;}
      }
    }
    &.facebook {
      &:after {
        content: "#{$ksc-icon-facebook-round}";
      }
    }
    &.bluesky {
      &:after {
        content: "#{$ksc-icon-bluesky-round}";
      }
    }
    &.instagram {
      &:after {
        content: "#{$ksc-icon-instagram-round}";
      }
    }
    &:hover, &:focus {
      img {
        @include imgHover;
      }
      &:before{
        background: $yellow;
      }
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}