//==========News Liste==========//
.news  span.h2{
  font-family: American_Captain_01;
  margin-bottom: 1em;
  display: block;
  font-size: 4.5rem;
}
.newsList {
  aside{
    float: right;
  }
  .newsItem {
    display: block;
    margin-bottom: 4.5em;
    .itemInfo {
      border-top: 1px solid $grey-superlight;
      border-bottom: 1px solid $grey-superlight;
      padding: .75em 0;
      margin: 0 0 1em 0;

      span, time {
        font-size: 1.5em;
        font-weight: 900;
        color: $grey-ci-bright;
        text-transform: uppercase;
      }
      time {
        text-transform: none;
      }
      .news-list-category {
        float: right
      }
    }
    figure {
      width: 25%;
      display: inline-block;
      vertical-align: top;
      margin-right: calc(3em - 4px);
      img{
        transition: $t-medium;
      }
    }
    .info {
      display: inline-block;
      vertical-align: top;
      width: calc(75% - 3em);
      .headline {
        font-weight: 900;
        font-size: 1.5em;
        line-height: 1.25;
        margin-bottom: 0.25em;
        transition: $t-fast;
        color: $font_darkgrey;
      }
      p {
        color: $font_darkgrey;
        text-transform: none;
        font-weight: 300;
        font-size: 1.75em;
        line-height: 1.25;
        margin: 0;
      }
    }
    &:hover, &:focus {
      figure {
        img {
          @include imgHover;
        }
      }
      .headline{
        color: $yellow;
      }
      .ksc-icon.bell{
        color: $blue-ci !important;
      }
    }
    &.daily{
      @media screen and (min-width: 1281px){
        font-size: 14px;
      }
      p{
        font-size: 1.5rem !important;
      }
      @media screen and (max-width: 1280px){
        font-size: 1.5rem;
        p{
          font-size: 1.25em !important;
        }
      }
      @media screen and (max-width: 767px){

      }
      @media screen and (max-width: 480px){
        margin-bottom: 1.25em;
      }
      .news-list-category{
        display: none;
      }

      @media screen and (min-width: 1200px){
          figure{
          width: 33.333%;
        }
          .info{
            width: calc(66.667% - 3em)
          }
      }
      @media screen and (min-width: 768px) and (max-width: 991px){
        figure{
          width: 33.333%;
        }
        .info{
          width: calc(66.667% - 3em)
        }
      }
      figure:empty{
        @media screen and (min-width: 481px){
        padding-bottom: 16.77776%;
        background: $grey-superlight;
        position: relative;
        &:before {
          font-family: 'ksc-icons' !important;
          content: $ksc-icon-ksc-logo;
          font-size: 5em;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
        }
        }
      }
      .headline{
        flex: 1;
        margin-right: .5em;
        color: $blue-ci;
      }
      .itemInfo{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 0 !important;
        @media screen and (max-width: 480px){
          border-bottom: 1px solid $grey-ci-light;
          font-size: 1em;
          margin-bottom: 1.25em;
        }
      }
      .news-list-category{
        font-size: 1em;
        margin: .6em 1em 0 auto ;
      }
      .ksc-icon.bell{
        color: $grey-ci-bright;
        display: block;
        font-size: 1.5em;
        transition: color .3s;
        margin-top: 0.25em;

      }
      .content{
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 767px){
          flex-direction: column;
        }
      }
      .bodytext{
        flex: 1;
      }
      .teaser{
        margin-bottom: .5em;
        p{
          font-weight: 500;
        }
      }
      time{
        flex: 1 0 40px;
        max-width: 40px;
        text-align: right;
        color: $grey-ci-bright;
        font-size: 12px;
        margin: .85rem 10px 0 auto;
        @media screen and (max-width: 767px){
          flex: 1 0 100%;
          max-width: 100%;
          order: 3;
          text-align: left;
          margin-left: 0;
          margin-top: 4px;
        }
      }
    }
  }
  hr.daily-divider{
    font-size: 1.5rem;
    margin-bottom: 4.5em;
    @media screen and (min-width: 1920px){
      font-size: 13.33333px;
    }
    @media screen and (max-width: 480px){
      margin-bottom: 3em;
      margin-top: 2.5em;
      border-color: $blue-ci;
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  .newsList{
    main{margin-bottom: 6em}
    aside {
      float: none;
    }
  }
}

@media (max-width: 480px) {
  .newsList {
    aside{
    }
    .newsItem {
      margin-bottom: 60px;
      .itemInfo {
        border: none;
        margin: 5px 0;
        font-size: 10px;
      }
      figure {
        width: 100%;
        margin-right: 0;
      }
      .info {
        width: 100%;
        .headline {
        }
        p {
        }
      }
    }
  }
}

@media (max-width: 375px) {

}
