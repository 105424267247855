.ksc-loginBar {
  $font: 'Lato', Arial, Helvetica, sans-serif;
  $blue-ci: rgb(0, 76, 148);
  $blue-ci-bright: rgb(52, 103, 167);
  $yellow: rgb(22, 159, 219);

  font-family: $font;
  background: $blue-ci-bright;
  padding: 0 26px;
  color: #fff;
  line-height: 1;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1920px;
  z-index: 200;

  .item {
    display: table-cell;
    vertical-align: middle;
    padding-right: 15px;
    position: relative;
    > a, .label {
      position: relative;
      display: block;
      font-size: 11px;
      font-weight: 800;
      text-transform: uppercase;
      color: #fff;
      padding: 5px 0;
      padding-left: 30px;
      cursor: pointer;
      span {
        display: inline-block;
      }
      .icon {
        position: absolute;
        left: 0;
        top: -1px;
        height: 23px;
        svg {
          height: 100%;
          width: auto;
        }
      }
      &:hover, &:focus, &:active {
        color: $yellow;
        .icon {
          color: #fff;
        }
      }
    }
    &.lb-mitglied {
      a {
        padding-left: 38px;
      }
    }
    &.lb-tickets {
      a {
        padding-left: 25px
      }
    }
  }
  .lc, .rc {
    height: 40px;
    display: table;
    float: left;
  }
  .rc {
    float: right;
    text-align: right;
    .item {
      padding: 0 0 0 15px;
      > .label {
        transition: all .25s;
        padding-left: 0;
        padding-right: 25px;
        .icon {
          left: auto;
          right: 0;
          top: -2px;
        }
      }
    }
    #user {
      display: inline-block;
    }
  }
  .lb-loginBox {
    text-align: left;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 200;
    width: 280px;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 96%;
      left: 0;
      top: 0;
      background: #000;
      box-shadow: 0 20px 20px #000, 0 30px 50px #000;
      opacity: 0.2;
    }
    .lb-mask {
      position: relative;
      background: $blue-ci-bright;
      overflow: hidden;
      max-height: 0;
      transition: all .4s;
    }
    .lb-loginField, .lb-loggedIn {
      font-size: 10px;
      padding: 0 2em;
      position: relative;
      z-index: 1;
      transition: all .4s;
    }
    .lb-loggedIn {
      //border-top: 1px solid $blue-ci;
      background: $blue-ci-bright;
      padding-bottom: 2em;

      label {
        font-size: 1.8em;
        font-weight: 800;
        margin-bottom: 10px;
      }
      .lb-username {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 20px;
      }
    }
    .btn {
      min-width: 100%;
      margin: 0;
      border: none;
      background: transparent;
      cursor: pointer;
      span {
        font-size: 1.8em;
      }
    }
    .lb-close {
      cursor: pointer;
      float: right;
      text-align: right;
      display: block;
      width: 100%;
      height: 15px;
      margin: 10px 0;
      transition: all .3s;
      svg {
        height: 100%;
      }
      &:hover, &:focus, &:active {
        color: $yellow;
      }
    }
    form {
      text-transform: none;

      input {
        font-weight: 200;
        font-size: 1.8em;
        border: none;
        border-radius: 3px;
        margin-bottom: 10px;
        &:focus {
          box-shadow: inset 0 0 0 60px #fff;
        }
      }
    }
    .lb-forgotPassword {
      display: block;
      text-align: right;
      font-weight: 800;
      padding-bottom: 10px;
      margin-bottom: 10px;
      color: #fff;
      &:hover, &:focus, &:active {
        color: $yellow;
      }
    }
    .lb-register {
      background: $blue-ci;
      padding: 1.5em 2em 2em;
      margin: 0 -2em;
      label {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 15px;
      }
    }
    &.loggedIn {
      .lb-loginField {
        display: none;
      }
      .lb-loggedIn {
        display: block;
      }
    }
    &.login-open {
      .lb-mask {
        max-height: 360px;
      }
      &.loggedIn {
        .lb-mask {
          max-height: 360px;
        }
        .lb-loginField {
          display: none;
        }
        .lb-loggedIn {
          display: block;
        }
      }
    }
  }
}

@media all and(max-width: 1100px) {
  .ksc-loginBar {
    .item {
      padding-right: 7px;
      > a > span {
        visibility: hidden;
        width: 0;
        pointer-events: none;
      }
      &.lb-login {
        > a > span {
          display: none;
        }
      }
    }
  }
}

@media all and(max-width: 479px) {
  .ksc-loginBar {
    padding: 0 10px;
  }
}
@media all and(max-width: 450px) {
  .ksc-loginBar {
    .lb-loginBox {
      right: -2em;
      width: 100vw;
      .lb-register {
        margin: 0 -4em;
        padding: 1.5em 4em 2em;
      }
      &.login-open {
        .lb-mask {
          padding: 0 2em;
        }
      }
    }
    .hide-sm {
      display: none !important;
    }
  }
}
@media all and(max-width: 340px) {
  .ksc-loginBar {
    .lb-loginBox.login-open {
      .lb-mask {
        padding: 0;
      }
    }
  }
}

@media all and(max-width: 350px) {
  .ksc-loginBar {
    .lb-fschlule {
      display: none;
    }
  }
}


@media all and(max-width: 374px) {
  .ksc-loginBar {
    .lb-mitglied {
      display: none;
    }
  }
}
