//==========FONTS==========//
main {
  h1, .h1 {
    font-size: 5.5em;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
    margin: 0 0 1em 0;
    color: $grey-ci;
    font-family: American_Captain_01;
    + h2, h2 {
      font-size: 2.25em;
      margin: -2.2em 0 2em 0;
      text-transform: none;
      font-weight: 700;
      border: none;
      padding: 0;
    }
  }
  h2, .h2, h3, .h3 {
    color: $blue-ci;

    font-size: 3.25em;
    font-weight: normal;
  }
  h2, .h2 {
    text-transform: uppercase;
    + h3, .h3{
      font-weight: normal;
      font-size: 2.25em;
      line-height: 1.618em;
    }
  }
  h3, .h3 {
    line-height: 1.25;
  }

  section {
    h1, .h1 {
      font-style: normal;
      font-size: 6em;
      text-align: center;
      margin: 0 0 1em 0;
      + h2, .h2{
        text-align: center;
      }
    }
  }
blockquote p{
  font-size: 2em;
}
  p, ul li, ol li {
    line-height: 1.618;
    font-size: 2.25em;
    font-weight: 300;
    margin-bottom: 2em;
    &.asterisk {
      font-size: 1.5em;
      font-weight: 500;
      color: $grey-ci-bright;
      text-align: left !important;
      + p.asterisk {
        margin-top: -1em;
      }
    }
    b, strong {
      font-weight: 500;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
    a {
      display: inline-block;
      position: relative;
      font-weight: 500;
      color: $grey-ci;
      padding: 2px 0;
      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 1px;
        background: $yellow;
        left: 0;
        bottom: -0.2em;
        transition: $t-fast;

      }
      &:after {
        width: 0;
        height: 120%;
        top: -10%;
        left: -10px;
        transform: skewX(-3deg) skewY(-2deg);
      }
      &:hover, &:focus {
        color: $grey-ci-dark;
        &:before {
          opacity: 0;
        }
        &:after {
          width: calc(100% + 20px);
        }
      }
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {
  main blockquote {
    font-size: 2.25em;
  }
}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {
  main blockquote {
    font-size: 2.125em;
  }
}

@media (max-width: 991px) {
  main blockquote {
    font-size: 1.9em;
  }
}

@media (max-width: 767px) {
  main{
    blockquote {
      font-size: 1.75em;
      max-width: 100% !important;
      margin-left: 0 !important;
    }
    p, ul li, ol li {
      font-weight: 400;
      b, strong, a{
        font-weight: 600;
      }
    }
  }
  section h1, section .h1 {
    hyphens: auto;
  }
}

@media (max-width: 479px) {
  main {
    section {
      h1, .h1 {
        font-size: 11.8vW;
      }
    }
  }
}

@media (max-width: 375px) {

}