//==========SECTIONS==========//

section{
  position: relative;
  padding: 6em 0;
  &.section-white{
    background: #fff;
  }
  &.section-grey{
    @include gradient_section-grey;
  }
  &.section-blue{
    color:#fff;
    h1, .h1{
      color: #fff;
      margin-top: 0.5em;
    }
  }
}



//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px){

}
@media (min-width: 768px){

}
@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 375px){

}