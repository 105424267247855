//==========Quicklinks==========//

.quicklinks{
  $itemMargin: 15px;
  padding: 4em 0 calc(6em - 35px);
  @include gradient_ci-turquoise;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  .iOS &{
    background-attachment: scroll;
    background-position: center bottom;
    background-size: 150%;
  }
  .container{
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    width: 100%;
    .slick-list{
      padding: 0 15px;
    }
  }
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 4em;
    opacity: .5;
    top:0;
    left: 0;
    position: absolute;
    @include topshadow_darkblue_top;
  }
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 5em;
    opacity: .5;
    bottom:0;
    left: 0;
    position: absolute;
    @include topshadow_darkblue_bottom;
  }
  .item{
    width: 20%;
    float: left;
    position: relative;
    z-index: 2;
    padding: 15px 15px 35px;
    .inner{
      display: block;
        color: #fff;
      background: rgba(255, 255, 255, 0.05);
      margin: 0 calc(#{$itemMargin} / 2);
      text-align: center;
      padding: 3.5em 0;
      border-radius: 3.5em;
      @include boxshadow_darkblue_soft;
      @media all and (max-width: 450px){
        max-width: 250px;
        margin: 0 auto;
      }
      .ksc-icon{
        font-size: 9em;
        color: currentColor;
          &.wikis{
              font-size: 4.5em;
              margin: 0.75em 0;
            @media all and (max-width: 649px) and (min-width: 550px){
              font-size: 3.5em;
              margin: 1.14em 0.25em;
            }
            @media all and (max-width: 449px) and (min-width: 550px){
              font-size: 3.5em;
              margin: 1.14em 0.25em;
            }
          }

      }
      .h2{
        color: currentColor;
        font-size: 1.25em;
        text-transform: uppercase;
        font-weight: 900;
        margin-top: 1.8em;
        transition: $t-medium;
      }
    }
    &:last-child{
      .inner{
      }
    }
    &:hover{
      z-index: 3;
      .inner{
          background: rgba(255, 255, 255, 0.1);
        .h2{
          color: $yellow;
        }
      }
    }
  }
  ul.slick-dots {
    z-index: 13;
    bottom: 0;
    margin: 0;
    li{
      background: transparentize(#fff, .2);
    }
  }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1200px){
  .quicklinks .item {
    padding: 15px 15px 35px;
  }
}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 350px){
  .quicklinks .item {

    padding-left: 35px;
    padding-right: 35px;
  }
}