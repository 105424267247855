$font-path: "../webfont/ksc-icons" !default;

$ksc-icon-app-android: "\e900";
$ksc-icon-app-apple: "\e901";
$ksc-icon-app-handy: "\e902";
$ksc-icon-arrow: "\e903";
$ksc-icon-arrow-round: "\e904";
$ksc-icon-arrow-triangle: "\e905";
$ksc-icon-bundesliga: "\e906";
$ksc-icon-event-calender: "\e907";
$ksc-icon-event-family: "\e908";
$ksc-icon-event-game: "\e909";
$ksc-icon-event-press: "\e90a";
$ksc-icon-event-pylone: "\e90b";
$ksc-icon-facebook: "\e90c";
$ksc-icon-facebook-round: "\e90d";
$ksc-icon-fussballschule: "\e90e";
$ksc-icon-instagram: "\e90f";
$ksc-icon-instagram-round: "\e910";
$ksc-icon-ksc-logo: "\e911";
$ksc-icon-membership: "\e912";
$ksc-icon-shop: "\e913";
$ksc-icon-shop-small: "\e914";
$ksc-icon-snapchat: "\e915";
$ksc-icon-snapchat-round: "\e916";
$ksc-icon-ticket: "\e917";
$ksc-icon-ticket-small: "\e918";
$ksc-icon-x: "\e919";
$ksc-icon-x-round: "\e91a";
$ksc-icon-wikis: "\e91b";
$ksc-icon-wildpark-tv: "\e91c";
$ksc-icon-youtube: "\e91d";
$ksc-icon-youtube-big: "\e91e";
$ksc-icon-youtube-round: "\e91f";
$ksc-icon-whatsapp-round: "\e920";
$ksc-icon-bluesky: "\e933";
$ksc-icon-bluesky-round: "\e934";
$ksc-icon-football: "\e921";
$ksc-icon-arrow-right: "\e922";
$ksc-icon-bfv: "\e923";
$ksc-icon-dfb: "\e924";
$ksc-icon-fussballschule_V2: "\e925";
$ksc-icon-arrow-triangle_down: "\e926";
$ksc-icon-newsletter_V2: "\e927";
$ksc-icon-newsletter_V1: "\e928";
$ksc-icon-linkedin: "\e929";
$ksc-icon-audio-round: "\e92d";
$ksc-icon-video-round: "\e92f";
$ksc-icon-live-round: "\e931";
$ksc-icon-gallery-round: "\e92a";
$ksc-icon-alarmclock: "\e92b";
$ksc-icon-bell: "\e92c";
$ksc-icon-ksc-eV: "\e92e";
$ksc-icon-ksc-logo_inverse: "\e930";
$ksc-icon-tiktok: "\e932";



@font-face {
  font-family: 'ksc-icons';
  src:  url('#{$font-path}/ksc-icons.eot?221ldfess');
  src:  url('#{$font-path}/ksc-icons.eot?221ldfess#iefix') format('embedded-opentype'),
    url('#{$font-path}/ksc-icons.woff2?221ldfess') format('woff2'),
    url('#{$font-path}/ksc-icons.ttf?221ldfess') format('truetype'),
    url('#{$font-path}/ksc-icons.woff?221ldfess') format('woff'),
    url('#{$font-path}/ksc-icons.svg?221ldfess#ksc-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*="ksc-icon "], .shariff span.fa {
  font-family: 'ksc-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: $t-fast;
}
[class*="ksc-icon "]{
    &:before{
        transition: none;
    }
}
.ksc-icon.app-android {
  &:before {
    content: $ksc-icon-app-android;
  }
}
.ksc-icon.app-apple {
  &:before {
    content: $ksc-icon-app-apple;
  }
}
.ksc-icon.app-handy {
  &:before {
    content: $ksc-icon-app-handy;
  }
}
.ksc-icon.arrow {
  &:before {
    content: $ksc-icon-arrow;
  }
}
.ksc-icon.arrow-round {
  &:before {
    content: $ksc-icon-arrow-round;
  }
}
.ksc-icon.arrow-triangle {
  &:before {
    content: $ksc-icon-arrow-triangle;
  }
}
.ksc-icon.bundesliga {
  &:before {
    content: $ksc-icon-bundesliga;
  }
}
.ksc-icon.event-calender {
  &:before {
    content: $ksc-icon-event-calender;
  }
}
.ksc-icon.event-family {
  &:before {
    content: $ksc-icon-event-family;
  }
}
.ksc-icon.event-game {
  &:before {
    content: $ksc-icon-event-game;
  }
}
.ksc-icon.event-press {
  &:before {
    content: $ksc-icon-event-press;
  }
}
.ksc-icon.event-pylone {
  &:before {
    content: $ksc-icon-event-pylone;
  }
}
.ksc-icon.facebook {
  &:before {
    content: $ksc-icon-facebook;
  }
}
.ksc-icon.facebook-round, .fa-facebook {
  &:before {
    content: $ksc-icon-facebook-round;
  }
}
.ksc-icon.fussballschule {
  &:before {
    content: $ksc-icon-fussballschule_V2;
  }
}
.ksc-icon.instagram {
  &:before {
    content: $ksc-icon-instagram;
  }
}
.ksc-icon.instagram-round {
  &:before {
    content: $ksc-icon-instagram-round;
  }
}
.ksc-icon.ksc-logo {
  &:before {
    content: $ksc-icon-ksc-logo;
  }
}
.ksc-icon.membership {
  &:before {
    content: $ksc-icon-membership;
  }
}
.ksc-icon.shop {
  &:before {
    content: $ksc-icon-shop;
  }
}
.ksc-icon.shop-small {
  &:before {
    content: $ksc-icon-shop-small;
  }
}
.ksc-icon.snapchat {
  &:before {
    content: $ksc-icon-snapchat;
  }
}
.ksc-icon.snapchat-round {
  &:before {
    content: $ksc-icon-snapchat-round;
  }
}
.ksc-icon.ticket {
  &:before {
    content: $ksc-icon-ticket;
  }
}
.ksc-icon.ticket-small {
  &:before {
    content: $ksc-icon-ticket-small;
  }
}
.ksc-icon.x {
  &:before {
    content: $ksc-icon-x;
  }
}
.ksc-icon.x-round, .fa-twitter {
  &:before {
    content: $ksc-icon-x-round;
  }
}
.ksc-icon.bluesky {
  &:before {
    content: $ksc-icon-bluesky;
  }
}
.ksc-icon.bluesky-round {
  &:before {
    content: $ksc-icon-bluesky-round;
  }
}
.ksc-icon.wikis {
  &:before {
    content: $ksc-icon-wikis;
  }
}
.ksc-icon.wildpark-tv {
  &:before {
    content: $ksc-icon-wildpark-tv;
  }
}
.ksc-icon.youtube {
  &:before {
    content: $ksc-icon-youtube;
  }
}
.ksc-icon.youtube-big {
  &:before {
    content: $ksc-icon-youtube-big;
  }
}
.ksc-icon.youtube-round {
  &:before {
    content: $ksc-icon-youtube-round;
  }
}
.ksc-icon.whatsapp-round, .fa-whatsapp {
  &:before {
    content: $ksc-icon-whatsapp-round;
  }
}
.ksc-icon.football {
  &:before {
    content: $ksc-icon-football;
  }
}
.ksc-icon.arrow-right {
  &:before {
    content: $ksc-icon-arrow-right;
  }
}
.ksc-icon.bfv, .ksc-icon.event-bfv  {
  &:before {
    content: $ksc-icon-bfv;
  }
}
.ksc-icon.dfb, .ksc-icon.event-dfb  {
  &:before {
    content: $ksc-icon-dfb;
  }
}
.ksc-icon.fussballschule {
  &:before {
    content: $ksc-icon-fussballschule_V2;
  }
}
.ksc-icon.arrow-triangle_down {
  &:before {
    content: $ksc-icon-arrow-triangle_down;
  }
}
.ksc-icon.newsletter_V2 {
    &:before {
        content: $ksc-icon-newsletter_V2;
    }
    header &{
        font-size:2em !important;
        &:before{
            color: #fff;
        }
    }
}
.ksc-icon.newsletter_V1 {
  font-size:2em !important;
    &:before {
        content: $ksc-icon-newsletter_V1;
        color:  #fff;
    }
}
.ksc-icon.linkedin {
  &:before {
    content: $ksc-icon-linkedin;
  }
}
.ksc-icon.audio-round {
  &:before {
    content: $ksc-icon-audio-round;
  }
}

.ksc-icon.video-round {
  &:before {
    content: $ksc-icon-video-round;
  }
}
.ksc-icon.live-round {
  &:before {
    content: $ksc-icon-live-round;
  }
}
.ksc-icon.gallery-round {
  &:before {
    content: $ksc-icon-gallery-round;
  }
}

.ksc-icon.alarmclock {
  &:before {
    content: $ksc-icon-alarmclock;
  }
}
.ksc-icon.bell {
  &:before {
    content: $ksc-icon-bell;
  }
}

.ksc-icon.ksc-eV {
  &:before {
    content: $ksc-icon-ksc-eV;
  }
}
.ksc-icon.ksc-logo_inverse {
  &:before {
    content: $ksc-icon-ksc-logo_inverse;
  }
}
.ksc-icon.tiktok {
  &:before {
    content: $ksc-icon-tiktok; 
  }
}