.cookieconsent-optout-marketing {
  display: none;
}

.wildparkTV {
  .cookieconsent-optout-marketing {
    text-align: center;
    padding-bottom: 3em;

    p {
      font-size: 2em;
    }

    span {
      border-bottom: 1px solid $yellow;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

section.opta {

  .cookieconsent-optout-marketing {
    text-align: center;
    padding-bottom: 3em;
    display: none;

    p {
      font-size: 1.4em;
      background: $grey-ultralight;
      padding: 1em;
      @media (min-width: 1280px) {
        margin-top: 2.4em;
      }

      @media (max-width: 1279px) {
        max-width: 600px;
        margin: 0 auto;
      }
    }

    span {
      border-bottom: 1px solid $yellow;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.yumpu .cookieconsent-optout-marketing {
  text-align: center;
  padding-bottom: 3em;
  display: none;

  p {
    font-size: 1.8em;
    background: $grey-ultralight;
    padding: 1em;
  }

  span {
    border-bottom: 1px solid $yellow;
    white-space: nowrap;
    cursor: pointer;
  }
}

.article .cookieconsent-optout-marketing {
  text-align: center;
  padding-bottom: 3em;
  display: none;

  p {
    font-size: 0.8em;
    background: $grey-ultralight;
    padding: 1em;
  }

  span {
    border-bottom: 1px solid $yellow;
    white-space: nowrap;
    cursor: pointer;
  }
}

#CybotCookiebotDialog {
  box-shadow: none !important;
  border-top: 5px solid $blue-ci !important;

  #CybotCookiebotDialogBodyContentTitle {
    font-family: American_Captain_01, Arial, Helvetica, sans-serif;
    font-size: 1.8em;
    color: $blue-ci;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.6px;
  }
}

#CookieDeclarationUserStatusPanel {
  #CookieDeclarationUserStatusLabel {
    font-size: 2.25em;
    color: $blue-ci;
    padding-bottom: 1.5em;
  }

  #CookieDeclarationConsentIdAndDate {
    font-size: 0.9em;
    color: $gray-base;
    padding-top: 1em;

    span {
      padding-bottom: 1em;
    }
  }

  #CookieDeclarationChangeConsent {
    padding-bottom: 1em;

    a {
      font-size: 2.0em;
      border-bottom: 1px solid $yellow;
      color: $blue-ci;
    }
  }
}

.CookieDeclarationType {
  border: 1px solid $grey-ci-light !important;

  a {
    border-bottom: 1px solid $yellow;
    color: $blue-ci;
  }

  .CookieDeclarationTable {
    font-size: 1.3em;
  }

  p.CookieDeclarationTypeDescription {
    line-height: 1.2;
  }

  .CookieDeclarationTableCell {
    border-bottom: 1px solid $grey-ci-light !important;
  }
}

.content-hidden-cookie {
  visibility: hidden;
}

.content-hidden-cookie-message {
  position: absolute;
  width: calc(100% - 60px);
  height: 100%;

  &__flex {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    &__text {
      font-size: 1.4em;
      text-align: center;
      background: #f0f1f2;
      padding: 1em;
      color: black;
    }
  }
}


.content-hidden-cookie-link {
  color: $blue-ci;
  font-weight: bold;

  &:active {
    color: $blue-ci;
  }
}

#CybotCookiebotDialog img {
  width: unset;
  height: unset;
  display: initial;
}
