// ctype SCSS
.ce-diary {
  font-size: 1.2em;

  h2 {
    font-family: "American_Captain_01", Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-align: center;
    font-size: 4.0em;
    margin-bottom: 0.2em;
    color: $blue-ci;
  }

  h3 {
    font-family: "American_Captain_01", Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-align: center;
    font-size: 3.0em;
    margin-bottom: 0.2em;
    color: $grey-ci-dark;
  }

  .text {
    font-size: 0.7em;

    p, ul li, ol li {
      line-height: 1.5;
      font-size: 2.4em;
      font-weight: 300;
      margin-bottom: 1em;
    }
  }
}

main main section.ce-diary:first-child {padding-top:0 !important;}