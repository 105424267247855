//==========LATEST MATCHES==========//

.latestMatches {
  .item {
    display: inline-block;
    transform-origin: center center;
    transform: translateX(0) scale(.9);
    .day {
      font-size: 1.95em;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 0.25em;
      color: $grey-ci-dark;
      transition: $t-fast;
    }
    time {
      font-size: 1.3em;
      color: $font_lightgrey;
      .divider {
        margin: 0 5px;
      }
    }
    .resultBox {
      text-align: center;
      .result {
        letter-spacing: 0.025em;
        display: inline-block;
        vertical-align: middle;
        font-weight: 900;
        color: $blue-ci;
        font-size: 9em;
        width: 2em;
        transition: $t-fast;
      }
      .team {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        width: 11.5em;
        //overflow: hidden;
        img {
          height: auto;
          width: auto;
          max-height: 11.5em;
          max-width: 11.5em;
          display: inline-block;
          &[alt="SC Paderborn 07"] {
            transform: scale(1.4);
          }
        }
        &.home {
        }
        &.away {
        }
      }
    }
    &.lastmatch, &.nextmatch {
      position: absolute;
      opacity: .75;
      transform: translateX(0) scale(.55);
      .day {
        margin-top: 1.25em;
        margin-bottom: 0;
      }
      time {
        display: none;
      }
      .resultBox {
        .result {
          color: $font_darkgrey;
        }
      }
      img {
        filter: grayscale(100%); /* Standard */
        -webkit-filter: grayscale(100%); /* Webkit */
        filter: url(/elements/grayscale.svg#desaturate); /* Firefox 4-34 */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(1); /* Old WebKit */
      }
    }
    &.lastmatch {
      transform-origin: left center;
      left: 30px;
    }
    &.nextmatch {
      transform-origin: right center;
      right: 30px;
    }
    &:hover, &:focus {
      .day {
        transform: scale(1.25);
      }
      .result {
        color: $yellow !important;
      }
    }
  }
  .btn {
    margin-top: 1.5em;
  }
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
  .latestMatches {
    > .container {
      width: 100%;
    }
    .item {
      &.nextmatch, &.lastmatch {
        font-size: 7px;
        .day {
          font-size: 3.5em;
          margin-bottom: .25em;
          margin-top: 1em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .latestMatches {
    .item {
      &.nextmatch, &.lastmatch {
        display: none;
      }
      &:nth-child(2) {
        .resultBox {
          font-size: 8.5px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .latestMatches {
      .resultBox {
        font-size: 1.9vW !important;
          margin-top: 1.5em;
      }
  }
}

@media (max-width: 413px) {
  .latestMatches {
    > .container {
      padding: 0;
    }
    .btn {
      max-width: calc(100% - 60px);
    }
  }
}

@media (max-width: 375px) {
  .latestMatches {
    .item {
      &:nth-child(2) {
        .resultBox {
        }
      }
    }
  }
}