//==========ADSECTION==========//

section.ads {
  padding: 8em 0;
  .ad728 {
    padding: 0;
    .googleAd728 {
      width: 728px;
      margin: 0 auto;
      iframe {
        max-width: 728px;
        width: 100%;
        height: auto;
      }
    }
  }
  .ad1400 {
    padding: 0;
    .googleAd1400 {
      width: 1400px;
      margin: 0 auto;
      iframe {
        width: 1400px;
        height: 220px;
      }
    }
  }
  &.padding-small {
    padding: 4em 0;
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {
  section.ads {
    .ad1400 {
      padding: 0;
      .googleAd1400 {
        width: 700px;
        margin: 0 auto;
        iframe {
          width: 700px;
          height: 110px;
        }
      }
    }
  }
}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  section.ads {
    .ad1400 {
      padding: 0;
      .googleAd1400 {
        width: 300px;
        margin: 0 auto;
        iframe {
          width: 300px;
          height: 250px;
        }
      }
    }
    .ad728 {
      padding: 0;
      .googleAd728 {
        width: 300px;
        margin: 0 auto;
        iframe {
          width: 300px;
          height: 250px;
        }
      }
    }
  }
}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}