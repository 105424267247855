//==========Box im Textelement==========//
.textElement {
    .wrap {
        margin-bottom: 6em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.box {
    $boxPadding: 2em;
    margin-bottom: 0;

    .boxHead {
        padding: $boxPadding;
        color: #fff;
        font-size: 1em;
        background: $blue-ci-bright;
        border-bottom: 0.7em solid #fff;

        span {
            font-size: 1.6em;
            text-transform: uppercase;
            font-weight: 900;
        }
    }

    .boxContent {
        padding: $boxPadding;
        background: $grey-ultralight;

        span {
            font-size: 1.6em;
            font-weight: 300;

            p {
                line-height: 1.5;
                font-size: 100%;
                margin-bottom: 1em;
            }

            ul, ol {
                margin: 0 0 1em 0;
                padding-left: 1em;

                li {
                    margin-bottom: 1em;
                    line-height: 1.5;
                    font-size: 1em;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .btn.small {
            display: block;
        }
    }
}
.newsContent{
    p{
        &:last-child{
            margin-bottom: 2em !important;
        }
    }
}
.box-newsRelated {
    float: right;
    @media all and (min-width: 650px) {
        padding-right: 0;
    }
    @media all and (max-width: 649px) {
        margin-top: 3em;
        padding: 0;
    }

    .box {
        font-size: 10px;
        margin: 1rem 0 2rem;
    }

    .boxContent {
        padding: 1.5em;
    }

    .boxLink {
        padding: .5em;
        font-size: 10px;
        display: block;
        margin-bottom: .5rem;
        transition: background-color .35s;
        @media all and (min-width: 650px){
            &:last-child {
                margin-bottom: 0;
            }
        }
        .title {
            color: $blue-ci;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 1.4em;
            line-height: 1.25;
            margin-bottom: 0.25em;
        }

        .desc {
            color: $font_darkgrey;
            text-transform: none;
            font-weight: 300;
            font-size: 1.75em;
            line-height: 1.25;
            margin: 0;
            transition: color .25s;
        }

        &:hover, &:focus, &:active {
            background: #fff;

        }
    }

    @media all and (max-width: 649px) and (min-width: 540px) {
        .boxContent {
            display: flex;
            flex-wrap: wrap;
            .boxLink {
                flex: 0 0 calc(50% - 1.5rem);
                max-width: 50%;
                &:nth-child(odd) {
                    margin-right: 1.5rem;
                }
                &:nth-child(even) {
                    margin-left: 1.5rem;
                }
            }
        }
    }
    @media all and (min-width: 650px){
        &.pos-left, &.pos-right{
            width: 50%;
            figure{
                margin-top: 1em;
                figcaption{
                    font-size: 1.2em;
                    font-weight: 400;
                }
            }
        }
        &.pos-left{
            float: left;
            padding-left: 0;
            padding-right: 30px;
        }
    }
    @media all and (max-width: 1023px){
        &.pos-left, &.pos-right{
            figure{
                figcaption{
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }
    @media all and (max-width: 649px){
        &.pos-left, &.pos-right{
            figure{
                margin-top: 0;
                figcaption{
                    font-size: 11px;
                }
            }
        }
    }
}

@media all and (max-width: 649px){
    .newsContent{
        display: flex;
        flex-wrap: wrap;
        .box-newsRelated{
            order: 2;
            margin-top: 0;
            .box{
                margin-top: 0;
            }
        }
    }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
    .box {
        font-size: 125%;
    }
}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}