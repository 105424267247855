//==========Matchday - Navigation==========//
.matchdayNav {
    padding: 0;

    ul {
        li {
            float: left;
            width: 33.332%;
            font-size: 1em;
            text-align: center;
            margin: 0;
            padding: 0;

            a {
                border: none;
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
                display: block;
                color: $grey-ci;
                font-size: 2.8em;
                text-transform: uppercase;
                padding: 1em 0;
                margin: 0;
                font-weight: 300;
                cursor: pointer;
                background: $grey-superlight;

                &:before, &:after {
                    display: none;
                }
            }

            &.slick-active {
                a {
                    background: #fff;
                    color: $blue-ci;
                    font-weight: 700;
                }
            }

            &:first-child {
                a {
                    border-left: 0;
                }
            }

            &:last-child {
                a {
                    border-right: 0;
                }
            }
        }
    }
}

.matchdayNav-bottom {
    .matchdayNav {
        nav {
            position: relative;
            border-top: 1px solid $grey-superlight;
            border-bottom: 1px solid $grey-superlight;

            ul {
                display: block;
                text-align: center;

                li {
                    float: none;
                    width: auto;
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    border: none !important;
                    display: inline-block;

                    a {
                        border: none !important;
                        display: block;
                        color: $grey-ci;
                        font-size: 2em;
                        text-transform: none;
                        padding: 1em 1em;
                        margin: 0;
                        font-weight: 700;
                        background: none;

                        &:hover, &:focus {
                            color: $blue-ci;
                        }
                    }

                    &.slick-active {
                        a {
                            color: $yellow;
                        }
                    }
                }
            }
        }
    }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {
    .matchdayNav ul li a {
        font-weight: 400;
    }
}

@media (max-width: 1199px) {
    .matchdayNav {
        ul li {
            a {
                font-size: 1.95vW;
            }
        }
    }
}

@media (max-width: 991px) {
    .matchdayNav {
        ul li {
            a {
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 767px) {
    .matchdayNav ul li {
        float: left;
        width: 50%;
        text-align: center;
        margin: 0;
        padding: 0;

        a {
            font-size: 3.25vW;
            border: none;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
        }

        &:nth-child(even) {
            a {
                border-right: none;
            }
        }

        &.slick-active a {
            background: $grey-ci-dark;
            color: #fff;
        }
    }
}

@media (max-width: 649px) {
    .matchdayNav-bottom {
        .matchdayNav {
            nav {
                padding: 1em 30px;
                min-width: 75%;

                ul {
                    li {
                        float: none;
                        width: auto;
                        text-align: center;
                        margin: 0;
                        padding: 0;
                        border: none !important;
                        display: inline-block;

                        a {
                            font-size: 1.5em;
                            padding: .5em;
                        }

                    }
                }
            }
        }
    }
}

@media (max-width: 499px) {
    .matchdayNav-bottom {
        .matchdayNav ul li {
            float: left;
            width: 50%;
            text-align: center;
            margin: 0;
            padding: 0;

            &:nth-child(2) {
                border-right: none;
            }

            a {
                font-size: 3.5vW;
            }
        }
    }
}


@media (max-width: 420px) {
    .matchdayNav ul li {
        a {
            font-size: 4vW;
        }
    }
}

@media (max-width: 375px) {

}

