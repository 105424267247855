//==========ShopTeaser==========//

main .shopTeaser {
    position: relative;
    background: url("../Img/Photos/shopBG.jpg") no-repeat center bottom;
    background-size: cover;
    padding: 6em 0 10em;
    .h1 {
        font-weight: normal;
        color: #fff;
        font-size: 6em;
        margin: 0;
        a {
            color: #fff;
            display: inline-block;
            position: relative;
            font-family: $font2;
            text-shadow: 0 0.13em 0.175em rgba(0,0,0,0.35);
            &:after {
                font-family: 'ksc-icons';
                font-weight: normal;
                font-style: normal;
                font-size: 0.85em;
                position: absolute;
                content: $ksc-icon-arrow-round;
                right: 0;
                opacity: 0;
                transform: rotate(180deg) scale(0.8) translate(1rem);
                transition-delay: .03s;
                color: #fff;
                text-shadow: 0 -0.13em 0.175em rgba(0,0,0,0.35);
            }
            &:hover {
                opacity: .65;
                color: #000;
                &:after {
                    opacity: 1;
                    transform: rotate(180deg) scale(1) translate(-6.5rem);
                    color: #000;
                }
            }
        }
    }
    .overlay {
        //position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .item {
        width: calc(100% / 5);
        margin-top: 2em;
        .name{
            opacity: .75;
            color: #000;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 1.4;
            text-align: center;
            margin: 0 1.5rem;
            transform: translateY(1rem);
            opacity: 0;
            transition: all .25s;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .price {
            opacity: .65;
            color: #000;
            display: inline-block;
            transition: $t-fast;
            margin: 1rem 0 5rem;
            span {
                font-size: 5em;
                position: relative;
                font-family: $font2;
            }
        }
        .product {
            display: block;
            margin: 0 .5em;
            text-align: center;
            figure{
                margin: 0 1rem;
                border-radius: 2.25em;
                overflow: hidden;
                background: #fff;
                box-shadow: 0 1.5rem 5rem -2.5rem #000;
                transform: scale(0.92);
                transition: transform .35s;
            }
            img {
                background: #fff;
                padding: 1rem;
                border-radius: 2.2rem;
                overflow: hidden;
                transition: $t-medium;
            }
            &:hover, &:focus {
                .name{
                    opacity: .75;
                    transform: translateY(-1.25rem);
                }
                .price {
                    opacity: 1;
                    color: $blue-ci;
                }
                figure {
                    transform: scale(1);
                }
            }
        }
    }

    .shopSlider {
        margin-top: 3em;
    }
    .dots {
        position: absolute;
        width: 100%;
        bottom: 1.75em;
        ul.slick-dots {
            position: static;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            padding: 0;
            margin: 0;
            li {
                background: #fff;
                box-shadow: 0.15em 0.2em 0.6em;
                &.slick-active {
                    background: $yellow;
                }
            }
        }
    }
}
.touch{
    main .shopTeaser .item .name{
        opacity: .75;
        transform: none;
    }
}
@media (max-width: 480px) {
    main .shopTeaser .h1 {
        font-size: 11vW;
    }
}
