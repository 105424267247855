/*BASE*/
html {

}

body, html {
  height: 100%;
  min-height: 100%;
  //overflow-x: hidden;
}

body {
  background: #ccc;
  color: $font_darkgrey;
  &.navOpen {
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vH;
    max-height: 100vH;
  }
}

* {
  @include box-sizing(border-box);
}

*:before,
*:after {
  @include box-sizing(border-box);
}

#wrap {
  max-width: 1920px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  z-index: 99;
}

footer, main {
  overflow: hidden;
  main{
    overflow: visible;
  }
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  &:focus {
    outline: none !important;
  }
}

a {
  text-decoration: none;
  transition: $t-fast;
  &:before, &:after {
    transition: $t-fast;
  }
}

time {
  text-decoration: none !important;
  color: inherit;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

figure {
  overflow: hidden;
  figcaption {
    background: $grey-ultralight;
    padding: 1em;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.35;
    color: $grey-ci-dark;
  }
}

figure, blockquote {
  margin: 0;
}

blockquote {
  color: $blue-ci;
  font-size: 2.6em;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  position: relative;
  padding: 2em 0;

  p {
    font-size: 1em;
    line-height: 1.25 !important;
    margin-bottom: 0.5em !important;
    &.blockquote-person {
      text-align: center;
      color: $grey-ci-bright;
      font-size: 1em !important;
      font-style: normal;
    }
  }
  > p.blockquote-person {
    margin-top: 0.5em;
    line-height: 1.5 !important;
    font-style: italic;
  }
  &:before {
    position: absolute;
    display: block;
    content: '„';
    line-height: 1;
    font-weight: 700;
    font-size: 3em;
    color: $blue-ci;
    top: -0.55em;
    left: 50%;
    transform: translateX(-50%);
  }
}

hr {
  height: 0;
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 0;
  border: none;
  border-bottom: 1px solid $grey-ci-light;
}

.container > hr {
}

[role="button"] {
  cursor: pointer;
}

.row.centered {
  text-align: center;
  vertical-align: top;
  [class*="col-"] {
    text-align: left;
    margin-right: -4px;
    display: inline-block;
    vertical-align: top;
    float: none;
  }
}

.centered, .align-center {
  text-align: center;
}

.table {
  display: table;
  .tableCell {
    display: table-cell;
    vertical-align: middle;
  }
}

.container .container {
  padding: 0 !important;
  width: 100% !important;
}
main .container .row main .container .row .person{
    padding-left: 30px;
    padding-right: 30px;
}

nav, .nav {
  ul, nav ol {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}

.nav {
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

table.tablestyle {
  width: 100%;
  font-size: 1.2em;
  tr {
    td, th {
      text-align: center;
      border-right: 0.25em solid #fff;
      padding: 0 1em;
      line-height: 1.5;
      a {
        color: $blue-ci;
        font-weight: 700;
      }
      &:last-child {
        border: none;
      }
      span {
        color: $font_darkgrey;
        font-weight: 900;
      }
    }
    &:nth-child(even) {
      background: rgb(226, 227, 228);
    }
    &:nth-child(odd) {
      background: rgb(236, 237, 238);
    }
  }
  thead {
    tr {
      th {
        font-weight: 900;
        text-transform: uppercase;
        background: rgb(216, 217, 218);
        padding: 0 1em;
        line-height: 1.2;
      }
    }
  }
  tbody {
    tr {
      transition: $t-fast;
      td {
        transition: $t-fast;
        span {
          font-weight: 700;
        }
        &.nationality {
          img {
            width: 30px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
          }
        }
      }
      &:hover, &:focus, &.highlight {
        background: #fff;
        td {
          border-color: rgb(236, 237, 238);
        }
        &.highlight td {
          border: 2px solid $yellow;
          border-left: none;
          border-right: none;
        }
      }
    }
  }
}

*::selection, *::-moz-selection {
  background-color: $yellow;
  color: #fff;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.zoom {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  z-index: 11;
  right: 15px;
  bottom: 15px;
  border: 4px solid #fff;
  background: transparentize(#000, .7);
  transition: $t-fast;
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 4px;
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 1.5px;
    transform: translate3D(-50%, -50%, 0);
  }
  &:after {
    transform: translate3D(-50%, -50%, 0) rotate(180deg);
    width: 4px;
    height: 16px;
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/
@media only screen and (min-width: 1400px) {

}

@media only screen and (max-width: 767px) {
  .tableScroll{
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    table.tablestyle {
      min-width: 800px;
      &.standings tbody tr td.team .teamname {
        display: inline-block;
        text-align: left;
        padding-left: 1em;
      }
    }
  }
}
