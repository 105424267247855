//==========VORLAGE==========//

#bio_ep_bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000 !important;
  opacity: 0.6;
  z-index: 10001;
}

#bio_ep {
  display: none;
  position: fixed;
  width: 500px;
  max-width:95%;
  height: auto;
  left: 50%;
  top: 50%;
  padding:0 !important;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 100002;
}

#bio_ep_close {
  position: absolute;
  left: 100%;
  width: 20px;
  height: 20px;
  color: $blue-ci !important;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  background-color: #5c5c5c;
  cursor: pointer;
  margin-top:-50px !important;
  margin-left:-40px !important;
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}