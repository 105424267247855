.tx-felogin-pi1{
  h3, .h3{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2em;
    margin-bottom: 15px;
    + div {
      margin-bottom: 15px;
      line-height: 1.25;
      font-size: 1.25em;
    }
  }
  legend {
    margin-bottom: 15px;
    font-size: 2em;
    color: $font_darkgrey;
    border: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e5e5;
    display: none;
  }
  label {
    text-transform: uppercase;
    display: inline-block;
    max-width: 100%;
    margin: 10px 0;
    font-size: 12px;
    font-weight: 700 !important;
  }
}

form input[type="text"], form input[type="password"], form input[type="email"] {
  line-height: 1;
  display: block;
  width: 100%;
  padding: 10px 15px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 500px #fff;
  margin-bottom: 15px;
}
input[type="submit"]{
  padding: 10px 15px;
  border-radius: 30px;
  border: none;
  background: #e2e3e4;
  min-width: 50%;
  font-size: 1.5em;
  font-weight: 700;
  margin-top: 15px;
}
