//==========TEAM-SLIDER==========//

section.team {
  &.teamHeader {
    overflow: hidden;
    padding: 0;
    > img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    .teamnav{
      background: $blue-ci;
      margin: 0;
      text-align: center;
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        z-index: 11;
        left: 0;
        bottom: -2px;
        height: 4px;
        background: #e2e3e4;
      }
      .slick-arrow {
        cursor: pointer;
        display: inline-block !important;
        vertical-align: top;
        position: relative;
        z-index: 10;
        transform: none;
        top: auto;
        width: 8em;
        height: 8em;
        text-align: center;
        color: #fff;
        background: $blue-ci;
        //margin-right: -2px;
        .ksc-icon {
          font-size: 3em;
          position: absolute;
          width: 100%;
          top: 50%;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 100%;
          top: 0;
          background: $blue-ci;
        }
        &.slick-left {
          border-right: 1px solid #fff;
          .ksc-icon {
            transform: translateY(-50%)
          }
          &:after {
            left: -5px;
          }
        }
        &.slick-right {
          border-left: 1px solid #fff;
          margin-right: -2px;
          .ksc-icon {
            transform: translateY(-50%) rotate(180deg);
          }
          &:after {
            right: -5px;
          }
        }
        &:hover, &:focus {
          color: $yellow;
        }
      }
      ul {
        width: calc(100% - 16em);
        display: inline-block;
        position: relative;
        z-index: 2;
        vertical-align: top;
        margin-right: -2px;
        text-align: left;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        li {
          height: 8em;
          position: relative;
          width: calc(100% / 6);
          margin-top: 0;
          &:before {
            transform: none;
            z-index: 11;
            left: 0;
            height: 4px;
            right: 0;
            background: $blue-ci-bright;
            opacity: 1;
          }
          .playerFoto {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: auto;
            top: 2px;
            left: 0;
            transform: translateY(-100%);
            img {
              //transition: $t-medium;
              transform: scale(.83);
              transform-origin: center bottom;
            }
          }
          span {
            padding: 0;
            transition: $t-medium;
            &.name {
              font-size: 1.55em;
              font-weight: 400;
              line-height: 1.25;
              text-transform: none;
              padding-right: .5em;
            }
            &.number {
              font-weight: 900;
              font-size: 4.5em;
              text-align: right;
              line-height: 1;
            }
          }
          &:hover, &:focus{
            &:before {
              background: $blue-ci;
            }
          }
          &.slick-current.slick-active {
            &:before {
              background: $blue-ci;
            }
            .playerFoto {
              img {
                transform: scale(0.95);
              }
            }
            span {
              &.number {
                color: $yellow;
              }
            }
          }
          &.stats {
            .number {
              font-size: 1.75em;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .allPlayers{
    padding-top: 6em;
  }
  &.playerDetail {
    text-align: center;
    background: $grey-superlight;
    padding-top: 0;
    .container {
      width: 100% !important;
    }
    .pd {
      float: none;
      display: inline-block;
      transform: none;
    }
    .playerItem {
      position: relative;
      text-align: left;
      .number {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        margin-right: 1.5em;
        margin-left: -3px;
      }
      span {
        font-weight: 900;
        font-size: 7.4em;
        position: relative;
        top: -0.04em;
      }
      .namePos {
        display: inline-block;
        vertical-align: top;
      }
      .name {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 4em;
        color: $grey-ci;
        margin-left: -0.06em;
        margin-bottom: 0.1em;
      }
      .position {
        text-transform: uppercase;
        font-weight: 900;
        color: $grey-ci;
        font-size: 2em;
        margin-bottom: 1em;
      }
      .numberNamePos{
        display: inline-block;
        vertical-align: bottom;
        margin-right: -4px;
        width: calc(100% - 25em);
        overflow: hidden;
        .inner{
          font-size: 12px;
        }
      }
      .playerFoto {
        position: relative;
        display: inline-block;
        margin-right: -4px;
        vertical-align: bottom;
        z-index: 2;
        left: 0;
        top: 0;
        transform: none;
        width: 25em;
        height: 30em;
        overflow: hidden;
        figure {
          position: relative;
          height: 100%;
          overflow: hidden;
          img {
          }
        }
      }
      .data {
        background: #fff;
        padding: 30px;
        position: relative;
        z-index: 10;
      }
      .playerData, .playerStats {
        float: left;
        width: calc(50% - 2em);
        .headline {
          font-weight: 900;
          text-transform: uppercase;
          color: $blue-ci;
          font-size: 2.8em;
          margin: 0.25em 0 1em 0;
        }
        .items {
          .item {
            padding: 1em 0;
            border-bottom: 1px solid $grey-superlight;
            transition: $t-medium;
            .label, .value {
              display: inline-block;
              vertical-align: middle;
              width: calc(50% - 2px);
              font-size: 1.5em;
              font-weight: 700;
            }
            .value {
              font-weight: 500;
              text-align: right;
            }
            &:hover, &:focus {
              background: transparentize($grey-ultralight, .7);
            }
            &:first-child {
              border-top: 1px solid $grey-superlight;
            }
          }
        }
      }
      .playerStats {
        float: right;
      }
    }
  }
  .playerNav {
    margin-top: 5em;
    position: relative;
    z-index: 10;
    .col-md-8 {
      &:nth-child(2) {
        float: right;
      }
    }
    .row {
      margin: 0 -10px;
      [class*="col-"] {
        padding: 0 10px;
      }
    }
    .button {
      background: #fff;
      padding: 1.75em;
      font-weight: 700;
      display: block;
      position: relative;
      cursor: pointer;
      transition: $t-fast;
      span {
        font-size: 1.75em;
        color: $grey-ci;
        transition: $t-fast;
        &.number {
          display: none;
        }
      }
      .ksc-icon {
        font-size: 1.75em;
        position: absolute;
        z-index: 2;
        width: auto;
        top: 50%;
        left: 0.875em;
        transform: translateY(-50%);
        color: $grey-ci;
      }
      &.slide-left {
        text-align: right;
      }
      &.slide-right {
        text-align: left;
        .ksc-icon {
          left: auto;
          right: 0.875em;
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &.autogramm {
        text-align: center;
        background: $blue-ci;
        font-weight: 900;
        text-transform: uppercase;
        span {
          color: #fff;
        }
        &:hover, &:focus {
          background: $blue-ci;
          span {
            color: $yellow;
          }
        }
      }
      &:hover, &:focus {
        background: #ffffff;
        span {
          color: $blue-ci;
        }
        .ksc-icon {
          color: $yellow;
        }
      }
    }

  }
  table.playerslist {
    th {
      font-weight: 900;
      text-transform: uppercase;
      padding: 0 1em;
      height: 5.5em;
      line-height: 1.2;
      span {

        padding: 0 1em;
        display: block;
      }
    }
  }
  .allPlayers{
    table.playerslist{}
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {
  section.team {
    &.playerDetail {
      .playerItem {
        .playerData, .playerStats {
          .items {
            .item {
              .label {
              }
              .value {

              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1100px){
  .allPlayers{
    table.playerslist{
      th{
        padding: 0 !important;
        height: 40px !important;
        span{
          font-size: 10px;
        }
      }
      tr{
        td{
          padding: 5px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  section.team {
    &.playerDetail {
      .playerItem {
        .playerData, .playerStats {
          .headline {
            font-size: 2.25em;
          }
          .items {
            .item {
              .label {
              }
              .value {
              }
            }
          }
        }
      }
    }
    .playerNav {
      $arrowWidth: 2em;
      .col-md-8 {
        vertical-align: top;
        &:nth-child(3) {
          clear: left;
        }
        &:nth-child(1), &:nth-child(2) {
          width: 50%;
          float: left;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media  (max-width: 768px) {
  section.team.playerDetail .playerItem .number {
    display: block;
    text-align: left;
  }
}

@media (max-width: 767px) {
  section.team {
    &.playerDetail {
      .pd{width: 100%;}
      .playerItem {
        .playerFoto{
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
        .numberNamePos{
          width: 100%;
          display: block;
          background: $blue-ci;
          padding: 15px 15px 12px;
          .number{
            display: inline-block;
            color: $yellow;
            span{
              font-size: 6.6em;
            }
          }
          .name{
            color: #fff;
          }
          .position{
            margin: 0;
            color: #fff;
          }
        }
        .playerStats {
          margin-top: 30px;
        }
        .playerData, .playerStats {
          float: none;
          width: 100%;
          .items {
            .item {
              .label {
              }
              .value {
              }
            }
          }
        }
        .numberNamePos .inner {
          font-size: 8px;
        }
      }
    }
    .playerNav {
      .button span {
        &.name {
          display: none;
        }
        &.number {
          font-weight: 900;
          font-size: 2.5em;
          display: block;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  section.team {
    .playerNav {
      margin-top: 20px;
    }
    &.playerDetail {
      .pd {
      }
      .playerItem {
        .data {
          padding-left: 15px;
          padding-right: 15px;
        }
        .playerFoto {
          figure {
          }
        }
        .playerStats {
          margin-top: 30px;
        }
        .playerData, .playerStats {
          float: none;
          width: 100%;
          .items {
            .item {
              .label {
              }
              .value {
              }
            }
          }
        }
      }
    }
    .teamnav ul li span.number {
      display: none;
    }
  }
}

@media (max-width: 520px) {
  section.team.playerDetail .playerItem .name {
    font-size: 3.25em;
  }
}

@media (max-width: 413px) {
  section.team {
    &.playerDetail {
      .pd {
        width: 100%;
      }
      .playerItem {
        .name {
        }
        .data {
          padding-left: 15px;
          padding-right: 15px;
        }
        .playerFoto {
          figure {
            img {
            }
          }
        }
        .data {
        }
        .playerData, .playerStats {
          .headline {
            font-size: 2.5em;
          }
          .items {
            .item {
              .label, .value {
                width: 100%;
                display: block;
                float: none;
                text-align: left;
                font-size: 1.75em;
                font-weight: 900;
              }
              .value {
                margin-top: .5em;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    &.teamHeader .teamnav ul li span{
      text-align: center;
    }
  }
}

//SLIDER-BACKGROUND IMG==========//
@media (max-width: 991px) {
  section.team.teamHeader > img {
    width: 158%;
  }
}

@media (max-width: 768px) {
  section.team.teamHeader > img {
    width: 154%;
  }
}
@media (max-width: 767px) {
  section.team.teamHeader > img {
    width: 146%;
  }
}
@media (max-width: 519px) {
  section.team.teamHeader > img {
    width: 184%;
  }
}
@media (max-width: 479px) {
  section.team.teamHeader > img {
    width: 184%;
  }
}

@media (max-width: 420px) {
  section.team.teamHeader > img {
    width: 333%;
  }
}

@media (max-width: 330px) {
  section.team.teamHeader > img {
    width: 320%;
  }
}