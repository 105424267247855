//==========Staff==========//
.staffitem {
  padding-bottom: 6em;
  .name {
    font-weight: 600;
    color: $blue-ci;
    span {
      font-size:1.85em;
      padding-bottom: .25em;
      display: block;
    }
  }
  .position {
    span {
      display: block;
      font-size: 1.4em;
      line-height: 1.25;
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {
  .staffitem {
    .name, .position{
      color: #fff;
    }
    .row {
      .col-sm-12 {
        &:nth-child(2) {
          padding-left: 15px;
          padding-top: 15px;
          padding-right: 15px;
          margin-left: -25px;
          background: $blue-ci-bright;
        }
      }
    }
  }
}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  .staffitem {
    width: 50%;
    float: left;
    .name{
       margin-top: 1.5em;
       span{
       }
     }
    .position{
      margin-top: .5em;
      span{
      }
    }
  }
}

@media (max-width: 480px) {
  .staffitem {
    width: 100%;
    float: none;
  }
}

@media (max-width: 375px) {

}