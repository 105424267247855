//==========Matchday Hero==========//

.matchdayHero {
    padding: 0;
    color: #fff;
    text-align: center;

    .overlay {
        position: absolute;
        z-index: 2;
        width: 100%;
        max-height: 100%;
        left: 50%;
        top: 50%;
        transform: translate3D(-50%, -50%, 0);

        .teamName {
            font-size: 1.5em;
            line-height: 1.25;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 100%;
            margin-top: 0.8em;
            left: 0;
            font-weight: 900;
            text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        }

        .matchday {
            display: inline-block;
            margin: 0 0 0.1em 0;
            position: relative;
            text-shadow: 2px 2px 0.8em rgba(0, 0, 0, 0.6);
            .prevNextMatch {
                display: flex;
                align-items: center;
                .prev, .next{
                    color: #fff;
                    font-size: 3.5em;
                    padding: 0.5em .75em;
                    &.inactive{
                        opacity: 0;
                    }
                    .ksc-icon{
                        transition: none;
                    }
                    &:hover, &:active{
                        color: $yellow;
                    }
                }
                .next{
                    transform: rotate(180deg);
                }
            }


            span {
                font-weight: normal;
                text-transform: uppercase;
                font-size: 7em;
                font-family: American_Captain_01, Arial, Helvetica, sans-serif;
            }
        }

        time {
            display: block;
            font-size: 2.25em;
            font-weight: 600;
            margin-top: 0.25em;
            text-shadow: 2px 2px 0.8em rgba(0, 0, 0, 0.6);

            .divider {
                padding: 0 0.5em;
            }
        }

        .match {
            text-align: center;
            margin-top: 2.5em;

            .team {
                display: inline-block;
                vertical-align: middle;
                position: relative;

                .logo {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0;
                    width: 16.5em;
                    overflow: hidden;
                    text-align: center;

                    img {
                        height: auto;
                        max-width: 100%;
                        width: auto;
                        max-height: 16.5em;
                        display: inline-block;
                    }
                }

                &.home {
                    margin-right: 8em;

                    .logo {
                        //text-align: right;
                    }
                }

                &.away {
                    margin-left: 8em;

                    .logo {
                        //text-align: left;
                    }
                }
            }

            .result {
                display: inline-block;
                vertical-align: middle;

                > span {
                    font-size: 14.5em;
                    font-weight: 900;
                    text-shadow: 0.03em 0.03em 0.2em rgba(0, 0, 0, 0.2)
                }
            }
        }

        .halfTimeResult {
            display: inline-block;
            vertical-align: top;
            font-size: 5em;
            font-weight: 400;
            text-align: center;
            transform: translateY(-0.2em);
            margin-bottom: 0.75em;
        }
    }

    .selectMatchday {
        position: absolute;
        top: 0.8em;
    }

    .info {
        margin: 0 0 0 0;
        font-size: 1.5em;
        font-weight: 700;
        position: absolute;
        width: 100%;
        bottom: 1.5em;
        left: 0;
        text-align: center;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);

        div {
            line-height: 1.45em;
            display: inline-block;
            position: relative;
            margin: 0 .5em;
        }

        span {
            display: inline-block;
        }
    }
}
.tickets.matchday{
    display: block;
    text-align: center;
    .btn{
        max-width: calc(100% - 60px);
        width: auto;
        min-width: 0;
        transform: none;
        left: 0;
        display: inline-flex;
        text-align: left;
        padding: 0 1.75em 0 2em;
        align-items: center;
        justify-content: space-between;
    }
    span.ksc-icon{
            font-weight: 100;
            margin-left: 0.3em;
            font-size: 3.3em;
            line-height: 1;
    }
}
h1.no-entries{
    margin: 0;
    color:$grey-superlight;
}
//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
    .matchdayHero {
        .overlay {
            font-size: 1.25vW;
            transform: translate3D(-50%, -57%, 0);

            .teamName {
                font-size: 14px;
            }
        }

        .info {
            position: static;
            bottom: 0;
            background: #fff;
            font-size: 1.5em;
            font-weight: 700;
            padding: 0.6em;
            color: #8c8d8f;
            text-shadow: none;
        }
        .overlay .matchday .prevNextMatch .prev,  .overlay .matchday .prevNextMatch .next {
            font-size: 5em;
        }
    }
}

@media (max-width: 480px) {
    .matchdayHero {
        .selectMatchday {
            right: -38px;
            top: -1px;
            z-index: 2;
            width: 30px;
            height: 30px;
            line-height: 28px;
            border-radius: 5px;

            &:before {
                font-size: 10px;
            }

            .layer {
                font-size: 13px;
                //left: 0;
                //right: auto;
                //transform: translateX(calc(-100% + 1.25em ));
            }
        }

        .overlay {
            transform: translate3D(-50%, -44%, 0);

            time {
                font-size: 3em
            }

            .teamName {
                font-size: 12px;
            }

            .match {
                margin-top: 3.5em;
            }

            .match .team {
                &.home {
                    margin-right: 4em;
                }

                &.away {
                    margin-left: 4em;
                }
            }
        }

        .info {
            font-size: 13px;
            display: none;
            //background: $grey-superlight;
            div {
                //display: none;
                &:nth-child(3) {
                    display: inline-block;
                }
            }
        }
    }
}

@media (max-width: 413px) {
    .matchdayHero {
        picture {
            img {
                opacity: .67;
            }
        }

        background: #000;

        .overlay {
            time {
                font-size: 12px;
            }

            .teamName {
                display: none;
            }
        }

        .info {
        }
    }
}

.selectMatchday.open .layer {
    font-size: 13px;
    left: auto;
    right: -3px;
}