//==========ARTICLE (NewsDetail & Spielbericht==========//
section.article {
  .news-img-wrap.slick-slider {
    ul.slick-dots {
      position: relative;
      bottom: 0;
      margin-top: 2em;
      li {
        &.slick-active{
          background: $yellow;
        }
      }
    }
  }

  .roofLine {
    font-size: 1.6em;
    font-weight: 900;
    text-transform: uppercase;
    color: $grey-ci-dark;
    margin-bottom: .5em;
  }
  main {
    h1 {
      font-size: 4.2em;
      font-weight: normal;
      line-height: 1.1;
      color: $blue-ci;
      text-transform: uppercase;
      font-style: normal;
      margin: 0;
      text-align: left;
    }
    h2 {
      font-size: 2.4em;
      font-weight: 700;
      line-height: 1.35;
      color: $grey-ci-dark;
      text-transform: none;
    }
    h3 {
      font-size: 2.8em;
      font-weight: 500;
      line-height: 1.25;
      color: $blue-ci;
      text-transform: none;
    }
    p {
      font-size: 2.1em;
    }
    .info {
      border-top: 1px solid $grey-superlight;
      border-bottom: 1px solid $grey-superlight;
      padding: 1.5em 0;
      margin: 2.5em 0;
      span, time {
        font-size: 1.5em;
        font-weight: 900;
        color: $grey-ci-bright;
        text-transform: uppercase;
      }
      time {
        text-transform: none;
      }
      .news-list-category {
        &:before {
          content: "|";
          display: inline-block;
          margin: 0 0.7em 0 .5em;
        }
      }
      .author {
        float: right
      }
    }
    figure, .news-img-wrap.slick-slider {
      margin: 3em 0;
    }
    .news-img-wrap.slick-slider{
      figure{margin: 0;}
    }
  }
  .twitter-tweet {
    width: 100% !important;
    margin-bottom: 4em !important;
  }
  .video {
    margin-bottom: 2em;
    font-size: 2.1em;
  }
  aside {
    .roofLine {
      margin-bottom: 1em;
    }
    .newsTeaser {
      .item {
        font-size: 10px;
        background: $grey-ultralight;
        box-shadow: none !important;
        margin-bottom: 30px;
      }
    }
  }
  audio {
    width: 100%;
    margin-bottom:40px;
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {
  .article {
    aside {
      float: right !important;
      .item-wrap {
        padding: 0 !important;
      }
    }
  }
}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
  section.article {
    aside {
      padding-top: 6em;
      .newsTeaser {
        .col-md-24.col-sm-12.col-xs-24 {
          &:nth-child(n+3) {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  section.article {
    font-size: 8px;
    aside {
      .newsTeaser {
        .col-xs-24 {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  section.article {
    aside {
      .newsTeaser {
        .col-xs-24 {
          width: 100%;
        }
      }
    }
  }
}


@media (max-width: 479px) {
  section.article {
    font-size: 7.5px;
    main {
      .info {
        .author {
          float: none;
          margin-top: .5em;
          display: block;
          font-weight: 500;
          text-transform: none;
        }
      }
    }
  }
}

