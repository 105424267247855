//==========TeaserItems==========//
.teaserItems {
    margin: 0;
    .item {
        display: block;
        margin: 0 0 30px 0;
        .item {
            margin: 0;
        }
        img {
            transition: $t-slow;
        }
        .content {
            background: $grey-ultralight;
            display: table;
            width: 100%;
            height: 6.8em;
            min-height: 6.8em;
            padding: 0 15px;
            border-top: 5px solid $yellow;
            position: relative;
            &:after {
                font-family: ksc-icons;
                font-weight: normal;
                content: $ksc-icon-arrow-round;
                color: $grey-ci;
                font-size: 2.5em;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%) rotate(180deg);
                transition: $t-fast;
            }
            .inner {
                display: table-cell;
                vertical-align: middle;
                span {
                    width: 80%;
                    display: block;
                    font-weight: 300;
                    text-transform: uppercase;
                    font-size: 2em;
                    color: $grey-ci-dark;
                }
            }
        }
        &:hover, &:focus {
            img {
                transform: scale(1.25) rotate(-3deg);
            }
            .content {
                &:after {
                    color: $yellow;
                }
            }
        }

    }
}

.layernav {
    .teaserItems {
        margin: 0;
        .item .content {
            background: #fff;
        }
    }
}

main .teaserItems {
    &.horizontal {
        .item {
            width: 100%;
        }
        padding: 6em 0;
    }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 600px) {
    main .teaserItems {
        &.horizontal {
            .item {
                width: 50%;
            }
        }
    }
}
@media (min-width: 768px) {
    main .teaserItems {
        &.horizontal {
            .item {
                width: 50%;
            }
        }
    }
}

@media (min-width: 992px) {
    aside .teaserItems .col-md-12.item {
        width:100%;
    }
     .aside {
        .teaserItems {
            .item {
                width: 50%;
            }
        }
    }
    main .teaserItems {
        &.horizontal {
            .item {
                width: 33.33333%;
            }
        }
    }
}


@media (min-width: 1280px) {
    main .teaserItems {
        &.horizontal {
            .item {
                width: 33.33333%;
            }
        }
    }
}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}