.herographics {
    position: relative;
    &.top {
        background: url(../Img/ksc360/DREI60_Header_rechtes_Element_60x270px.svg) no-repeat center right;
        height: 4rem;
        @media (max-width:1183px) {
            height: 3rem;
            height: 2rem;
            background-position: 85vw 0%;
        }
        @media (max-width:479px) {
            height: 2rem;
            background-position: 80vw 0%;
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 90px;
            height: 88px;
            background: url(../Img/ksc360/DREI60_Header_linkes_Element_oben_180x175px.svg);
            background-size: cover;
            content: "";
            z-index: 5;

            @media (max-width:1183px) {
                width: 60px;
                height: 59px;
            }
            @media (max-width:479px) {
                width: 45px;
                height: 44px;
            }
        }
        &:after {
            position: absolute;
            top: 2rem;
            left: 2rem;
            width: 153px;
            height: 153px;
            background: url(../Img/ksc360/DREI60_Header_mehr_dabei_204x204px.svg);
            background-size: cover;
            content: "";
            z-index: 5;

            @media (max-width:1183px) {
                width: 120px;
                height: 120px;
                top: 1.4rem;
                left: 1.4rem;
            }
            @media (max-width:479px) {
                width: 75px;
                height: 75px;
                top: 1rem;
                left: 1rem;
            }

        }
    }
    &.bottom {
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 4rem;
            height: 32rem;
            background: url(../Img/ksc360/DREI60_Header_linkes_Element_60x350px.svg) no-repeat !important;
            content: "";
            z-index: 5;
            background-position: bottom !important;
            @media (max-width:1183px) {
                width: 3rem;
                height: 20rem;
            }
            @media (max-width:479px) {
                width: 2rem;
                height: 15rem;
            }

        }
    }
}

#heroslider.swiper-container {

    .herographics {
        position: relative;
        &.top {}
        &.bottom {
            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 4rem;
                height: 200px;
                background: url(../Img/ksc360/DREI60_Header_linkes_Element_60x350px.svg) no-repeat !important;
                content: "";

            }
        }
    }

    padding: 6rem 0;
    border-left: 4rem solid #fff;
    background: url(../Img/ksc360/DREI60_Header_Background_1860x700px.svg) no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    @media (max-width:1183px) {
        border-left: 3rem solid #fff;
    }
    @media (max-width: 767px) {
        padding: 6rem 0 4rem;
    }
    @media (max-width: 479px) {
        padding: 45px 0 35px;
        border-left: 2rem solid #fff;
    }
    @include gradient_ci2019_straight;
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {

    }
    .heronews.swiper-slide {
        width: 33.33334%;
        @media (max-width: 1350px) {
            width: 50%;
        }
        @media (max-width: 1200px) {
            width: 66.66667%;
        }
        @media (max-width: 1024px) {
            width: 75vw;
        }
        @media (max-width: 479px) {
            width: calc(100% - 60px);
        }
        pointer-events: none;
        @media (min-width: 0px) {
            &:after {
                position: absolute;
                content: "";
                width: 100%;
                padding-top: 9.17%;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 69%) scale(1.28);
                opacity: 0.7;
                @media (max-width: 767px) {
                    opacity: 0.4;
                }
            }
        }

        a {
            .newsteaser {
                max-width: 100%;
                width: 100% !important;
                bottom: 10%;
                .topline {
                    display: none;
                    font-size: 1.8em;
                }
                .mainline {
                    p {
                        font-size: 3.4em;
                        @media (max-width: 1350px) {
                            font-size: 5em;
                        }
                        @media (max-width: 1200px) {
                        }
                        @media (max-width: 1024px) {
                            font-size: 4em;
                        }
                    }
                }
                @media (max-width: 1024px) {
                    width: 75%;
                }
            }
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                .newsteaser {
                    transform: none;
                    background-color: #fff;
                    position: static;
                    order: 3;
                    > * {
                        flex: 1 0 100%;
                    }
                    .mainline {
                        transform: none;
                        p {
                            line-height: 1.1;
                            padding: 10px 12px;
                            font-size: 24px;
                            display: block;
                        }
                    }
                }
            }
        }
        &.swiper-slide-active {
            pointer-events: all;
        }
    }
    > .swiper-pagination-bullets {
        bottom: 2em;
        @media (max-width: 1024px) {
            display: none;
        }
        .swiper-pagination-bullet {
            width: 1em;
            height: 1em;
            margin: 0 0.3em;
            transition: all .3s;
            &.swiper-pagination-bullet-active {
                background: $blue-vivid2019;
            }
        }
    }
}
