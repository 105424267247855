//==========Social Quicklinks==========//
section.socialQuicklinks{
  padding-top: 3em;
  text-align: center;
  span{
    display: block;
    padding-top: 3em;
  }
  .ksc-icon{
    display: inline-block;
    color: $blue-ci;
    font-size: 5em;
    margin: 0 0.1em;

    &:hover, &:focus{
      color: $yellow;
    }
  }
}



//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 360px){
  section.socialQuicklinks .ksc-icon {
    font-size: 4.2em;
  }
}