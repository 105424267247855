// ctype SCSS
.ce-count2 {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.2em;

  h2 {
    font-family: "American_Captain_01", Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-align: center;
    font-size: 3.0em;
    margin-bottom: 0.2em;
    color: $grey-ci-dark;
    text-transform: none;
  }

  .count {
    margin: 1em 2em;
    font-weight: normal;
    text-align: center;
    color: $blue-ci;
    display: inline-block;
    position: relative;

    .number {
      text-align: center;
      font-family: "American_Captain_01", Arial, Helvetica, sans-serif;
      font-size: 7em;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 2;
    }

    .hollow {
      color: white;
      position: relative;
      font-family: "American_Captain_01", Arial, Helvetica, sans-serif;
      font-size: 7em;
      visibility:hidden;
    }

    .unit {
      font-size: 2.8em;
      font-family: "American_Captain_01", Arial, Helvetica, sans-serif;
      padding-left:5px;
    }

    .description {
      display: block;
      font-size: 1.3em;
      text-transform: uppercase;
      color: $grey-ci;
    }
  }
}