//==========SUBNAV==========//
.layernav {
  text-align: left;
  position: absolute;
  z-index: 90;
  left: 0;
  top: 100%;
  width: 100%;
  transform: translateY(-150%);
  transition: $t-slow;
  padding-top: 0;
  @include gradient-darkblue;
  color: #fff;
  h1, .h1, h2, .h2, p, a, ul, li, a, div, span {
    color: #fff;
  }
  h1, .h1 {
    //@include textshadow_darkblue;
    font-size: 5em;
    margin: 0 0 1.25em;
    font-weight: normal;
    font-family: American_Captain_01, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
  .main {
    float: right;
    padding: 0 60px 0 30px;
    [class*="col-"] {
      float: none;
      display: inline-block;
      vertical-align: top;
      margin-right: -2px;
    }
  }
  .aside {
      .teaserItems {
          .container{
              padding: 0;
              width: 100%;
          }
      }
  }
  ul {
    display: block;
    text-align: left;
    margin: 0;
    padding: 0;
    vertical-align: top;
    margin-bottom: 60px;
    font-size: 12px;
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      &.header {
        font-weight: 900;
        border-bottom: 0.135em solid #fff;
        text-transform: uppercase;
        font-size: 1.6em;
        margin-bottom: 0.75em;
        padding-bottom: 1em;
        &.active{
          color: $yellow;
          //border-color: $yellow;
        }
      }
      &:not(.header){
        a {
          display: block;
          font-weight: 400;
          font-size: 1.5em;
          margin-bottom: 0.85em;
          line-height: 1.35;
          &:hover, &:focus, &.active {
            color: $yellow;
          }
          &.active{
            font-weight: 700;
          }
        }
    }
      &.inline {
        display: inline-block;
        margin-right: 10px;
      }

    }
    &.jugendmannschaften {
      li {
        display: inline-block;
        width: 26%;
        margin-right: 1em;
        &:first-child, &:last-child, &:nth-child(2) {
          display: block;
          width: auto;
          margin-right: 0;
        }
      }
    }
  }
  .teaser {
    text-align: center;
    margin-top: 2em;
    img {
      display: inline-block;
      width: 75%;
    }
  }
  .layernav_content {
    transform: translateY(-100%);
    transform-origin: center top;
    transition: $t-medium;
    transition-delay: 0s;
    opacity: 1;
  }
}

.layerslideitem {
}

.layernav-open {
  .layernav {
    transform: translateY(0);
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition-delay: .4s;
  }
  .layernav_content {
    transform: translateY(0) scale(1);
    opacity: 1;
    height: 100%;
  }
}

body {
  &.mobilenav-open, &.layernav-open {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
    .mainnav {
      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    .layernav {
      overflow: hidden;
    }
  }
}
.layernav {
  height: calc(100vH - 14em) !important;
  .slick-slider {
    .slick-slide{
      height: calc(100vH - 14em - .4vW);
      padding-top: 6em;
      padding-bottom: 50px;
      overflow: hidden;
      overflow-y: auto;
    }
    .slick-track {
    }
    .slick-list {
    }
  }
}
$controlHeight: 6em;
.slick-control-layernav {
  display: none !important;
  position: fixed;
  z-index: 130;
  left: 50%;
  bottom: 0;
  transform: translate3D(-50%, 100%, 0);
  width: 100%;
  max-width: 1920px;
  text-align: center;
  background: #fff;
  transition: $t-medium;
  .layernav-open & {
    transform: translate3D(-50%, 0, 0);
    transition-delay: 1s;
  }
  .slick-arrow {
    display: inline-block !important;
    height: $controlHeight;
    background: $yellow;
    transform: none;
    top: 0;
    cursor: pointer;
    text-align: center;
    &.slick-left {
      left: 0;
    }
    &.slick-right {
      right: 0;
    }
    .ksc-icon {
      line-height: calc(#{$controlHeight} + 0.2em);
      color: #fff;
      width: $controlHeight;
      &:before {
        font-size: 2.5em;
      }
    }
    &:hover, &:focus{
      .ksc-icon{
        transform: scale(1.35);
      }
    }
  }
  .dots {
    display: inline-block;
    height: $controlHeight;
    width: calc(100% - (#{$controlHeight} * 2));
    .slick-dots {
      font-size: 150%;
      position: relative;
      top:50%;
      bottom: auto;
      transform:translateY(-50%);
      margin:0;
      li{
        margin: 0 0.75em;
        width: 1em;
        height: 1em;
        &:hover{
          transform-scale: 1.3;
        }
      }
    }
  }
}
//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1201px) {
  .layernav {
    .aside {
      .teaserItems {
        .item {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {
  .layernav {
  }
}

@media (max-width: 1279px) {
  .layernav .aside .teaserItems {
    margin: 0;
  }
}

@media (max-width: 1200px) {
  .layernav {
    top: 0;
    z-index: 120;
    min-height: 100vH !important;
    .slick-slider {
      .slick-slide {
        height: 100vH;
      }
    }
    .main, .aside {
      float: none;
      width: 100%;
      padding: 0 60px;
      > .row {
        .col-md-12 {
          width: 33.33333%;
        }
      }
    }
    .aside {
      position: static !important;
      width: 100%;
      height: auto;
      min-height: 0 !important;
      margin-top: 0;
      background: none;
      .teaserItems {
        margin: 0 0 60px 0;
        > .row {
          .col-md-12 {
            width: 33.33333%;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .layernav {
    .main {
      > .row {
        .col-md-12 {
          width: 50%;
        }
      }
    }
    .aside {
      .teaserItems {
        > .row {
          .col-md-12 {
            width: 50%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .layernav {
    .close{
      top: 2em;
      right: 2em;
    }
    ul{
      font-size: 11.4px;
    }
    .main, .aside {
      padding: 0 30px;
    }
    .aside{
      .teaserItems {
        margin: 0 0 60px;
      }
    }
  }
}
@media (max-width: 600px) {
  .layernav {
    ul{
      font-size: 2.5vW;
    }
    .main {
      > .row {
        .col-md-12 {
          width: 100%;
        }
      }
    }
    .aside {
      .teaserItems {
        > .row {
          .col-md-12 {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .layernav {
    ul{font-size: 3vW}
    .main {
      padding: 0 30px;
    }
    .aside {
      .teaserItems {
        margin: 0;
      }
    }
  }
  .slick-control-layernav {
    text-align: left;
    .slick-arrow {
      &.slick-left {
        left: auto;
        right: calc(#{$controlHeight} + 2px);
      }
      &.slick-right {
      }
      .ksc-icon {
        line-height: calc(#{$controlHeight} + 0.2em);
        color: #fff;
        width: $controlHeight;
        &:before {
          font-size: 2.5em;
        }
      }
    }
    .dots {
      display: inline-block;
      height: $controlHeight;
      max-width: calc(100% - (#{$controlHeight} * 2));
      .slick-dots {
        font-size: 150%;
        position: relative;
        top:50%;
        bottom: auto;
        transform:translateY(-50%);
        margin:0;
        li{
          margin: 0 0.75em;
          width: 1em;
          height: 1em;
          &:hover{
            transform-scale: 1.3;
          }
        }
      }
    }
  }
}
@media (max-width: 420px) {
  .slick-control-layernav {
    background: none;
    .dots {
      visibility: hidden;
    }
  }
}
@media (max-width: 340px) {
  .layernav{
    ul{
      font-size: 12px;
    }
    h1, .h1{
      font-size: 4.4em;
      margin:0 0 1.25em;
    }
    ul{

    }
  }
}