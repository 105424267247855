.bwinAd {
  a {
    display:inline-block;
    img {
      width: auto;
      max-width: none;
    }
  }
  &.container {
    padding:3em 0 6em 0;
    &.space {
      padding:6em 0;
    }
    &.spacetop {
      padding:6em 0 0 0;
    }
  }
}
