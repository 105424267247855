//==========Appear==========//

@media (min-width: 375px) {
  section:not(.fixedBgImage), footer .ksc-logo, .sponsoren .sponsor, .hauptsponsor a, .textElement
  {
    transform: translateY(10em);
    transform-origin: center bottom;
    opacity: 0;
    transition: all 0.8s;
    &.appeared {
      transform: translateY(0);
      opacity: 1;
    }
  }

  section {
    &.imageHeader, &.jumpNav {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .msIE {
    section:not(.fixedBgImage), footer .ksc-logo, .sponsoren .sponsor, .hauptsponsor a, .textElement
    {
      transform: none;
      opacity: 1;
    }
  }
}
