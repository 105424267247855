//==========standings & schedule==========//

.standings-schedule {
    div.h2.schedule {
        font-weight: 900;
        padding-bottom: 1em;
    }
    .tablestyle.standings {
        &.fullSchedule{
            margin-bottom: 2.5em;
        }
        tr {
            td, th {
                height: 5.5em;
                padding: 0 1em;
            }
        }
        thead {
            tr {
                th {
                    width: calc(100% / 7);
                    width: 5.5em;

                    &.team {
                        width: calc((100% / 7) * 3);
                        width: auto;
                    }
                    &.result {
                        width: calc((100% / 7) * 1.5);
                        width: 5.5em;
                    }
                    &.date {
                        width: 11em;
                    }
                    span {
                        display: block;
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    &.rank, &.day {
                        span {
                            font-weight: 300;
                            font-size: 2.5em;
                        }
                    }
                    &.team {
                        text-align: left;
                        figure.logo {
                            height: 3em;
                            padding: 1px;
                            margin: 0 0.75em 0 0;
                            width: 3em;
                            overflow: hidden;
                            display: inline-block;
                            vertical-align: middle;
                            text-align: center;
                            img {
                                display: inline-block;
                                position: relative;
                                height: auto;
                                max-width: 3em;
                                padding: 1px;
                                width: auto;
                                max-height: 3em;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        .teamname {
                            display: inline-block;
                            vertical-align: middle;
                            width: calc(100% - 6em);
                            font-weight: 700;
                            text-align: left;
                        }
                        &.match {
                            text-align: center;
                            > a {
                                display: block;
                                > span{
                                    display: flex;
                                    flex: 1;
                                    text-align: center;
                                    justify-content: center;
                                    .vs{
                                        align-items: center;
                                        display: flex;
                                    }
                                    .team{
                                        display: flex;
                                        flex: 1 1 50%;
                                        align-items: center;
                                        &:first-child{
                                            span{
                                                order: -1;
                                                margin-left: auto;
                                            }
                                        }
                                        .teamname{
                                            width: auto;
                                        }
                                    }
                                }
                            }
                            figure.logo {
                                margin: 0 0.5em;
                                //max-width: calc((50% - 0.8em) - 2px);
                                height: auto;
                                max-width: 3em;
                                width: auto;
                                max-height: 5.5em;
                                img {
                                    top: 0;
                                    transform: none;
                                }
                            }
                            .vs {
                                display: inline-block;
                            }
                        }
                    }
                    &.points {
                        span {
                        }
                    }
                    &.result {
                        span {
                            font-size: 2em;
                            &.tickets {
                                display: block;
                                a {
                                    color: $blue-ci;
                                    font-size: 0.5em;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    p.asterisk {
        padding-top: 2em;
        text-align: center !important;
    }
    .ksc-schedule {
        p {
            margin-top: 2em;
            margin-bottom: 1em;
            text-align: center;
        }
        .btn {
            margin-top: 0;
        }
        &.top {
            p {
                margin-top: 0;
                margin-bottom: 1em;
                text-align: center;
            }
            .btn {
                margin-bottom: 6em;
            }
        }
    }
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {
    .standings-schedule{
        table.standings.tablestyle{
            font-size: 1.5em;

        }
    }
}
@media (min-width: 992px) and (max-width: 1279px) {
    .standings-schedule{
        table.standings.tablestyle{
            &.fullView{
                .teamname{
                    display: none !important;
                }
                tbody tr td.team{
                    text-align: center;
                    figure.logo{
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media (min-width: 1650px) {
    .standings-schedule{
        table.standings.tablestyle{
            font-size: 1.25em;
        }
    }
}
@media (min-width: 768px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
    .standings-schedule {
        .container {
            .col-md-12.col-sm-24:first-child {
                > .btn.blue {
                    margin-bottom: 6em;
                }
            }
            .col-md-12.col-sm-24:nth-child(3) {
                > h2, .h2 {
                    padding-top: 2em;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .standings-schedule {
        .tablestyle {
            &.standings {
                tbody {
                    tr td {
                        height: 5.5em;
                        padding: 0 5px;
                        &.team {
                            text-align: center;
                            width: auto;
                            figure {
                                &.logo {
                                    width: 4em;
                                    height: 4em;
                                    margin: 0;
                                }
                            }
                            &.match {
                                > a > span .team{
                                    flex-direction: column;
                                    &:first-child span{
                                        margin: 0;
                                        order: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 600px){
    .standings-schedule .tablestyle.standings tbody tr td.team.match>a>span .team .teamname {
        display: none;
    }
}
@media (max-width: 479px) {
    .standings.tablestyle {
        .difference {
            display: none;
        }
    }
    .tableScroll {
        .standings.tablestyle {
            .difference {
                display: table-cell;
            }
        }
    }
}

@media (max-width: 420px) {
    .standings-schedule .tablestyle.standings thead tr th {
        padding: 0 .5em;
    }
    .standings.tablestyle {
        .goals {
            display: none;
        }
    }
    .tableScroll {
        .standings.tablestyle {
            .goals {
                display: table-cell;
            }
        }
    }
}

@media (max-width: 413px) {
    .standings-schedule {
        .tablestyle {
            tr td, th td {
                padding: 0 0.75em;
                &.team.match {
                    padding: 0;
                }
            }
            &.standings {
                thead {
                    tr {
                        th {
                            span {
                                display: none;
                                &:last-child {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                tbody tr td {
                    &.rank, &.points, &.day, &.result {
                        span {
                            font-size: 1.5em;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 350px) {
    .standings.tablestyle {
        .matches {
            display: none;
        }
    }
    .tableScroll {
        .standings.tablestyle {
            .matches {
                display: table-cell;
            }
        }
    }
}

@media (max-width: 330px) {
    .standings-schedule {
        .tablestyle {
            &.standings {
                thead {
                    tr {
                        th {
                            font-size: 11px;
                        }
                    }
                }
                tbody tr td {
                    &.rank, &.points, &.day, &.result {
                        span {
                            font-size: 12px;
                        }
                    }
                    &.date {
                        span {
                            font-size: 11px;
                        }

                    }
                }
            }
        }
    }
}