.popup {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(1, 1, 1, 0.3);

  &__container {
    width: 90%;
    max-width: 65vh;
    // @media (min-width: 768px) {
    //   width: 50%;
    // }
    @media (min-width: 1480px) {
      width: 35%;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgb(0, 76, 148);
    transform: translate(-50%, -50%);

    &__header {
      display: flex;
      justify-content: end;

      &__button {
        background-color: rgb(0, 76, 148);
        color: white;
        padding: 10px 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        border: none;
      }
    }

    &__image {
      margin: auto;

    }
  }
}