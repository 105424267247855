form {
  .form-group {
    margin-bottom: 5px;
  }
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    font-weight: normal;
    font-size: 18px;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    opacity: 1;
    font-weight: normal;
    font-size: 18px;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    opacity: 1;
    font-weight: normal;
    font-size: 18px;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: normal;
    font-size: 18px;
  }
  .radio, .checkbox {
    label {
    }
  }
  label {
    font-weight: normal;
  }
  .form-group {

  }
  input {
    &[type="radio"], &[type="checkbox"] {
      margin-top: 0;
    }
    &[type="text"], &[type="password"], &[type="email"] {
      line-height: 1;
      display: block;
      width: 100%;
      padding: 10px 15px;
      background: #fff;
      border: 1px solid #ccc;

      &:focus {
        box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.15);
        background: #fff;
      }
    }
  }
  button, input {
    &[type="submit"] {

    }
  }
  textarea {
    &.form-control {
      &:focus {
        box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.15);
        background: #fff;
      }
    }
  }
}