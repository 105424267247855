//==========BUTTONS==========//

.btn {
  min-width: 33em;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 6em auto 0;
  left: 50%;
  transform: translateX(-50%);
  &.noMarginTop {
    margin-top: 0;
  }
  &.block {
    display: block;
  }
  span {
    transition: $t-medium;
    transition-delay: 0;
    position: relative;
    z-index: 3;
    font-size: 2.4em;
    line-height: 2.25em;
    font-weight: 900;
  }
  &:before, &:after {
    content: "";
    display: block;
    width: calc(100% + 1em);
    height: 100%;
    border-radius: 3em;
    background: $blue-ci;
    top: 0;
    left: -0.5em;
    position: absolute;
    z-index: 1;
    transition: $t-medium;
    transition-delay: .05s;
  }
  &:before {
    @include boxshadow_grey;
  }
  &:after {
    transition: $t-medium;
    transition-delay: 0s;
    z-index: 2;
    background: $yellow;
    opacity: 0;
  }

  &.blue {
    span {
      color: #fff;
    }
    &:before {
      @include gradient_btn-blue;
    }
  }
  &.white {
    span {
      color: $font_darkgrey;
    }
    &:before {
      @include gradient_btn-white;
      box-shadow: none;
    }
  }
  &.small {
    min-width: 0;
    margin: 0;
    padding: 0.75em;
    border-radius: 5px;
    border: 2px solid $grey-ci-bright;
    color: $grey-ci-bright;
    background: #fff;
    left: auto;
    transform: none;
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
    &:before, &:after {
      display: none;
    }

    &:hover, &:focus {
      background: $grey-ci-bright;
      color: #fff;
    }
    &.grey {
      background: $grey-ci-bright;
      color: #fff;
      &:hover, &:focus {
        background: #fff;
        color: $grey-ci-bright;
      }
    }
  }
  &.inactive{
      pointer-events: none;
    &:before, &:after{
      background: #cecfd0 !important;
    }
    .ksc-icon{opacity: .5}
  }
  &:hover, &:focus {
    span {
      color: #fff !important;
    }
    &:before {
      @include boxshadow_grey_hover
    }
    &:after {
      opacity: 1;
    }
  }
}

.align-center {
  .btn {
    left: 0;
    transform: none;
  }
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {
  .btn {
    min-width: 0;
    width: 100%;
  }
}