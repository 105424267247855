//==========Ankernavi für Subpages==========//

section.jumpNav{
  padding: 0;
  background: $grey-ultralight;
  li{
    font-size: 1em;
    display: inline-block;
    position: relative;
    margin: 0 -2px 0 0;
    &:after{
      content: "|";
      padding: 0 .82em;
      display:inline-block;
      color: $font_darkgrey;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 1.5em;
    }
    &:last-child{
      &:after{
        display: none;
      }
    }
  }
  a{
    color: $font_darkgrey;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.5em;
    &:before, &:after{
      content: "";
      display: none;
    }
    &:hover, &:focus{
      color: $blue-ci;
    }
  }
  li:after, a{
    padding-top: 1em;
    padding-bottom: 1em;
  }
}



//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 375px){

}