//==========EVENT Tabelle==========//

section.eventTable {
  .table {
    font-size: 1em;
    display: block;
    //background: $blue-dark;
    color: #fff;
    text-align: left;
    .item {
      display: block;
      position: relative;
      background: $blue-ci;
      transition: $t-medium;
      background: $blue-ci;
      color: #fff;
      &:nth-child(even) {
        background: $blue-ci-bright;
      }
      > div {
        display: table;
        position: relative;
        float: left;
        padding: 1em 2em;
        min-height: 8.4em;
      }
      .icon {
        padding: 1em 0;
        width: 9em;
        text-align: center;
        .ksc-icon {
          display: table-cell;
          vertical-align: middle;
          font-size: 4.5em;
        }
      }
      .description {
        width: calc(75% - 9em);
        border-left: 1px solid transparentize(#fff, .5);
        .info {
          display: table-cell;
          vertical-align: middle;
          time {
            line-height: 1.25;
            font-size: 1.25em;
            font-weight: 900;
          }
          .type {
            line-height: 1.25;
            font-size: 2.4em;
            font-weight: 300;
          }
        }
      }
      .getTicket {
        position: absolute;
        text-align: center;
        right: 1.5em;
        top: 50%;
        transform: translateY(-50%);
        .ksc-icon {
          font-size: 3em;
          color: white;
        }
        span {
          transition: $t-medium;
          display: inline-block;
          font-size: 10px;
          margin-top: 5px;
          font-weight: 900;
          text-transform: uppercase;
          color:white;
        }
      }
      .location {
        float: right;
        width: 25%;
        border-left: 1px solid transparentize(#fff, .5);
        span {
          display: table-cell;
          vertical-align: middle;
          font-size: 1.25em;
          line-height: 1.25;
          font-weight: 900;
          text-transform: uppercase;
        }
      }
      &:hover, &:focus {
        background: $blue-dark;
        .icon {
          .ksc-icon {
            color: $yellow;
          }
        }
        .getTicket {
          span {
            color: $yellow;
          }
        }
        &:nth-child(even) {
          background: $blue-dark;
        }
      }
      &.game {
        .description .info {
          padding-right: 5em;
        }
      }
    }
  }
  .ksc-schedule {
    p {
      margin-top:2em;
      margin-bottom:1em;
      text-align:center;
    }
    .btn {
      margin-top:0;
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  section.eventTable {
    .table {
      .item {
        margin-bottom: 1em;
        .description {
          width: calc(100% - 9em);
        }
        .location {
          width: 100%;
          float: none;
          min-height: 0;
          border: none;
          border-top: 1px solid transparentize(#fff, .5);
          //border-bottom: 1px solid #fff;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  section.eventTable {
    .table {
      .item {
        .description {
          .info {
            .type {
              font-size: 2em;
              font-weight: 400;
            }
          }
        }
        .location {
        }
      }
    }
  }
}

@media (max-width: 413px) {
  section.eventTable {
    .table {
      .item {
        > div {
          position: static;
        }
        .icon {
          width: 100%;
          min-height: 0;
          text-align: left;
          padding: 1em 2em;
          .ksc-icon {
            font-size: 5.5em
          }
        }
        .description {
          width: 100%;
          border: none;
          padding-top: 0;
          min-height: 0;
          .info {
            padding-right: 0;
            .type {
              font-size: 2em;
              font-weight: 400;
            }
          }
        }
        .location {
        }
        .getTicket {
          transition: $t-medium;
          transform: none;
          top: 1.5em;
        }
        &:hover, &:focus {
        }
      }
    }
  }
}