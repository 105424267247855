//==========Breadcrumb==========//

.breadcrumb{
  padding: 4em 0 3.5em;
  ul{
    li{
      color: $grey-ci-light;
      font-weight: 400;
      font-size: 2.25em;
      display: inline-block;
      margin-right: -2px;
      a{
        color: $grey-ci-light;
        &:before, &:after{
          display: none;
          content: "";
        }
      }
      &:after{
        content:"//";
        padding:0 0.5em;
        display: inline-block;
      }
      &:last-child{
        &:after{
          content: "";
          display: none;
        }
      }
    }
  }
}

.breadcrumb360{
  padding: 4em 0 1em;
  ul{
    padding: 0;
    li{
      color: $grey-ci-light;
      font-weight: 400;
      font-size: 1.75em;
      display: inline-block;
      margin-right: -2px;
      margin-bottom: 0;
      a{
        color: $grey-ci-light;
        &:before, &:after{
          display: none;
          content: "";
        }
      }
      &:after{
        content:"//";
        padding:0 0.5em;
        display: inline-block;
      }
      &:last-child{
        &:after{
          content: "";
          display: none;
        }
      }
    }
  }
}



//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 375px){

}