#cookieNotice {
  @include gradient_ci-blue;
  position: fixed;
  width: 100%;
  min-height: 80px;
  padding: 20px 0;
  text-align: left;
  bottom: 0;
  left: 0;
  z-index: 5000;
  color: #fff;
  display: none;
  font-size: 1.3em;
  border-top: 1px solid #fff;
  a.dataprotect {
    color: $yellow;
  }
  a.btn.small {
    cursor: pointer;
  }
  span {
    line-height: 1.3em;
  }
}


@media (max-width: 991px){
 a.btn.small {margin-top:10px;}
}