.subnav{
    background: $blue-ci;
    height: auto;
    z-index: 9;
    ul{
        li{
            height: auto;
            &:after{
                content: none !important;
            }
            a{
                color: #fff;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 1;
                padding: .75em 0;
            }

            &:hover, &:focus, &.active {
                a {
                    color: $yellow;
                }
            }
        }
        &.leftnav{
            //transform: translateX(1em);
        }
        &.rightnav{
            //transform: translateX(-1em);
        }
    }
}
.subnav-mobile{
    display: none;
 }
@media all and (max-width: 1200px){
    .subnav{
        display: none;
    }
    .mainnav .subnav-mobile{
        flex: 1;
        display: block;
        background: $grey-superlight;
        padding: 30px 0;
        margin-top: 30px;
        padding-bottom: 160px;
       .sub{
           text-align: center;
           display: block;
            li{
                display: inline-block;
                line-height: 1;
                margin: 0;
                &:after{
                    content: none;
                }
                a{
                    font-size: 2.5em;
                    padding: .5em .25em;
                    &:after{

                    }
                }
            }
           &.leftnav{

           }
           &.rightnav{

           }
        }
    }
}
@media all and (max-width: 767px) {
    .mainnav .subnav-mobile{
        .sub{
            display: block;
            li{
                a{
                    font-size: 2.2em;
                    padding: .25em;
                }
            }
        }
    }
}
@media all and (max-width: 580px) {
    .mainnav .subnav-mobile{
        .sub{
            display: block;
            li{
                display: block;
                a{
                    padding: .15em 0;
                }
            }
        }
    }
}