//==========IMAGE HEADER (für Unterseiten)==========//

section.imageHeader{
  padding: 0;
  position: relative;
  overflow: hidden;
  &:after{
    content: "";
    display: block;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    background: transparentize($grey-ci-dark, .62);
  }
  &.no-bg:after {
    background:none;
  }
  h1, .h1{
    font-size: 10px;
    position: absolute;
    z-index: 10;
    width: 100%;
    padding: 0;
    margin: 0;
    top:50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    color: #fff;
    span{
      font-size: 5.2vW;
      font-family: American_Captain_01, Arial, Helvetica, sans-serif;
      font-weight: normal;
      text-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.45)
    }
  }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){
    section.imageHeader{
        h1, .h1{
            span{
                font-size: calc(12px + 4.2vW);
            }
        }
    }
}
@media (max-width: 480px){

}
@media (max-width: 375px){

}