//==========NEWSTEASER - SLIDER HOME==========//

.newsTeaser{
  padding-bottom: 0;
  h1 {
    img {
      width: 80%;
      height: auto;
      max-width: 400px;
      display: block;
      margin: 0 auto;
    }
  }
  .item-wrap{
    padding: 0;
    margin: 30px;
    margin-top:0;
  }
  .item{
    margin-bottom: 6em;
    display: block;
    background: #fff;
    color: $font_darkgrey;
    transition: $t-fast;
    @include boxshadow_grey;
    figure{
      position: relative;
      background: #ccc;
      width: 100%;
      padding-bottom: 87.8%;
      img{
        position: absolute;
        transition: $t-slow;
      }
    }
    .info{
      padding: 2.5em 2em;
      .headline{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 1.4em;
        line-height: 1.25;
        margin-bottom:1em;
        transition: $t-fast;
      }
      p{
        text-transform: none;
        font-weight: 300;
        font-size: 2em;
        line-height: 1.25;
        margin: 0;
      }
    }
    &:hover, &:focus{
      @include boxshadow_grey_hover;
      figure{
        img{
          @include imgHover;
        }
      }
      .info{
        .headline{
          color: $blue-ci;
        }
      }
    }
  }
}
aside{
  .newsTeaser{
    .item{
      .info{
        p {

        }
      }
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px){

}
@media (min-width: 768px){

}
@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 375px){

}