$bp-noGrid: 650px;
.ce-360promo{
  .container{
    position: relative;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  &.section-grey{
    .btn-360, .box-360{
      background: #fff !important;
    }
  }
  .grid {
    margin: 0 1fr;
    display: grid;
    grid-template-columns: repeat(18, 1fr) 1fr;
    grid-gap: 0;
    @media screen and (max-width: $bp-noGrid) {
      display: block;
    }
  }
  .item {
    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: none;
    }
  }
  .logo {
    grid-area: 1 / 1 / 3 / 4;
    position: relative;
    z-index: 10;
    img {
      transform: translateX(-50%);
    }
    @media screen and (max-width: 1023px) {
      grid-area: 1 / 1 / 3 / 5;
      img {
        transform: none;
      }
    }
    @media screen and (max-width: 768px) {
      grid-area: 1 / 1 / 3 / 6;
    }
    @media screen and (max-width: $bp-noGrid) {
      margin: 0 1.5rem 0 auto;
      max-width: 130px;
      img {
        transform: none;
      }
    }
    @media screen and (max-width: 1550px) and (min-width: 1024px) {
      grid-area: 1 / 2 / 3 / 6;
      img {
        transform: translateX(-50%) scale(0.75);
      }
    }
  }

  .foto {
    grid-area: 2 / 1 / 6 / 14;
    img {
      height: 100%;
      object-fit: cover;
      object-position: left top;
    }
    @media screen and (max-width: 1023px) {
      grid-area: 2 / 3 / 6 / 18;
    }
    @media screen and (max-width: 768px) {
      grid-area: 3 / 1 / 6 / 20;
    }
    @media screen and (max-width: $bp-noGrid) {
      height: 220px;
      margin-top: -65px;
    }
    @media screen and (max-width: 550px) {
      grid-area: 3 / 1 / 6 / 20;
    }
    @media screen and (max-width: 1550px) and (min-width: 1024px) {
      grid-area: 2 / 2 / 6 / 14;
    }
  }

  .claim {
    grid-area: 1 / 15 / 3 / 20;
    display: flex;
    align-items: flex-end;
    img {
     // transform: translateY(50%);
    }
    @media screen and (max-width: 1023px) {
      grid-area: 1 / 15 / 4 / 20;
      padding: 3rem 0 0 0;
      align-items: flex-start;
      img {
        transform: none;
      }
    }
    @media screen and (max-width: 768px) {
      grid-area: 1 / 11 / 3 / 19;
      padding: 0;
      align-items: center;
      img {
        transform: translateY(12%);
      }
    }
    @media screen and (max-width: $bp-noGrid) {
      display: none;
    }
    @media screen and (max-width: 1550px) and (min-width: 1024px) {
      align-items: center;
      img {
        transform: translateY(50%);
      }
    }
  }

  .content {
    grid-area: 4 / 8 / 7 / 19;
    @media screen and (max-width: 1023px) {
      grid-area: 5 / 7 / 9 /20;
    }
    @media screen and (max-width: 768px) {
      grid-area: 5 / 5 / 9 /20;
    }
    @media screen and (max-width: 550px) {
      grid-area: 5 / 3 / 9 /20;
    }
  }

  .box-360 {
    background-color: #efefef;
    border-radius: 1.5rem 1.5rem 0 1.5rem;
    padding: 3rem;
    ul {
      margin: 1.5rem 0;
      padding: 0;
      li {
        font-size: 2.2rem;
        line-height: 1.2;
        font-weight: 600;
        margin-bottom: 1em;
        list-style: none;
        position: relative;
        padding-left: 2.5em;
        @media screen and (max-width: 1280px) {
          font-size: 2.2rem;
        }
        @media screen and (max-width: 1023px) {
          font-size: 2rem;
          font-weight: 800;
        }
        &::before {
          content: "";
          width: 1.75em;
          height: 1.75em;
          background: url("../Img/ksc360/ksc360-bullet.svg") no-repeat center;
          background-size: contain;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    @media screen and (max-width: 768px) {
      border-radius: 1.5rem 0 0 1.5rem;
    }
    @media screen and (max-width: $bp-noGrid) {
      border-radius: 0;
      padding: 1.5rem;
    }
  }

  .price {
    font-family: $font2;
    text-align: right;
    margin: 3.75rem 0 1.5rem;
    font-weight: 100;
    text-transform: uppercase;
    line-height: 1;
    font-size: 4.2rem;

    @media screen and (max-width: 1280px) {
      font-size: 3.6rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 3.4rem;
      text-align: center;
    }
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $bp-noGrid) {
      margin: 1.5rem 0 0 0;
    }
    .login,
    .btn-360 {
      color: $blue-ci;
      flex: 1 0 50%;

    }
    .btn-360 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0 0 auto;
      padding: 3rem;
      min-width: 0;
      border-radius: 0 0 1.5rem 1.5rem;
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: 900;
      transition: all .3s;
      background: $grey-ultralight;
      span{
        color: currentColor;
        transition: all .3s;
      }
      .ksc-icon{
        color: $yellow;
        transition: all .3s;
      }
      &:hover, &.active{
        .ksc-icon{
          transform: translateX(1rem);
        }
      }
      @media screen and (max-width: 768px) {
        border-radius: 0 0 0rem 1.5rem;
        flex: 1 0 auto;
      }
      @media screen and (max-width: $bp-noGrid) {
        flex: 1;
        order: -1;
        border-radius: 3rem;
        border: none;
        margin: 0 1.5rem 1.5rem 1.5rem;
        padding: 1.75rem 3rem;
      }
    }
    .login {
      padding-left: 4rem;
      display: flex;
      align-items: center;
      line-height: 1;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        flex: 0 0 auto;
      }
      @media screen and (max-width: $bp-noGrid) {
        flex: 1 0 100%;
        position: absolute;
        top: 1.25rem;
        left: 0;
        padding-left: 2rem;
      }
      svg {
        height: 4rem;
        fill: currentColor;
        transition: color 0.3s ease-out;
      }
      span {
        display: block;
        margin-left: 1.2rem;
        font-weight: 900;
        font-size: 1.5rem;
        margin-top: 0.8em;
        margin-right: 3em;
        color: $blue-ci;
        transition: color 0.3s ease-out;
      }
      &:hover,
      &:active {
        color: $yellow;
      }
    }
  }
}
.col-lg-16{
  @media screen and (min-width: 992px){
    .ce-360promo{
      .grid{
      grid-template-rows: repeat(7, 1fr) 1fr;
      }
      .content {
        grid-area: 5 / 5 / 10 /20;
      }
      .foto{
        grid-area: 2 / 1 / 7 / 18;
      }
      .claim{
        grid-area: 1 / 15 / 2 / 20;
        align-items: flex-end;
      }
      .actions{
        .login{
          flex: 0 0 auto;
        }
        .btn-360{
        }
      }
    }
  }
 @media screen and (min-width: 1024px){
   .ce-360promo {
     .logo {
       grid-area: 1 / 2 / 3 / 7;
       img{
         transform: translate(-45%, 18%) scale(1);
       }
     }
     .claim{
       img{
         transform: translateY(-25%);
       }
     }
   }
 }
 @media screen and (min-width: 1650px){
   .ce-360promo {
     .logo {
       grid-area: 1 / 1 / 3 / 6;
       img{
         transform: translate(-50%, 20.5%) scale(1);
       }
     }
     .foto{
       grid-area: 2 / 1 / 6 / 18;
     }
     .content{
       grid-area: 5 / 4 / 10 /20;
     }
     .box-360 ul li {
       font-size: 1.8rem;
     }
   }
 }
 @media screen and (max-width: 1023px){
   .ce-360promo{
     .content{

     }
     .foto {
       grid-area: 2 / 1 / 6 / 18;
     }
     .logo{
       img{
         transform: scale(1) translateX(-18.7%);
       }
     }
   }
 }
 @media screen and (max-width: 991px){
   .ce-360promo{
     .content{
       grid-area: 5 / 5 / 9 / 20;
     }
     .foto {

     }
     .logo{
       img{

       }
     }
   }
 }
 @media screen and (max-width: 768px) and (min-width: $bp-noGrid){
   .ce-360promo {
     .box-360{
       border-radius: 1.5rem 1.5rem 0 1.5rem;
     }
     .btn-360{
       border-radius: 0 0 1.5rem 1.5rem;
     }
     .claim {
       grid-area: 1 / 15 / 2 / 20;
     }
     .logo{
       img{
         transform: scale(1) translate(-18.7%, -25%);
       }
     }
 }
}
 @media screen and (max-width: $bp-noGrid){
   .ce-360promo{
     .content {
     }
     .logo{
       margin-right: 0;
       img{
         transform: translateX(30px);
       }
     }
     .box-360{
       border-radius: 0 0 1.5rem 1.5rem;
     }
     .actions{
       .login{
         padding-left: 0;
       }
       .btn-360{
         margin: 0;
       }
     }
   }
 }
}
