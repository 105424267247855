//==========VORLAGE==========//

.fixedBgImage {
  background-position: center 0;
  background-repeat: no-repeat !important;
  background-attachment: fixed;
  background-color: #fff;
  width: 100%;
  height:50vH;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
  margin:0;
  padding:0;
  .iOS &{
    background-attachment: scroll;
    background-position: center center;
  }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){
  .fixedBgImage {
  }
}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){
  .fixedBgImage {
    .isMobile & {
      background-attachment: scroll;
      background-position: center center;
    }
  }
}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 375px){

}

