//==========SLICKSLIDER==========//

ul.slick-dots {
    position: absolute;
    bottom: 3em;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 1em;

    li {
        cursor: pointer;
        margin: 0 .2em;
        padding: 0;
        list-style: none;
        display: inline-block;
        width: 0.6em;
        height: 0.6em;
        border-radius: 50%;
        background: $grey-ci-bright;
        overflow: hidden;
        transition: $t-fast;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover, &:focus {
            transform: scale(1.25);
        }

        &.slick-active {
            background: $yellow;
            cursor: default;

            &:hover, &:focus {
                transform: scale(1);
            }
        }

        button {
            display: none;
        }
    }
}

.slick-arrow {
    display: none !important;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);

    &.slick-prev {
        left: -100px
    }

    &.slick-next {
        right: -100px;
    }
}

.image-slick-gallery {
    ul.slick-dots {
        margin: 0;
        bottom: 2em;

        li:not(.slick-active) {
            background-color: #fff;
        }
    }

    .slick-arrow {
        display: block !important;
        height: 100%;
        top: 0;
        transform: none;
        width: 10%;
        min-width: 12rem;

        .ksc-icon {
            cursor: pointer;
            color: $blue-ci;
            font-size: 50px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            border-radius: 50%;
            border: .15em solid #fff;
            @media all and (max-width: 767px) {
                font-size: 40px;
            }
            @media all and (min-width: 1650px) {
                font-size: 70px;
            }

            &:hover, &:focus, &:active {
                color: $yellow;
            }
        }

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;

            .ksc-icon {
                right: 0;
                transform: translate(50%, -50%) rotate(180deg);
            }
        }
    }
}

.teamslider .slick-list {
    overflow: inherit;
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}