//HEADER===//

$logowidth: 16em;
$navMargin: calc((#{$logowidth} / 2) + (#{$logowidth} / 4));
.headerparts {
    position: relative;
    z-index: 100;
}

#logo {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate3D(-50%, -50%, 0);
    width: $logowidth;
    &:hover, &:focus {
        animation: logoHover .25s;
    }
}
#meineHeimat{
    width: 28.6em;
    height: 8.5em;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(8em, -0.4em) scale(0.5);
    transform-origin: left center;
    opacity: 0;
    animation: meineHeimat forwards .5s 2s;
    .navcontainer:not(.stuck) & {
        opacity: 0;
        animation: unset;
    }
    .stuck & {
        animation: unset;
        transform: translate(3.2em, -2.1em) scale(0.27);
    }
    svg{
        path{
            transition: fill 0.3s;
            fill: #fff;
        }
    }
    &:hover, &:active{
        svg path{
            fill: $yellow;
        }
    }
}

@media all and (max-width: 1200px) {
    #meineHeimat {
        .stuck & {
            opacity: 1;
            transform: translate(4.6em, -1.9em) scale(0.27);
        }
    }
}
@media all and (max-width: 767px){
    #meineHeimat{
        transform: translate(6.5em, -0.4em) scale(0.5);
        .stuck & {
            transform: translate(3.8em, -1.8em) scale(0.27);
        }
    }
}
@media all and (max-width: 600px){
        #meineHeimat{
            transform: translate(5.0em, -0.8em) scale(0.32)
    }
}
@media all and (max-width: 479px){
    #meineHeimat{
        .stuck & {
            transform: translate(2.5em, -1.6em) scale(0.23)
        }
    }
}

@media all and (max-width: 374px){
    #meineHeimat{
        transform: translate(3.2em, -0.8em) scale(0.27)
    }
}

@media all and (max-width: 319px){
    #meineHeimat{
        display: none;
    }
}

@keyframes logoHover {
    0% {
        transform: translate3D(-50%, -50%, 0) rotate(0)
    }
    50% {
        transform: translate3D(-50%, -50%, 0) rotate(-15deg)
    }
    100% {
        transform: translate3D(-50%, -50%, 0) rotate(0)
    }
}
@keyframes meineHeimat {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
header {
    margin-top: $loginBarHeight;
    position: relative;
    background: $blue-ci;
    .topheader {
        height: 8em;
        padding: 0 3em;
        display: table;
        width: 100%;
        background: $blue-ci;
    }
    .lc, .rc {
        display: table-cell;
        vertical-align: middle;
    }
    .lc {
        text-align: left;
    }
    .rc {
        text-align: right;
    }
    .bwin, .mainsponsor {
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }
    .bwin {
        height: 6em;
        img {
            height: 100%;
            width: auto;
        }
        a {
            position: absolute;
            width: 50%;
            height: 100%;
            display: block;
            left: 0;
            top: 0;
            background: transparent;
            + a {
                left: 50%;
            }
        }
    }
    .mainsponsor {
        height: 3em;
        margin-left: 0;
        img {
            height: 100%;
            width: auto;
        }
    }
    .hashtag {
        font-weight: 800;
        font-size: 2.4em;
        color: #fff;
        display: inline-block;
        margin-right: .5em;
        a {
            color: #fff;
            &:hover, &:focus {
                color: $yellow;
            }
        }
    }
    .relegate {
        height: 3.0em;
        font-weight: 800;
        font-size: 2.1em;
        color: #fff;
        display: inline-block;
        margin-top: 0em;
        margin-right: -0.7em;
        margin-left:0.9em;
        vertical-align:middle;
        img {
            height: 100%;
            width: auto;
        }
    }
    .social {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        .ksc-icon {
            color: #fff;
            display: inline-block;
            font-size: 2.2em;
            cursor: pointer;
            margin: 0em 0 0 0.3em;
            &:hover {
                color: $yellow;
            }
        }
    }
    .marketing {
        position: absolute;
        z-index: 110;
        right: 3em;
        color: $grey-ci-dark;
        bottom: 9.9%;
        a {
            display: inline-block;
            .ksc-icon {

                color: $yellow;
                display: block;
                font-size: 3.2em;
                text-align: center;
            }
            &:first-child {
                margin-right: 1em;
            }
            span {
                display: block;
                font-size: 10px;
                margin-top: 0.5em;
                font-family: $font;
                text-transform: uppercase;
                font-weight: 900;
                color: $blue-ci;
                //transition: $t-fast;
            }
            &:hover {
                .ksc-icon {
                    color: $blue-ci;
                }
                span {
                    color: $blue-ci;
                }
            }
        }
    }

    .jobOffer {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        border: 1.5px solid currentColor;
        padding: 4px 5px;
        margin-left: 10px;
        line-height: 1;
        font-size: 11px;
        font-weight: 800;
        &:hover, &:focus, &:active {
            background: $yellow;
            border-color: $yellow;
            color: $blue-ci;
            border-radius: 15px;
        }
    }
}

//STICKY HEADER =================================//
.navcontainer {
    transition: $t-slow;
    transition-delay: 0s;
    position: relative;
    z-index: 1000;
    width: 100%;
    max-width: 1920px;
    left: 50%;
    top: 0;
    transform: translate3D(-50%, 0, 0);
    &.stuck {
        position: fixed;
        transform: translate3D(-50%, -200%, 0);
        //transition-delay: .3s;
        #logo {
            top: 100%;
            transform: translate3D(-50%, -50%, 0) scale(0.5);
                &:hover, &:focus {
                    @media all and (max-width: 1200px){
                    animation: logoHover_sticky_mobile .25s;
                }
            }

            @media all and (min-width: 1201px){
                transform: translate3D(-50%, -58%, 0) scale(0.8);
                &:hover, &:focus {
                    animation: logoHover_sticky .25s;
                }
            }

        }
        &.scrollup {
            position: fixed;
            transition-delay: 0s;
            top: $loginBarHeight;
            transform: translate3D(-50%, 0, 0);
        }
    }
    .msIE & {
        left: 0;
        transform: none;
        &.stuck {
            transform: translate3D(0, -200%, 0);
        }
    }
}

.xheader {
    position: fixed;
    max-width: 1920px;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 1000;
    width: 100%;
    .topheader {
        //display: none;
    }
}

@keyframes logoHover_sticky {
    0% {
        transform: translate3D(-50%, -58%, 0) scale(0.8) rotate(0);
    }
    50% {
        transform: translate3D(-50%, -58%, 0) scale(0.8) rotate(-15deg);
    }
    100% {
        transform: translate3D(-50%, -58%, 0) scale(0.8) rotate(0);
    }
}
@keyframes logoHover_sticky_mobile {
    0% {
        transform: translate3D(-50%, -50%, 0) scale(0.5) rotate(0);
    }
    50% {
        transform: translate3D(-50%, -50%, 0) scale(0.5) rotate(-15deg);
    }
    100% {
        transform: translate3D(-50%, -50%, 0) scale(0.5) rotate(0);
    }
}
//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {
    header {
        .social {
            display: none;
        }
        .marketing {
            color: #fff;
            bottom: 53%;
            .ksc-icon {
                span {
                    transition: $t-fast;
                    color: #fff;
                }
                &:hover, &:focus {
                    color: $yellow !important;
                    span {
                        color: $yellow;

                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    header {
        .marketing {
        }
    }
}

@media (max-width: 767px) {
    header {
        #logo {
            img {
                transform: scale(.8);
            }
        }
    }
}
@media (max-width: 640px) {
    header {
        .bwin, .mainsponsor{
            font-size: 7px;
        }
    }
}
@media (max-width: 479px) {
    header .relegate {
        display:none;
    }
    @keyframes burgerAni {
        0% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .burger {
        top: 1.2em;
        right: 2em !important;
        &.open {
            top: -40px;
            z-index: 120;
            animation: burgerAni .6s;
        }
    }
    header .marketing {
        right: 2em !important;
        z-index: 100;
    }
    header {
        #logo {
            img {
                transform: scale(.6);
            }
        }
        .hashtag {
            font-size: 2em;
        }
        .topheader {
            padding: 0 1em;
            height: 6.8em;
        }
        .bwin {
            height: 4.2em;
        }
    }
}

@media (max-width: 375px) {
    header {
        .bwin, .mainsponsor{
            font-size: 6px;
        }
    }
}