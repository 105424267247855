//==========formation==========//

section.formation{
  .team{
    border: 1px solid $grey-superlight;
    padding-top: 3.33333em;
  }
  .header{
    text-align: center;
    .ad{
      margin-top: 3.33333em;
    }
    .logo{
      display: inline-block;
      width: 10em;
      text-align: center;
      img{
        display: inline-block;
        height: 10em;
        width: auto;
      }
    }
    .teamName{
      line-height: 1;
      text-transform: uppercase;
      margin: 3em 0 0;
      span{
        font-size: 3em;
        font-weight: 900;
        line-height: 1.15;
      }
    }
    .gameFormation{
      display: inline-block;
      border-top:1px solid $grey-ci-dark;
      border-bottom:1px solid $grey-ci-dark;
      margin: 3em 0 0 0;
      span{
        font-size: 3em;
        font-weight: 300;
        margin: 0.1em 0;
        display: block;
      }
    }
  }
  table{
    &.table, &.tablestyle{
      font-size: inherit;
      tr{
        font-size: inherit;
        td{
          text-align: center;
          font-size: inherit;
          height: 6em;
          line-height: 1;
          border-width: 2px !important;
          span{
            display: inline-block;
          }
          &.nr{
            width: 7em;
            span{
              font-size: 2.75em;
              font-weight: 900;
              color: $grey-ci-dark;
            }
          }
          &.name{
            text-align: left;
            .preName{
              font-size: 1.25em;
              font-weight: 500;
            }
            .lastName{
              display: block;
              font-size: 2.25em;
              font-weight: 700;
            }
          }
          &.replace{
            width: 7em;
            text-align: center;
            span{
              max-width: 2.75em;
              display: inline;
              text-align: center;
              img{
                width: 100%; height: auto;
                display: block;
              }
            }
          }
        }
      }
    }
    &.table{
      margin-bottom: 3em;
      thead{
        font-size: 0;
      }
      tr{
        &:nth-child(odd){
          background: $grey-ultralight;
        }
        &:hover, &:focus{
          background: $grey-superlight !important;
          td{
            border-color: #fff;
          }
        }
        td{
          &.nr{
            span{
              color: $blue-ci;
            }
          }
        }
      }
    }
  }
  .bank{
    height: 4.5em;
    background: #fff;
    display: table;
    width: 100%;
    text-align: center;
    span{
      display: table-cell;
      vertical-align: middle;
      font-size: 2em;
      font-weight: 900;
      text-transform: uppercase;
    }
  }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){
  section.formation .header .teamName span {
    font-size: 2.3em;
  }
}
@media (max-width: 991px){
  section.formation{
    table{
      &.table, &.tablestyle{
        tr{
          td{
            height: 7.5em;
            &.nr{
              span{
                font-size: 2.0em;
              }
            }
            &.name{
              .lastName{
                font-size: 1.75em;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  section.formation{
    .team.home{
      margin-bottom: 6em;
    }
    table{
      &.table, &.tablestyle{
        tr{
          td{
            &.nr{
              span{
              }
            }
            &.name{
              .lastName{
              }
            }
          }
        }
      }
      &.table{
      }
    }
  }
}
@media (max-width: 480px){

}
@media (max-width: 375px){

}