//==========KickerApi==========//

.kicker {
  .selector {
    text-align:center;
    padding-bottom:30px;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    &:after{
      //content: "";
      display: block;
      position: absolute;
      right: 1.2em;
      top: 1.4em;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 6px 0 6px;
      border-color: #7a7a7a transparent transparent transparent;
    }
    select {
      font-size:2.5em;
      border: 2px solid $grey-ci-bright;
      background: $grey-ultralight;
    }
  }
  a.kicker {
    padding-top:10px;
    text-align:right;
    display: block;
    img {
      width: auto;
      height: auto;
      display:inline;
    }
  }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 480px){

}
@media (max-width: 375px){

}