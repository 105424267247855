//==========Page-Navigation==========//

.page-navigation {
  text-align: center;
  margin-bottom: 6em;
  margin-top: 2em;
  padding-top: 2em;
  border-top:1px solid $grey-superlight;
  > p{
    display: none;
  }
  ul.pagination {
    display: inline-block;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: block;
      float: left;
      margin-right: 0.15em;
      margin-bottom: 0;
      line-height: 1;
      font-size: 2.5em;
      a, span {
        border-radius: 4px;
        background: $grey-ultralight;
        color: $grey-ci-bright;
        display: block;
        min-width: 1.5em;
        text-align: center;
        font-weight: 900;
        font-size: 1em;
        padding: .2em;
        &:before, &:after {
          display: none;
        }
      }
      span {
      }
      &.active {
        span {
          background: $yellow;
          color: #fff;
        }
      }
      &.disabled {
        span {
          background: none;
        }
      }
      &.last {
        &.next {
          a {
            background: none !important;
            .ksc-icon {
              transform: rotate(180deg);
            }
          }
          &:hover, &:focus{
            a{
              .ksc-icon{
                color: $yellow;
              }
            }
          }
        }
      }
      &.previous {
        a {
          background: none !important;
        }
        &:hover, &:focus{
          a{
            .ksc-icon{
              color: $yellow;
            }
          }
        }
      }
      &:hover, &:focus {
        a {
          background: $grey-ci-bright;
          color: #fff;
        }
      }
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
  .page-navigation{
    text-align: center;
  }
}

@media (max-width: 767px) {

}

@media (max-width: 480px) {
  .page-navigation{
    ul.pagination{
      font-size: 2.05vW;
    }
  }
}

@media (max-width: 375px) {

}