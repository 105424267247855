//==========Prev-Next==========//
.prevNext, .mcLivelinks{
    text-align: center;
    margin-bottom: 6em;

    .container {
        padding: 0;
    }

    .btn {
        margin-top: 6em;

        span {
            font-size: 1.8em;
            font-weight: 700;
            padding: 0 1em;
        }

        &:before, &:after {
            left: 0;
            width: 100%;
        }

        &.btn-next, &.btn-prev {
            display: inline-block;
            max-width: 20em;
            min-width: 16.5em;
            left: auto;
            transform: none;

        }

        &.btn-prev {
            text-align: right;
            margin-right: 1px;

            &:before, &:after {
                border-radius: 3em 0 0 3em;
            }

            .ksc-icon {
                transform: translateY(-50%);
                left: 1.5em;
            }
        }

        &.btn-next {
            text-align: left;
            margin-left: 1px;

            &:before, &:after {
                border-radius: 0 3em 3em 0;
            }

            .ksc-icon {
                transform: translateY(-50%) rotate(180deg);
                right: 1.5em;
            }
        }

        .ksc-icon {
            position: absolute;
            z-index: 10;
            top: 50%;
            color: $grey-ci-dark;
            font-size: 1.2em;
        }

        &.blue {
            .ksc-icon {
                color: #fff;
            }
        }

        &:hover, &:focus {
            .ksc-icon {
                color: #fff;
            }

            &.btn-prev .ksc-icon {
                left: 1.2em;
            }

            &.btn-next .ksc-icon {
                right: 1.2em;
            }
        }
    }

    &.detailview {
        display: flex;
        max-width: 500px;
        margin: 0 auto;
        @media all and (max-width: 991px) {
            font-size: 1.5rem;
        }

        .btn {
            &:before, &:after {
                box-shadow: none;
            }

            &.btn-next, &.btn.btn-prev {
                display: block;
                max-width: none;
                min-width: 30%;
                left: auto;
                transform: none;
                flex: 1 1 30%;
                @media all and (max-width: 450px) {
                    span {
                        opacity: 0;
                    }
                    max-width: 7.2rem;
                    min-width: 0;
                }
            }

            &.blue.detailview {
                max-width: 6em;
                min-width: 0;
                position: relative;
                left: auto;
                transform: none;
                display: flex;
                width: auto;
                margin-left: 1px;
                margin-right: 1px;

                &:before, &:after {
                    border-radius: 0;
                }

                span.icon_list {
                    padding: 0 .5em;
                    display: flex;
                    align-items: center;

                    svg {
                        width: 1.5em;
                    }
                }
            }
        }
    }
}
.mcLivelinks{
    margin-bottom:3em;
}


    //BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 550px) {
    .prevNext, .prevNext.detailview, .mcLivelinks {
        .btn.btn-next, .btn.btn-prev {
            max-width: 13em !important;
            min-width: 13em !important;
        }
    }
}

@media (min-width: 992px) {
    .prevNext, .mcLivelinks {
        font-size: 12px;
    }
}

@media (min-width: 1280px) {
    .prevNext, .mcLivelinks {
        font-size: 14px;
    }
}

@media (min-width: 1480px) {
    .prevNext, .mcLivelinks {
        font-size: 16px;
    }
}

//PREV-NEXT MATCHDAY =======================//

@media all and (min-width: 440px){
    .prevNext.matchday, .mcLivelinks{
        .btn.btn-next, .btn.btn-prev {
            max-width: 16em !important;
            min-width: 16em !important;
        }
    }
}
@media all and (max-width: 400px){
    .prevNext.matchday {
        .btn.btn-next, .btn.btn-prev {
            height: 3.7em;
            min-width: 4.3em;
            max-width: 4.3em;
            font-size: 1.5em;
            margin-top: 3em;
            span{
                display: none;
            }
        }
    }
    .mcLivelinks {
        .btn.btn-next, .btn.btn-prev {
            height: 3.7em;
            font-size: 1.0em;
            max-width:11em;
            min-width:11em;
        }
    }
}
