//==========Gallery==========//

.gallery {
    > .row{
        margin: 0 -15px;
    }
    .item {
        padding: 0 15px;
        margin-bottom: 30px;
        @media all and (max-width: 480px){
            width: 50%;
        }
        .inner {
            cursor: pointer;
            position: relative;
            display: block;
            .zoom {
                background: transparentize(#000, .25);
                pointer-events: none;
                transform: scale(.75);
                transform-origin: right bottom;
                @media all and (max-width: 600px){
                    display: none;
                }
            }
        }
    }
    figure {
        margin: 0 !important;
        img {
            transition: $t-slow;
        }
        &:hover, &:focus {
            img {
                @include imgHover;
            }
        }
    }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
    .gallery {
        .galleryTeaser {
            .item.col-xs-12 {
                width: 50%;
                margin-bottom: 30px;
                &:nth-child(odd) {
                    padding-right: 15px;
                }
                &:nth-child(even) {
                    padding-left: 15px;
                }
            }
        }
    }
}

@media (max-width: 649px) {

}

@media (max-width: 480px) {
    .gallery {
        .item.col-xs-12 {
            .zoom {
                right: 10px;
                bottom: 10px;
                transform: scale(.8);
            }
        }
    }
}

@media (max-width: 375px) {

}