@mixin ksc360_batch {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    z-index: 100;
    right: 1.5rem;
    top: 1.5rem;
    width: 20%;
    padding-bottom: 20%;
    min-width: 70px;
    min-height: 70px;
    background: url("../Img/ksc360/ksc360noclaim.svg") no-repeat center bottom #fff;
    border-radius: 50%;
    background-size: contain;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
    transform-origin: top right;
  }
}

.paidContent {
  @include ksc360_batch;
}

.ksc360page .paidContent {
  &::after {
    display: none !important;
  }
}

#heroslider {
  a.paidContent {
    position: static;

    &:after {
      right: 3rem;
      top: 3rem;
      width: 10%;
      padding-bottom: 10%;
      min-width: 100px;
      min-height: 100px;
    }
  }

  .ksc360Hl {
    width: 50%;
    max-width: 400px;
    height: auto;
    margin: 0 auto 5rem auto;
    display: block;

  }

  &.swiper-container {
    a.paidContent:after {
      display: none;
    }
  }
}

.newsItem {
  &.paidContent {
    .news-list-category {
      display: none !important;
    }

    time {
      visibility: hidden;
    }

    &:after {
      display: none;
    }
  }
}

.article {
  .info {
    img.ksc360 {
      width: 140px;
      height: auto;
    }
  }


  .abohint {
    margin-bottom: 0;

    img.ksc360 {
      width: 140px;
      height: auto;
      display: inline-block;
      margin: 0 3px;

      @media (max-width: 500px) {
        display: block;
        margin: 5px 0;
      }
    }

    + section.ce-360promo {
      padding-top: 0em;
    }
  }
}

.newsList {
  .info {
    img.ksc360 {
      width: 120px;
      height: auto;
    }
  }
}

