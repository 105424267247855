//==========PERSON==========//

.person {
    margin: 0 0 6em 0;
    h1, .h1 {
        font-style: normal;
        font-size: 3.5em;
    }
    .name {
        font-weight: 900;
        font-size: 3em;
        padding-bottom: .6em;
        margin-bottom: .6em;
        border-bottom: 1px solid $grey-superlight;
    }
    figure {
        img {

        }
    }
    figure, .info {
        margin-bottom: 3em;
    }
    .info {
    }
    .item {
        margin-bottom: 1.5em;
        .label {
            font-size: 2em;
            font-weight: 900;
            line-height: 1.25;
        }
        .value {
            font-size: 2em;
            font-weight: 500;
            line-height: 1.25;
        }
    }
    &.small {
        h1, .h1 {
            font-size: 2.5em;
        }
        .name {
            font-size: 2em;
        }
        p {
            font-size: 1.75em;
        }
    }
}

.personTwoCol {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin: -2px;
    .person.small {
        > .h1 {
        }
    }
}


//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 481px) {
    .person .info {
        margin-left: -30px;
    }
}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
    .person:not(.player) {
        margin-bottom: 30px;
        figure {
            margin-bottom: 0;
        }
        .name {
            margin-top: .6em;
        }
        &:not(small) {
            .name {
                font-size: 2.5em;
            }
        }
        [class*="col-"] {
            float: left;
            width: 50%;
        }
    }
}

@media (max-width: 480px) {
    .person {
        [class*="col-"] {
            float: none;
            width: 100%;
        }
    }
}

@media (max-width: 375px) {

}