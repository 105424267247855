//========== Richy T.Edit ==========//

.table-responsive, .box{
  margin-bottom: 6em;
}
.wrap .box{margin-bottom: 0;}
.blockquote-person{
  text-align: center;
  font-weight: 700;
  margin-top: -.75em;
  font-size: 1.75em;
  margin-bottom: 4em;
}

main .container{
  > blockquote{
    max-width: 75%;
    margin-left: 12.5%;
  }
  > p:last-child{
    margin-bottom: 2em !important;
  }
}

.btn.rte:not(.small){
  transition: $t-slow;
  position: relative;
  z-index: 3;
  font-size: 2.4em;
  padding: 0 1em;
  line-height: 2.25em;
  font-weight: 900;
  color: #fff;
  text-align: center;
  border-radius: 3em;
  min-width: 33%;
  max-width: 100%;
  @include boxshadow_grey;
  //@include gradient_btn-blue;
  background: $blue-ci;
  &:before, &:after{
    display: none;
  }
  &:hover{
    @include boxshadow_grey_hover;
    //@include gradient_yellow;
    background: $yellow;
  }
  p &{
    //font-size: calc(2.4em / 2.25);
    //border-radius: calc(3em / 2.25);
    font-size: 2.4rem;
    border-radius: 3rem;
    margin: 0;
  }

}
//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px){

}
@media (min-width: 992px){

}

@media (min-width: 1280px){

}
@media (min-width: 1480px){

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px){

}
@media (max-width: 1279px){

}
@media (max-width: 991px){
  .container{
    > blockquote{

    }
  }
}
@media (max-width: 767px){
  .btn.rte:not(.small){
    min-width: 75%;
  }
}
@media (max-width: 480px){

}
@media (max-width: 375px){

}