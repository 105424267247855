
section.countdown {
  .btn {
    margin: 4.5em auto 0;
  }
  .tick-group {
    margin: 0 .2em;
    text-align: center;
    .tick-flip-panel {
      background-color: $blue-ci;
    }
    .tick-label.tick-text {
      font-size: 0.42em;
      margin-top: 0.6em;
      display: block;
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  .tick {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  section.countdown {
    .tick-group .tick-label.tick-text {
      font-size: 0.48em;
      margin-top: 0.6em;
      display: block;
    }
  }
}

@media (max-width: 375px) {

}