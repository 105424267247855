//==========Wildpark TV==========//

.wildparkTV {
  @include gradient_wildparkTV;
  padding-top: 4em;
  .btn {
    @include boxshadow_darkblue_soft;
  }
  .h1, h1 {
    @include textshadow_darkblue;
    .ksc-icon {
      display: inline-block;
      position: relative;
      top: 0.125em;
      font-size: 1.24em;
      margin-left: 0.25em;
    }
  }
  .item {
    @include gradient_grey-light;
    @include boxshadow_darkblue_soft;
    padding: 3em;
    position: relative;
    .video {
      position: relative;
      &:after {
        font-family: ksc-icons;
        content: '#{$ksc-icon-arrow-triangle}';
        display: block;
        color: $blue-ci;
        position: absolute;
        z-index: 10;
        font-size: 2em;
        left: 50%;
        top: 50%;
        transform: translate3D(-50%, -50%, 0);
        background: transparentize(#fff, .1);
        width: 3em;
        line-height: 3em;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        transition: $t-fast;
      }
      &:hover, &:focus {
        &:after {
          color: $yellow;
        }
      }
    }
    .info {
      color: $font_darkgrey;
      p {
        display: none;
        margin: 0;
        line-height: 1.25
      }
      .h2, h2 {
        font-weight: 900;
        font-size: 1.75em;
        margin-bottom: .5em;
      }
    }
    &.bigVideo {
      .info {
        padding-top: 3em;
        p {
          display: block;
          font-size: 1.25em;
          font-weight: 400;
        }
      }
    }
    &.smallVideo {
      padding: 1.5em 1.5em 1.5em;
      .video {
        &:after {
          transform: translate3D(-50%, -50%, 0) scale(.5);
          transform-origin: center center;
        }
      }
      .h2, h2 {
        font-size: 1.2em;
        line-height: 1.2;
        font-weight: 700;
        margin: 0;
        position: relative;
        margin-top: 1.16em;
        color: $font_darkgrey;
      }
    }
  }
  .oneCol .item {margin-bottom:6em;}
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1280px) {
  .wildparkTV {
    .vid2 {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {
  .wildparkTV {
    .mHeight {
      //height: auto !important;
    }
    .item {
      &.bigVideo {
        margin-bottom: $gridGutter;
      }
    }
  }
}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
  .wildparkTV {
    .btn.white{
      margin-top: 1.5em;
    }
    .item {
      &.smallVideo, &.bigVideo {
        padding: 1.5em 1.5em 1.75em;
        margin-bottom: 4.5em;
        .info {
          padding-top: 0;
          h2, .h2 {
            font-size: 1.5em;
            line-height: 1.2;
            font-weight: 900;
            margin: 0;
            position: relative;
            top: 0;
            margin-top: 1.25em;
            color: $blue-ci;
          }
          p {
            font-weight: 400;
            font-size: 1.5em;
            margin-top: 0.5em;
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}