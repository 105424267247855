$font: 'Lato', Arial, Helvetica, sans-serif;
$font2: 'American_Captain_01', Arial, Helvetica, sans-serif;

//COLORS============//
$blue-ci:rgb(0, 75, 147);
$blue-ci-bright:rgb(52, 103, 167);
$blue-ci-dark:rgb(0, 42, 87);
$turquoise:rgb(78, 191, 216);
$blue-vivid2019: #009edf;

$blue-70:lighten($blue-ci,30%);
$blue-50:lighten($blue-ci,50%);

$blue-dark:rgb(0, 61, 118);
$blue-darkest:$blue-ci-dark;

$yellow: rgb(22, 159, 219);
$green: #60AE69;

$grey-ci:rgb(136, 137, 139);
$grey-ci-bright:rgb(157, 159, 160);
$grey-ci-dark:rgb(88, 88, 90);
$grey-ci-light:rgb(206, 207, 208);
$grey-superlight:rgb(226, 227, 228);
$grey-ultralight:rgb(240, 241, 242);

$font_darkgrey: $grey-ci-dark;
$font_lightgrey: $grey-ci;

$grey-50:lighten($grey-ci-dark,55%);
$grey-40:lighten($grey-ci-dark,60%);
$grey-30:lighten($grey-ci-dark,70%);
$grey-20:lighten($grey-ci-dark,80%);
$grey-15:lighten($grey-ci-dark,62%);
$grey-10:lighten($grey-ci-dark,90%);

$twitter: #1b95e0;
$facebook:#3b5998;
$whatsapp:#00d46e;

$loginBarHeight:40px;


//GRADIENTS============//
@mixin gradient_white-transparent{
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 98%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 98%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 98%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 98%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
}

@mixin gradient_ci2019{
    background: $blue-ci;
    background: -moz-linear-gradient(45deg, $blue-ci 0%, $blue-vivid2019 100%);
    background: -webkit-linear-gradient(45deg, $blue-ci 0%,$blue-vivid2019 100%);
    background: linear-gradient(45deg, $blue-ci 0%,$blue-vivid2019 100%);
}
@mixin gradient_ci2019_straight{
  background: $blue-ci;
  background: -moz-linear-gradient(0deg, $blue-ci 0%, $blue-vivid2019 100%);
  background: -webkit-linear-gradient(0deg, $blue-ci 0%,$blue-vivid2019 100%);
  background: linear-gradient(0deg, $blue-ci 0%,$blue-vivid2019 100%);
}
@mixin gradient_grey-light{
  background: $grey-30; /* Old browsers */
  background: -moz-linear-gradient(top, $grey-30 0%, $grey-15 100%);
  background: -webkit-linear-gradient(top, $grey-30 0%,$grey-15 100%);
  background: linear-gradient(to bottom, $grey-30 0%,$grey-15 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$grey-30', endColorstr='$grey-15',GradientType=0 );
}
@mixin gradient_ci-blue{
  background: $blue-ci-bright; /* Old browsers */
  background: -moz-linear-gradient(top, $blue-ci-bright 0%, $blue-ci 100%);
  background: -webkit-linear-gradient(top, $blue-ci-bright 0%,$blue-ci 100%);
  background: linear-gradient(to bottom, $blue-ci-bright 0%,$blue-ci 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-ci-bright', endColorstr='$blue-ci',GradientType=0 );
}
@mixin gradient_ci-turquoise{
    background: $turquoise; /* Old browsers */
    background: linear-gradient(165deg, $turquoise 0%,$blue-ci 65%, $blue-ci-dark 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#88bfe8', endColorstr='#140e1c',GradientType=1 );

}
@mixin gradient_darkblue{
  background: $blue-ci-bright;
  background: -moz-linear-gradient(180deg, $blue-ci-bright 0%, $blue-darkest 70%);
  background: -webkit-linear-gradient(180deg, $blue-ci-bright 0%,$blue-darkest 70%);
  background: linear-gradient(-180deg, $blue-ci-bright 0%,$blue-darkest 70%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-ci-bright', endColorstr='$blue-darkest',GradientType=1 );
}
@mixin gradient_midblue{
  background: $blue-dark;
  background: -moz-linear-gradient(180deg, $blue-dark 0%, $blue-ci 100%);
  background: -webkit-linear-gradient(180deg, $blue-dark 0%, $blue-ci 100%);
  background: linear-gradient(-180deg, $blue-dark 0%,$blue-ci 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-dark', endColorstr='$blue-ci',GradientType=1 );
}
@mixin gradient_btn-blue{
  background: $blue-ci; /* Old browsers */
  background: -moz-linear-gradient(top, $blue-ci-bright 0%, $blue-ci 100%);
  background: -webkit-linear-gradient(top, $blue-ci-bright 0%,$blue-ci 100%);
  background: linear-gradient(to bottom, $blue-ci-bright 0%,$blue-ci 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-ci-bright', endColorstr='$blue-ci',GradientType=0 );
}
@mixin gradient_btn-white{
  background: $grey-15; /* Old browsers */
  background: -moz-linear-gradient(top, $grey-15 0%, $grey-50 100%);
  background: -webkit-linear-gradient(top, $grey-15 0%, $grey-50 100%);
  background: linear-gradient(to bottom, $grey-15 0%, $grey-50 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$grey-15', endColorstr='$grey-50',GradientType=0 );
}
@mixin gradient_section-grey{
  background: $grey-15; /* Old browsers */
  background: -moz-linear-gradient(top, $grey-50 0%, $grey-15 100%);
  background: -webkit-linear-gradient(top, $grey-50 0%, $grey-15 100%);
  background: linear-gradient(to bottom, $grey-50 0%, $grey-15 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$grey-50', endColorstr='$grey-15',GradientType=0 );
}
@mixin gradient_wildparkTV{
  $gradientInner: rgb(149, 179, 207);
  background: $blue-darkest;
  background: -moz-radial-gradient(center, ellipse cover, $gradientInner 0%, $blue-ci-bright 50%, $blue-ci 65%, $blue-darkest 100%);
  background: -webkit-radial-gradient(center, ellipse cover, $gradientInner 0%, $blue-ci-bright 50%, $blue-ci 65%,$blue-darkest 100%);
  background: radial-gradient(ellipse at center, $gradientInner 0%, $blue-ci-bright 50%,$blue-ci 65%, $blue-darkest 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradientInner', endColorstr='$blue-darkest',GradientType=1 );
}
@mixin gradient_yellow{
  background: $yellow; /* Old browsers */
  background: -moz-linear-gradient(top, $yellow 0%, $yellow 100%);
  background: -webkit-linear-gradient(top, $yellow 0%, $yellow 100%);
  background: linear-gradient(to bottom, $yellow 0%, $yellow 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$grey-30', endColorstr='$grey-15',GradientType=0 );
}




//SHADOWS================//s
@mixin textshadow_darkblue{
  text-shadow: 15px 15px 20px transparentize($blue-darkest, .25);
}
@mixin boxshadow_darkblue{
  box-shadow: 15px 15px 20px transparentize($blue-darkest, .25);
}
@mixin boxshadow_darkblue_soft{
  box-shadow: 13px 13px 25px transparentize($blue-darkest, .45);
}
@mixin boxshadow_darkblue_soft_hover{
  box-shadow: 13px 25px 30px transparentize($blue-darkest, 0.1);
}
@mixin boxshadow_grey{
  box-shadow: 1em 2em 3em rgba(0, 0, 0, 0.08);
}
@mixin boxshadow_grey_hover{
  box-shadow: .75em 1.5em 2.75em rgba(0, 0, 0, 0.11);
}

@mixin topshadow_black_top{
  $shadowcolor:#000000;
  background: transparent;
  background: -moz-linear-gradient(top, transparentize($shadowcolor, .45) 0%, transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%, transparentize($shadowcolor, 1) 100%);
  background: -webkit-linear-gradient(top, transparentize($shadowcolor, .45) 0%,transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%, transparentize($shadowcolor, 1) 100%);
  background: linear-gradient(to bottom, transparentize($shadowcolor, .45) 0%,transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%,transparentize($shadowcolor, 1) 100%);
}
@mixin topshadow_darkblue_top{
  $shadowcolor: $blue-darkest;
  background: transparent;
  background: -moz-linear-gradient(top, transparentize($shadowcolor, .45) 0%, transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%, transparentize($shadowcolor, 1) 100%);
  background: -webkit-linear-gradient(top, transparentize($shadowcolor, .45) 0%,transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%, transparentize($shadowcolor, 1) 100%);
  background: linear-gradient(to bottom, transparentize($shadowcolor, .45) 0%,transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%,transparentize($shadowcolor, 1) 100%);
}
@mixin topshadow_darkblue_bottom{
  transform: rotate(180deg);
  $shadowcolor: $blue-darkest;
  background: transparent;
  background: -moz-linear-gradient(top, transparentize($shadowcolor, .45) 0%, transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%, transparentize($shadowcolor, 1) 100%);
  background: -webkit-linear-gradient(top, transparentize($shadowcolor, .45) 0%,transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%, transparentize($shadowcolor, 1) 100%);
  background: linear-gradient(to bottom, transparentize($shadowcolor, .45) 0%,transparentize($shadowcolor, .75) 25%, transparentize($shadowcolor, 1) 75%,transparentize($shadowcolor, 1) 100%);
}


//TRANSITIONS============//

$t-fast: all cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.25s ;
$t-medium: all cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.35s;
$t-slow: all cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.5s;

//Variables=============//
$gridGutter: 60px;


//Interactions==========//
@mixin imgHover{
  transform: scale(1.25) rotate(-3deg);
}