//==========fixedBottomNav==========//

.fixedBottomNav {
    font-size: 10px;
    transition: all .5s;
    transition-delay: 0s;
    position: fixed;
    z-index: 100;
    width: 100%;
    max-width: 1920px;
    left: 50%;
    bottom: 0;
    transform: translate3D(-50%, 110px, 0);
    height: 70px;
    background: $blue-ci;
    color: #fff;
    line-height: 1;

    &.scrolldown {
        transform: translate3D(-50%, 0, 0);
        transition-delay: 0s;
    }

    //@include gradient_midblue;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        bottom: 100%;
        left: 0;
        height: 40px;
        opacity: .35;
        transform: rotate(180deg);
        @include topshadow_black_top;
        transition: $t-medium;
    }

    &.atBottom {
        &:before {
            opacity: 0;
            visibility: hidden;
        }
    }

    .layernav-open & {
        transform: translate3D(-50%, 110px, 0) !important;

        &.isBottom {
            transform: translate3D(-50%, 110px, 0) !important;
        }
    }

    &.isBottom {
        transform: translate3D(-50%, 0, 0) !important;

        &:before {
            opacity: 0;
            visibility: hidden;
        }
    }

    .logo-mh {
        position: absolute;
        left: auto;
        right: 10px;
        top: 50%;
        transform: translate(-10px, -50%);
        height: 52px;
        svg{
            height: 100%;
            width: auto;
        }
        @media all and (max-width:1023px){
            display: none;
        }
    }

    .left {
        width: 135px;
        height: 70px;
        display: table;
        background: $blue-ci-bright;
        float: left;
    }

    .right {
        width: 75px;
        height: 70px;
        display: table;
        background: $blue-ci-bright;
        float: right;
    }

    .center {
        float: left;
        height: 70px;
        width: calc(100% - 212px);
        display: table;
        position: relative;
        @media all and (max-width: 535px) and (min-width: 480px){
            width: calc(100% - 136px);
        }
        .centerLeft, .centerRight {
            display: table-cell;
            position: relative;
            vertical-align: middle;
            text-align: left;
            width: 50%;
            padding: 0 15px;
        }

        .centerLeft {
            width: 100%;
        }

        .centerRight {
            width: 197px;
            padding: 0 12px;
            border-left: 2px solid $blue-ci-bright;
        }
    }

    .marketing {
        display: table-cell;
        vertical-align: middle;
        padding: 0 10px;

        a {
            display: inline-block;
            width: calc(50% - 2px);
            text-align: center;

            .ksc-icon {
                color: #fff;
                display: inline-block;
                font-size: 3.2em;
                text-align: center;
            }

            &:first-child {
            }

            span {
                display: block;
                font-size: 10px;
                margin-top: 0.5em;
                font-family: $font;
                text-transform: uppercase;
                font-weight: 900;
                color: #fff;
            }

            &:hover, &:focus {
                .ksc-icon {
                    color: $yellow;
                }

                span {
                    color: #fff;
                }
            }
        }
    }

    .socialIcons {
        text-align: right;
        white-space: nowrap;

        a {
            color: #fff;
            font-size: 28px;
            margin: 0 4px;
            position: relative;
            z-index: 10;
            @media all and (min-width: 820px){
                margin: 0 8px;
            }
            &:hover, &:focus {
                color: $yellow;
            }
        }
    }

    .scrollTop {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;

        .ksc-icon {
            display: inline-block;
            color: #fff;
            font-size: 28px;
            transform: rotate(90deg);
        }
        &:hover, &:focus {
            .ksc-icon {
                color: $yellow;
            }
        }
        @media all and (max-width: 535px) and (min-width: 480px){
            display: none;
        }
    }

    .matchInfo {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 1024px){
            justify-content: flex-start;
        }

        time {
            display: block;
            text-align: left;
            font-size: 13px;
            font-weight: 500;
            max-width: 63px;
        }
            .date {
                font-weight: 700;
                margin-bottom: 3px;
            }
        .game {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            .logo {
                display: inline-block;
                vertical-align: middle;
                width: 40px;
                height: 40px;
                img {
                    width: auto;
                    max-height: 40px;
                }
            }

            .result {
                display: inline-block;
                vertical-align: middle;
                font-size: 20px;
                font-weight: 700;
                text-align: center;
                margin: 0 10px;
            }
        }
    }

    .buttonMC {
        background: $blue-ci-bright;
        display: block;
        text-align: center;
        height: 70px;
        width: 70px;
        padding: 9px 0 0;
        position: relative;
        top: 0;
        margin-left: 5px;

        .ksc-icon {
            color: #fff;
            font-size: 28px;
            margin-bottom: 5px;
        }

        span {
            transition: $t-fast;
            color: #fff;
            font-weight: 900;
            font-size: 10px;
            text-transform: uppercase;
        }

        &:hover, &:focus {
            background: $yellow;

            .ksc-icon, span {
                color: $grey-ci-dark;
            }
        }
    }
}

.isLive {
    .fixedBottomNav {
        .matchInfo {
            time, .game .result {
                color: $font_darkgrey;
            }
        }

        .centerLeft {
            text-align: center;
            background: $yellow;
            color: $font_darkgrey;
        }

        .centerLeft, .centerRight {
            border: none;
        }
    }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
    .fixedBottomNav {
        .right {
            width: 65px;
        }

        .buttonMC {
        }

        .center {
            .centerLeft {
                display: none;
            }

            .centerRight {
                border: none;
            }
        }

        .socialIcons {
            a {

            }
        }
    }
    .isLive {
        .fixedBottomNav {
            .right {
                width: 65px;
            }

            .buttonMC {
            }

            .center {
                width: calc(100% - 200px);

                .centerLeft {
                    border: none;
                    display: table-cell;
                    padding: 0;
                }

                .centerRight {
                    display: none;
                }
            }

            .socialIcons {
                a {

                }
            }
        }
    }
}

@media (max-width: 550px) {
    .isLive {
        .fixedBottomNav {
            .buttonMC {
                display: none;
            }

            .right {
            }

            .buttonMC {
            }

            .center {
                .centerLeft {
                }

                .centerRight {
                }
            }
        }
    }
}

@media (max-width: 479px) {
    .fixedBottomNav {
        .buttonMC {
            background: none;
            margin: 0;
            width: 100%;
        }

        .right {
            width: 65px;
        }

        .buttonMC {
        }

        .center {
            width: calc(100% - 200px);

            .centerLeft {
                display: none;
            }

            .centerRight {
                border: none;
                display: none;
            }
        }

        .socialIcons {
            display: none;

            a {

            }
        }
    }
    .isLive {
        .fixedBottomNav {
            .matchInfo {
                time {
                    width: 85px;
                }
            }

            .left {
                display: none;
            }

            .center {
                width: calc(100% - 65px);
                text-align: left;

                .centerLeft {
                    padding-left: 15px;
                    text-align: left;
                }
            }
        }
    }
}

@media (max-width: 375px) {

}

@media (max-height: 600px) {
    .fixedBottomNav {
        display: none;
    }
}
