@font-face {
  font-family: 'edoregular';
  src: url('../webfont/edo/edo-webfont.eot');
  src: url('../webfont/edo/edo-webfont.eot?#iefix') format('embedded-opentype'),
  url('../webfont/edo/edo-webfont.woff') format('woff'),
  url('../webfont/edo/edo-webfont.ttf') format('truetype'),
  url('../webfont/edo/edo-webfont.svg#edoregular') format('svg');
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-style: normal;
}
