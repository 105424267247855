//Heroslider============//

#heroslider {
  position: relative;
  overflow: hidden;

  .heronews {
    position: relative;
    &.live{
      a .newsteaser{
        .mainline{
          p{
            &:before{
              content: $ksc-icon-live-round;
            }
          }
        }
      }
    }
    &.video{
        a .newsteaser{
          .mainline{
            p{
              &:before{
                content: $ksc-icon-video-round;
              }
            }
          }
        }
      }
    &.audio{
      a .newsteaser{
        .mainline{
          p{
            &:before{
              content: $ksc-icon-audio-round;
            }
          }
        }
      }
    }
    &.gallery{
      a .newsteaser{
        .mainline{
          p{
            &:before{
              content: $ksc-icon-gallery-round;
            }
          }
        }
      }
    }
  }

  a {
    .newsteaser {
      display: block;
      transform: rotate(-6.15deg) scale(.8);
      $teaserpadding: .75em;
      position: absolute;
      bottom: 15%;
      left: 0;
      max-width: 66.66666%;
      transition: $t-medium;

      .topline {
        display: inline-block;
        background: $grey-ci-dark;
        color: #fff;
        font-size: 2.1em;
        line-height: 1;
        font-weight: 900;
        //white-space: nowrap;
        padding: $teaserpadding;
        margin-bottom: 7px;
        max-width: 50%;
      }

      .mainline {
        background: #fff;

        p {
          padding: calc(#{$teaserpadding} / 2);
          margin: 0;
          color: $blue-ci;
          font-size: 6.0em;
          line-height: 1;
          font-weight: 300;
          text-transform: uppercase;
          font-family: American_Captain_01, sans-serif;
          &:before{
            font-family: 'ksc-icons', sans-serif;
            display: inline-block;
            transform: translateY(.07em);
            margin-right: 0.25em;
          }
        }
      }

      @supports (box-decoration-break: clone) {
        .topline{
          margin-bottom: 0.075em;
        }
        .mainline {
          line-height: 1;
          padding: 0;
          margin: 0;
          background: transparent;

          p {
            background-color: #fff;
            display: inline;
            line-height: 1.6;
            padding: 0.05em 0.3em 0;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
          }
        }
      }
    }

    &:hover, &:focus {
      .newsteaser {
        transform: rotate(-5deg) scale(.87) translateX(4%);
      }
    }
  }
}

.heronav {
  margin-top: -0.5em;
  background: $blue-ci-bright;

  .slick-track {
    height: 8em;
  }

  ul {
    height: 8em;

    li {
      float: left;
      width: calc(100% / 5);
      background: $blue-ci-bright;
      padding: 0 2em;
      border-right: 1px solid #fff;
      display: table !important;
      position: relative;
      height: 100%;
      cursor: pointer;
      transition: $t-fast;
      margin-top: 0.5em;
      line-height: 1;
      margin-bottom: 0;
      font-size: 1em;

      &:before {
        position: absolute;
        content: "";
        display: block;
        background: $yellow;
        //width: 100%;
        height: .5em;
        left: -1px;
        right: -1px;
        top: 0;
        transform: translate3D(0, -100%, 0);
        transition: $t-fast;
        opacity: 0;
      }

      span {
        display: table-cell;
        vertical-align: middle;
        text-transform: uppercase;
        font-weight: 400;
        color: #fff;
        font-size: 1.7em;
        line-height: 1.2;
        padding-bottom: 0.25em;
      }

      &:last-child, &.slick-active:last-child {
        border: none;
      }

      &:hover, &:focus {
        background: $blue-ci;
      }

      &.slick-current {
        background: $blue-ci;

        span {
          font-weight: 700;
        }

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//

@media (min-width: 992px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {
  #heroslider {
    .newsteaser {
      max-width: 50%;

      .mainline {

      }

      .topline {

      }
    }
  }
}

//BREAKPOINT MAX-WIDTH==========================//
@media (min-width: 1350px) {
  .heronav {
    ul li {
      &.slick-current + li + li + li + li {
        border-right: 0;
      }
    }
  }
}

@media (max-width: 1349px) {
  .heronav {
    ul li {
      &.slick-current + li + li + li {
        border-right: 0;
      }
    }
  }
}

@media (max-width: 1100px) {
  .heronav {
    ul li {
      &.slick-current + li + li {
        border-right: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  #heroslider {
    a .newsteaser {
      max-width: 85%;
      bottom: 9%;

      .mainline {
        p {
          font-size: 4.75em;

        }
      }

      .topline {

      }
    }
  }
}

@media (max-width: 767px) {
  #heroslider {
  }
  .heronav {
    ul li {
      &.slick-current + li {
        border-right: 0;
      }
    }
  }
}

@media (max-width: 550px) {
  .heronav {
    ul li span {
      text-transform: none;
      font-size: 1.5em;
    }
  }
  #heroslider {
    a .newsteaser {
      max-width: 100%;
      bottom: 8%;

      .mainline {
        p {
          font-size: 4.6em;
          font-weight: 500;
        }
      }

      .topline {
        max-width: 75%;
      }
    }
  }
}

@media (max-width: 479px) {
  #heroslider {
    a .newsteaser {
      .mainline {
        p {
          text-transform: none;
          font-size: 3.8em;
        }
      }

      .topline {
        font-size: 1.9em;
      }
    }
  }
}

@media (max-width: 375px) {
  .heronav {
    font-size: 8px;
  }
  #heroslider {
    a .newsteaser {
      .mainline {
        p {
          text-transform: none;
          font-size: 3em;
        }
      }

      .topline {
        font-size: 1.7em;
      }
    }
  }
}

@media (max-width: 325px) {
  #heroslider {
    a .newsteaser {
      .mainline {
        p {
          text-transform: none;
          font-size: 2.8em;
        }
      }

      .topline {
        font-size: 1.5em;
      }
    }
  }
}