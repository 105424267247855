@import 'pace-theme-loading-bar.css';
@import 'scss_includes/BS-variables';
// Core variables and mixins
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
// Reset and dependencies
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";
// Core CSS
// @import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";
// Components
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";
// Components w/ JavaScript
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
//@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";
// Utility classes
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../Gulp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
// Slick
@import "../../Gulp/bower_components/slick-carousel/slick/slick.scss";
//Add Custom Files here
@import 'scss_includes/variables';
@import 'scss_includes/preloader';
@import 'scss_includes/grid';
@import 'scss_includes/HTML-Breakpoints';
@import 'scss_includes/base';
@import 'scss_includes/lato-webfont';
@import 'scss_includes/edo_webfont';
@import 'scss_includes/americancaptain_01-webfont';
@import 'scss_includes/americancaptain_regular-webfont';
@import 'scss_includes/btn';
@import 'scss_includes/fonts';
@import 'scss_includes/forms';
@import 'scss_includes/ksc-icons';
@import 'scss_includes/header';
@import 'scss_includes/cookie';
@import 'scss_includes/mainnav';
@import 'scss_includes/subnav';
@import 'scss_includes/layernav';
@import 'scss_includes/teaserItems';
@import 'scss_includes/bwin';
@import 'scss_includes/footer';
@import 'scss_includes/sponsors';
@import 'scss_includes/heroslider';
@import 'scss_includes/hero360slider';
@import 'scss_includes/headlineCe';
@import 'scss_includes/sections';
@import 'scss_includes/latestMatches';
@import 'scss_includes/newsTeaser';
@import 'scss_includes/tabelle-spielplan';
@import 'scss_includes/wildparkTV';
@import 'scss_includes/socialMediaTeaser';
@import 'scss_includes/quicklinks';
@import 'scss_includes/kscApp';
@import 'scss_includes/socialQuicklinks';
@import 'scss_includes/team';
@import 'scss_includes/opta';
@import 'scss_includes/imageHeader';
@import 'scss_includes/jumpNav';
@import 'scss_includes/breadcrumb';
@import 'scss_includes/textElement';
@import 'scss_includes/box';
@import 'scss_includes/appear';
@import 'scss_includes/matchdayHero';
@import 'scss_includes/matchTimeline';
@import 'scss_includes/matchdayNav';
@import 'scss_includes/matchcenter-news';
@import 'scss_includes/eventTable';
@import "scss_includes/lightbox";
@import 'scss_includes/article';
@import 'scss_includes/slick';
@import 'scss_includes/shariff';
@import 'scss_includes/fixedbackgroundimage';
@import 'scss_includes/kickerApi';
@import 'scss_includes/person';
@import 'scss_includes/teamfoto';
@import 'scss_includes/adsection';
@import 'scss_includes/contentTables';
@import 'scss_includes/prevNext';
@import 'scss_includes/newsList';
@import 'scss_includes/pageNavigation';
@import 'scss_includes/formation';
@import 'scss_includes/fixedBottomNav';
@import 'scss_includes/staff';
@import 'scss_includes/rte';
@import 'scss_includes/gallery';
@import 'scss_includes/youtube';
@import 'scss_includes/sectionlogic';
@import 'scss_includes/shopTeaser';
@import 'scss_includes/sponsoren-logos';
@import 'scss_includes/iOS';
@import 'scss_includes/tx-felogin';
@import 'scss_includes/powermail';
@import 'scss_includes/advertising';
@import 'scss_includes/exitIntent';
@import 'scss_includes/ce-nonresponsiveimage';
@import 'scss_includes/loginBar';
@import 'scss_includes/content-elements/ce-accordion';
@import 'scss_includes/content-elements/countdown';
@import 'scss_includes/content-elements/interferer';
@import 'scss_includes/content-elements/ce-playerstatistic';
@import 'scss_includes/content-elements/ce-textimage2col';
@import 'scss_includes/content-elements/ce-count2';
@import 'scss_includes/paidContent';
@import 'scss_includes/content-elements/ce-360promo';
@import 'scss_includes/diarysettings';
@import 'scss_includes/popup';
@import 'scss_includes/content-elements/ce-diary';
@import 'scss_includes/content-elements/ce-diarydivider';
@import 'scss_includes/cookieconsent';
//============ CONTENT-ELEMENTS ============//
@import 'scss_includes/content-elements';

//============= DIVERSE ELEMENTE =============//
.close {
  cursor: pointer;
  width: 3em;
  height: 3em;
  position: absolute;
  z-index: 100;
  right: 3em;
  top: 2em;
  transition: $t-medium;

  &:before {
    transition: $t-fast;
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background: $blue-dark;
    opacity: 0;
  }

  span {
    transition: $t-fast;
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 100%;
    display: block;
    background: #fff;
    height: 0.5em;
    border-radius: 0.25em;
    transform: translate3D(0%, -50%, 0) rotate(45deg);
    transform-origin: center center;

    + span {
      transform: translate3D(0%, -50%, 0) rotate(-45deg);
    }
  }

  &:hover, &:focus {
    transform: rotate(0deg);

    &:before {
      opacity: 1;
      transform: scale(1.25);
    }

    span {
      top: 50%;
      width: 50%;
      transform: translate3D(50%, -50%, 0) rotate(0deg);

      + span {
        top: 50%;
        transform: translate3D(50%, -50%, 0) rotate(0deg);
      }
    }
  }
}

a.app {
  display: none;
}

