$optaNav_height: 4rem;
$label_fontsize: 1.3rem;
$title_fontsize: 1.6rem;

body.team {
    .container.headlines {
        padding: 0 15px;

        .row {
            margin: 0 -15px;

            [class*="col-"] {
                padding: 0 15px;
            }
        }
    }
}

section.opta {
    @media (max-width: 649px) and (min-width: 481px){
        .col-xs-12 {
            width: 50%;
        }
       .person.small .playerinfo{
           .h2.name{
               font-size: 1.75em;
           }
           p{
               font-size: 1.5em;
           }
       }
    }

    .Opta p.Opta-Load {
        background: url('../Img/Opta/loader.svg') no-repeat center bottom;
        text-align: center;
        padding: 20px 10px 40px 10px;
        border: none;
        margin: 10px 0;
    }

    .container {
        padding: 0 15px;

        .row {
            margin: 0 -15px;

            [class*="col-"] {
                padding: 0 15px;
            }
        }

        .person.player {
            .info {
                margin-left: 0;
            }

            .name {
                position: relative;
                @media all and (max-width: 499px) {
                    padding-right: 5rem;
                }
            }

            .playerinfo {
                @media all and (min-width: 500px) and (max-width: 819px) {

                }
            }
        }
    }

    .h1.playernumber {
        position: absolute;
        z-index: 5;
        font-size: 3rem;
        color: #fff;
        background: $blue-ci;
        width: 1.7em;
        height: 1.7em;
        text-align: center;
        line-height: 1.8em;
        border-radius: 1.5em;
        box-sizing: content-box;
        transform: translate(0,-0.5em);
        @media all and (max-width: 820px) {
            font-size: 2.5rem;
        }
        @media all and (max-width: 499px) {
            display: none;
        }
    }

    .playernumber-mobile {
        display: none;
        @media all and (max-width: 499px) {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .playerimage{
        figure{
            position: relative;
            &:after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 20%;
                @include gradient_white-transparent;
            }
            @media all and (max-width: 1279px) and (min-width: 481px){
                margin-bottom: 0;
                &:after{
                    display: none;
                }

            }
        }
    }

    .Opta_W .Opta-Tooltip {
        h3 {
            background: $blue-ci;
            border: none;
        }
    }

    .Opta .Opta-Info {
        background-color: $blue-ci;
        color: #fff;
        transition: $t-fast;

        &.active {
            background-color: $yellow;
            color: $blue-ci;
        }
    }
}

.person .Opta {
    > .Opta_W {
        margin: 0;
    }
    .Opta-error {
        padding: 0;
        .Opta-Cf{
            background: none;
        }
        p {
            height: $optaNav_height;
            background-color: $grey-superlight;
            line-height: $optaNav_height;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 0 10px;
            @media all and (min-width: 600px) {
                font-size: 1.6rem;
            }
        }
    }

    .Opta-Tabs {
        &.Opta-TabsMore {
            .Opta-Nav {
                z-index: 10;

                > ul {
                    background-color: #fff;
                    height: $optaNav_height;
                    display: flex;

                    &:hover, &:focus, &:active {
                        background-color: #fff !important;
                    }

                    &.Opta-Overflow li.Opta-Overflow {
                        margin-top: 0;
                        border-bottom: 0;
                        border-left: 2px solid #fff;
                        border-top: 2px solid #fff;
                        width: 25% !important;
                        @media all and (max-width: 329px) {
                            width: 33.33333% !important;
                        }
                    }

                    li {
                        flex: 1;
                        border-color: #fff;
                        border-width: 2px;

                        &:last-child {
                            border-right: none;
                        }

                        a {
                            display: block;
                            height: $optaNav_height;
                            line-height: $optaNav_height;
                            font-size: 1.4rem;
                            text-align: center;
                            background-color: $grey-superlight;

                            &:hover {
                                background-color: $yellow;
                            }

                            @media all and (min-width: 600px) {
                                font-size: 1.6rem;
                            }

                            &:after, &:before {
                                content: none;
                            }

                            &:hover {

                            }
                        }

                        &.Opta-On {
                            a {
                                font-weight: 800;
                                background: $blue-ci;
                            }
                        }

                        @media all and (max-width: 499px) {
                            &:nth-child(4) {
                                border-right: 0;
                            }
                            a {
                                text-align: left;
                            }
                        }
                        @media all and (max-width: 329px) {
                            &:nth-child(3) {
                                border-right: 0;
                            }

                        }
                    }

                    &.Opta-Overflowed {
                        &:after {
                            background: none;
                            color: #fff;
                            top: 50%;
                            transform: translateY(-50%) rotate(90deg);
                            text-align: center;
                            content: "...";
                            font-size: 2.2rem;
                            font-weight: 700;
                            line-height: 0.5rem;
                            right: 0;
                            display: block !important;
                            transition: $t-fast;

                            &:hover, &:focus, &:active {
                                background: $blue-ci !important;
                                color: #fff !important;
                            }
                        }

                        &.Opta-Overflow {
                            &:after {
                                content: "×";
                                line-height: 1;
                            }
                        }

                        @media all and (min-width: 500px) {
                            &.Opta-Overflowed {
                                &:after {
                                    content: none;

                                }

                                li.Opta-Overflow {
                                    top: auto;
                                    width: auto !important;
                                    display: block !important;
                                    position: static !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .Opta-TabbedContent {
        border: 1px solid $grey-superlight;
        border-top: none;
        transform: translateY(-1px);
    }

    .Opta-Flex {
        .Opta-Flex-Item {
            border-color: $grey-superlight !important;
            border-top: none;
        }

        &.Opta-Stats-Section-Distribution-Passes {
            .Opta-Flex-Item {
                border: none;
            }
        }

        &.Opta-Stats-Section-Attack-Shots {
            .Opta-Flex-Item {
                &:first-child {
                    border-right: none;
                }
            }

            > .Opta-Flex-Item {
                &:first-child {
                    display: flex;
                    align-items: center;

                    .Opta-Stats {
                        margin: 2rem 1rem;

                        .Opta-Value {
                            width: 50px;
                        }
                    }

                    .Opta-Graph-Title {
                        width: 11rem;
                        font-size: $label_fontsize;
                    }
                }
            }

            .Opta-Stats-Section-Attack-Conversion {
                .Opta-Flex-Item {
                    border-right: none;

                    &:last-child {
                        border-top-width: 2px;
                    }

                    @media all and (max-width: 430px) {
                        border: none;
                        &:first-child {
                            border-right: 1px solid;
                        }
                        &:last-child {
                            border-left: 1px solid;
                        }
                    }
                }
            }
        }

        &.Opta-Stats-Section-Attack-Methods {
            > .Opta-Flex-Item {
                &:first-child {
                    border: none;
                }
            }

            .Opta-Flex-Item {
                .Opta-Stats-Section-Attack-Box {
                    .Opta-Flex-Item {
                        border: none;

                        .Opta-Stats-Football-Box {
                            background: url('../Img/Opta/attack-box.svg') no-repeat center top;
                            background-size: contain;
                            margin: 10px;
                            padding: 10px;

                            .Opta-Stat {

                            }

                            svg {
                                rect, path, line {
                                    stroke: transparent;
                                    fill: transparent;
                                }

                            }
                        }
                    }
                }
            }

            .Opta-Stats-Goal-Methods {
                @media all and (max-width: 430px) {
                    margin-top: 0;
                }

                .Opta-Goal-Method {
                    > div {
                        border: none;
                    }

                    @media all and (max-width: 478px) {
                        flex: 1 0 100%;
                        max-width: 100% !important;
                        border-bottom: 1px solid $grey-superlight;
                        &:last-child {
                            border: none;
                        }
                        > div {
                            display: flex;
                            align-items: center;

                            > div {
                                margin: 0;
                                text-align: left;

                                &.Opta-Value {
                                    min-width: 4rem;
                                    text-align: right;
                                    margin: 0 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .Opta-Stat {
        .Opta-Value {
            fill: $blue-ci;
            color: $blue-ci;
            font-size: 2.4rem;
            font-weight: 700;
            margin: 0 0 0.5rem 0;
        }

        .Opta-Label {
            font-size: $label_fontsize;
            font-weight: 600;
            color: $grey-ci;
            fill: $grey-ci !important;
            @media all and (min-width: 1600px) {
                font-size: 15px;
            }
        }

        .Opta-Graph-Line {
            fill: transparent;
            stroke: $grey-superlight;
        }

        svg {
            path {
                &.Opta-Arc {
                    fill: $blue-ci;
                }
            }
        }

        .Opta-Bar-Fill {
            background: $blue-ci !important;
        }
    }

    .Opta-Goal-Method-Left .Opta-Goal-Figure {
        background-image: url(../Img/Opta/goal-left-foot.svg) !important;
    }

    .Opta-Goal-Method-Right .Opta-Goal-Figure {
        background-image: url(../Img/Opta/goal-right-foot.svg) !important;
    }

    .Opta-Goal-Method-Headed .Opta-Goal-Figure {
        background-image: url(../Img/Opta/goal-headed.svg) !important;
    }

    .Opta-Goal-Method-Other .Opta-Goal-Figure {
        background-image: url(../Img/Opta/goal-other.svg) !important;
    }

    .Opta-Graph-Title {
        font-weight: 700;
        font-size: $title_fontsize;
        margin: 0 0 2rem;
        color: $grey-ci;
    }

    .Opta_F_SPS_N {
        .Opta-Stats {
            margin: 2rem 1rem;
        }

        .Opta-Stats-Section-Defence-Graphs .Opta-Flex-Item {
            flex: 1 0 33%;
        }
    }

    .Opta-Stats-Cross {
        .Opta-Stat {
            circle {
                fill: $blue-ci;
            }
        }
    }

    .Opta_F_SPS_N .Opta-Stats-Section-Discipline {
        .Opta-Flex-Item {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;

            .Opta-Stat {
                margin: 0;
            }
        }
    }

    .Opta-Stats-Text .Opta-Stat.Opta-Stat-Card {
        .Opta-Value {
            width: 35px;
            height: 45px;
            line-height: 45px;
            margin: 0 auto 10px;
        }

        &.Opta-Stat-Yellow .Opta-Value {
            background-color: #ffcb05;
            border: none;
            color: #fff;
        }

        &.Opta-Stat-Red .Opta-Value {
            background-color: #e40000;
            border: none;
            color: #fff;
        }
    }
}
