//==========SHARIFF==========//

.shariff {
  display: inline-block;
  vertical-align: middle;
  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      font-size: 1em;
      line-height: 1;
      list-style: none;
      display: inline-block;
      a {
        &:before, &:after {
          display: none;
        }
        span.fa {

        }
        span.share_text {
          display: none;
        }
      }
    }
  }
}

.share {
  border-top: 1px solid $grey-superlight;
  padding: 1em 0 0 0;
  margin: 1em 0;
  color: $grey-ci-bright;
  > div {
    font-size: 2em;
    font-weight: 300;
    text-transform: uppercase;
    width: 100%;
    text-align: right;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75em;
  }
  span.fa {
    font-size: 1.75em;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 .25em;
    &.fa-facebook {
      color: $facebook;
    }
    &.fa-twitter {
      color: $twitter;
    }
    &.fa-whatsapp {
      color: $whatsapp;
    }
    &:hover, &:focus {
      transform: scale(1.25);
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {
  .iOS {
    .share span.fa.fa-whatsapp {
      display: none;
    }
  }
}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
  .share {
    span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.75em;
      font-size: 0.75em;
      font-weight: 600;
    }
  }
}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}