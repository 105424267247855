//==========VORLAGE==========//
section.teamphoto {

  .wrap {
    background-color: $grey-ultralight;
    border: 1px solid #fff;

    .teamphotoLabel, .teamphotoText {
      padding: 1em;
    }

    .teamphotoLabel {
      color: $grey-ci;
      span {
        font-weight: 600;
        font-size: 1.5em;
        line-height: 1.5em;
      }
    }

    .teamphotoText span {
      font-size: 1.5em;
      line-height: 1.5em;
    }

  }

}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}