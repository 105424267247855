//FOOTER=========//

footer {
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    background: #fff;
    padding-bottom: 70px;

    hr {
        background: #fff;
        opacity: .5;
    }

    .footercontent {
        background: $blue-ci;
        position: relative;
        overflow: hidden;

        &:after {
            //content: "";
            display: block;
            position: absolute;
            z-index: 1;
            width: 100%;
            left: 0;
            bottom: 0;
            @include topshadow_darkblue_bottom;
            height: 10em;
        }

        .ksc-logo {
            display: block;
            width: 17.5em;
            margin: 0 auto;
            padding: 6em;
            box-sizing: content-box;

            img {
            }
        }
    }

    .footernav {
        position: relative;
        z-index: 5;

        ul {
            li {
                display: inline-block;
                margin: 0 1.5em 3.1em;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                a {
                    color: #fff;
                    font-size: 1.6em;
                    font-weight: 500;
                    text-transform: uppercase;

                    &:hover, &:focus {
                        color: $yellow;
                    }
                }

                &:first-child {
                    //margin-left: 0;
                }

                &:last-child {
                    //margin-right: 0;
                }
            }
        }
    }

    .metanav {
        position: relative;
        z-index: 10;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:before, &:after {
                display: none;
            }
        }
        .hostedBy{
            flex: 0 1 100px;
            max-width: 100px;
        }
        ul.nav {
            padding: 4em 0;
            flex: 1 1 30%;
            text-align: left;
            &.right{
                text-align: right;
            }
            li {
                font-size: 1.2em;
                font-weight: 900;
                text-transform: uppercase;
                color: #fff;
                display: inline-block;

                &.divider {
                    padding: 0 .75em;
                }
                a {
                    color: #fff;
                    font-weight: 900;

                    &:hover, &:focus {
                        color: $yellow;
                    }
                }
                span {
                    cursor: pointer;

                    &:hover, &:focus {
                        color: $yellow;
                    }
                }
            }
        }
    }

    .liga {
        background: #fff;

        .liga-logo {
            width: 12em;
            margin: 2em auto;
            padding: 0;
            img {
            }
        }
    }
}


//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
    footer {
        .footercontent {
            .container {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
@media all and (min-width:695px) and (max-width: 1279px) {
    footer {
        .footernav {
            ul {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
@media all and (max-width:860px) {
    footer {
        .footernav {
            ul li a{
                font-size: 1.2em;
                font-weight: 900;
            }
        }
    }
}
@media (max-width: 650px) {
    footer {
        .metanav {
            .container{
                flex-wrap: wrap;
            }
            ul.nav{
                flex: 1 0 50%;
            }
            .hostedBy{
                order: 3;
                max-width: none;
                flex: 1 0 100%;
                img{
                    max-width: 80px;
                    margin: 0 auto 3.6em;
                }
            }
        }
    }
}

@media (max-width: 479px) {
    footer {
        .metanav {
            ul.nav {
                flex: 1 0 100%;
                text-align: center;
                &.right{
                    text-align: center;
                }
                &:first-child {
                    padding-top: 3.1em;
                    padding-bottom: 2em;
                }

                &:last-child {
                    padding-top: 0;
                    padding-bottom: 3.1em;
                }
            }
        }
    }
}

@media (max-width: 375px) {

}