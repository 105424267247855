//==========VORLAGE==========//

section.fixedBgImage + div {margin-top:6em;}


  //BREAKPOINT MIN-WIDTH==========================//
  @media (min-width: 768px){

  }
  @media (min-width: 992px){

  }

  @media (min-width: 1280px){

  }
  @media (min-width: 1480px){

  }

  //BREAKPOINT MAX-WIDTH==========================//

  @media (max-width: 1479px){

  }
  @media (max-width: 1279px){

  }
  @media (max-width: 991px){

  }
  @media (max-width: 767px){

  }
  @media (max-width: 480px){

  }
  @media (max-width: 375px){

  }
