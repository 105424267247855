
body{
  .load, .mask{
    z-index:50000;
    background-color: $blue-ci;
  }
  .pace{
    top:0;
    width: 20em;
    height: 20em;
    border-radius: 0;
    z-index:50001;
    .pace-activity{
      box-shadow:none;
      height: 20em;
      width: 20em;
      border-radius: 0;
      background: url("../Img/KSC-PreloadMask.svg") no-repeat center center !important;
      background-size: contain;
      z-index:50005;
      transform:scale(1.2);
      opacity: 1;
    }
    .pace-progress{
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      margin: 5px 0 0 5px;
      transform:translateX(10px);
      top:0;
      border-radius:0;
      background: #FFF;
      z-index:50002;
      -webkit-box-shadow: 120px 0 $blue-ci, 240px 0 $blue-ci;
      -ms-box-shadow: 120px 0 $blue-ci, 240px 0 $blue-ci;
      box-shadow: 120px 0 $blue-ci, 240px 0 $blue-ci;
      &:after{
        display:none;
      }
    }
  }
}
.mask {
  display: none;
  z-index: 1000;
  position: fixed;
  width: 100vw !important;
  height: 100vH !important;
  background-color: $blue-ci;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.pace, .mask{
  &:after{
    //content: "";
    display: block;
    width: 10em;
    height: 10em;
    border-radius:50%;
    position: absolute;
    z-index: 200000;
    left: 50%;
    top:50%;
    border:.25em solid #fff;
    transform:scale(1) translate3D(-50%, -50%, 0);
    transform-origin: left top;
    //animation: pulseLoader 1s infinite ease-in;
  }
  &:before{
    content: "";
    display: block;
    width: 20em;
    height: 20em;
    position: absolute;
    z-index: 200000;
    left: 50%;
    top:50%;
    transform: translate3d(-50%, -50%, 0);
    background: url("../Img/KSC-PreloadMask_inv_white.svg") no-repeat center center;
    background-size: cover;
    opacity: .25;
  }
}

@keyframes pulseLoader {
  0% {
    opacity: 0;
    transform:scale(0.25) translate3D(-50%, -50%, 0);
    border-width: 0;
  }
  70% {
    opacity: 1;
    transform:scale(1) translate3D(-50%, -50%, 0);
    border-width: 0.25em;
  }
  100% {
    opacity: 0;
    transform:scale(1.75) translate3D(-50%, -50%, 0);
    border-width: 0;
  }
}