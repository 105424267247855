//==========Textelement 2/3 - 1/3 Cols==========//
.imageHeader + .textElement{
  padding-top: 6em;
}
.textElement {
  margin-bottom: 6em;
  img{
    width: auto;
    max-width: 100%;
  }
  .lc {
    h2, .h2{
      border-bottom: 1px solid $grey-ci-light;
      padding-bottom: 1.625em;
      margin-bottom: 1.625em;
    }
    > h2, .h2 {
      padding-bottom: .7em;
      margin-bottom: .55em;
    }

    p{
      text-align: justify;
      a{text-align: left;}
    }
    ul, ol{
      margin-bottom: 4.5em;
      li{
        margin-bottom: 1em;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {
  .textElement {
    .lc {
      h2, .h2{
      }
      > h2, .h2 {
      }
      p{
        text-align: left;
        a{}
      }
      ul, ol{
        li{
          &:last-child{
          }
        }
      }
    }
    .rc {margin-top:6em;}
  }
}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}