//==========VORLAGE==========//

.tx-powermail {
  legend {
    text-align: left;
    font-size: 1.7em;
    color: $blue-ci;
    padding-bottom: 10px;
    padding-top: 2em;
  }
  p {
    font-size: 2em;
  }
  label {
    font-size: 1.5em;
    font-weight: 600;
  }
  select {
    margin-bottom: 15px;
  }
  input[type="file"] {
    font-size: 1.7em;
  }
  .powermail_fieldwrap {
    width: 100% !important;
  }
  .powermail_fieldwrap_type_check {
    padding-bottom: 1em;
    .control-label {
      font-size: 1.8em;
      color: $blue-ci;
      font-weight: 400;
    }
    .checkbox label {
      font-weight: 400;
      line-height: 1.1;
    }
  }
}

.powermail_fieldset_12 {
  legend {
    display: none;
  }
  .col-sm-16 {
    width: 100%;
    margin-left: 0;
  }
}

.powermail_fieldwrap_nachweisermigung {
  display:none;
  .col-sm-16:after {
    display: block;
    color: $blue-ci;
    width: 100%;
    font-size: 1.3em;
    padding-top: 10px;
    content: 'Bitte laden Sie einen Ermäßigungsnachweis hoch. (Schülerausweis, Immatrikulationsbescheinigung, Schwerbehindertenausweis, Rentnerausweis oder Supporters-Ausweis)'
  }
}

  //BREAKPOINT MIN-WIDTH==========================//
  @media (min-width: 768px) {

  }

  @media (min-width: 992px) {

  }

  @media (min-width: 1280px) {

  }

  @media (min-width: 1480px) {

  }

  //BREAKPOINT MAX-WIDTH==========================//

  @media (max-width: 1479px) {

  }

  @media (max-width: 1279px) {

  }

  @media (max-width: 991px) {

  }

  @media (max-width: 767px) {
    .row.row-centered [class*="col-"] {
      display: block;
    }
  }

  @media (max-width: 480px) {

  }

  @media (max-width: 375px) {

  }


