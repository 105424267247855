//==========VORLAGE==========//

body.diary {
  #wrap section {
    padding: 3em 0;
  }

  #wrap section.imageHeader {
    padding: 0;
  }

  #wrap section.ce-textimage2col {
    padding: 6em 0 8em 0;
  }

  #wrap section.ce-textimage2col + section.ce-textimage2col {
    padding: 0 0 8em 0;
  }

  .ce-textimage2col h2 {
    font-family: "American_Captain_01", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 4.8em;
  }

  header + main > .container {
    padding-top: 0;
  }
}

@media (min-width: 1650px) {
  body.diary .image-slick-gallery .slick-arrow .ksc-icon {
    font-size: 50px;
  }
}

//BREAKPOINT MIN-WIDTH==========================//
@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1480px) {

}

//BREAKPOINT MAX-WIDTH==========================//

@media (max-width: 1479px) {

}

@media (max-width: 1279px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 480px) {

}

@media (max-width: 375px) {

}