//==========SPONSOREN==========//
.hauptsponsor {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 2em;
    hr {
        display: none;
        border:none;
        background: none;
    }

    a {
        &.mainsponsor-logo {
            padding: 4.5em 0;
            margin: 0 4em 0 4.5em;
            width: 20em;
        }
        &.left-logo {
            width: 9em;
            order:-1;
        }
        &.right-logo {
            width: 8em;
        }
    }
}

.sponsoren {
    background: $blue-ci-bright;
    text-align: center;
    padding-bottom: 3.4em;
    overflow: hidden;

    .sponsor {
        display: inline-block;
        height: 3.2em;
        margin: 3.4em 1.6em 0;

        img {
            height: 100%;
            width: auto;
        }

        &.hbg {
            img {
                height: 140%;
                transform: translateY(-20%);
            }
        }
    }
}


@media (min-width: 1101px) {
    .sponsoren {
        .sponsor {
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

//BREAKPOINT MAX-WIDTH==========================//
@media (max-width: 1250px) {
    .sponsoren {
        font-size: 12px;
    }
}

@media (max-width: 1100px) {
    .sponsoren {
        font-size: 10px;
    }
}
@media (max-width: 767px) {
    .sponsoren {
        font-size: 9px;
    }
}

@media (max-width: 600px) {
    .hauptsponsor {
        hr {
            flex: 1 0 100%;
            width: 100%;
            display: block;
        }
        a {
            margin: 0 1.5em 1.5em;
            width: 30em;
            &.mainsponsor-logo {
                order: -1;
                padding: 3em 0;
                margin: 0;
                width: 30em;
            }

            &.left-logo {
                order:1;
            }
            &.right-logo{
                order: 5;
            }
        }
    }
}

@media (max-width: 440px) {
    .hauptsponsor {
        a {
            &.mainsponsor-logo {
                width: 80%;
            }

            &.left-logo {
                width: 10em;
            }
            &.right-logo {
                width: 9em;
            }
        }
    }
}

@media (max-width: 375px) {

}